import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class MotoristaService extends Service{

  constructor(){
    super('motorista');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
        let paramTemp = '';

        if (filter.transportadoraId){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `transportadoraId eq ${filter.transportadoraId}`;
        }

        if (paramTemp) {

          if(parametros){
            parametros += '&';
          }
          else {
            parametros += '?';
          }

          parametros += `$filter=${paramTemp}`;
        }

    }

    return parametros;
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    })
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public MotoristaByTransportadora(transportadoraId: number){
    return http.get(`${this.GetNomeControle()}/Transportadora/${transportadoraId}`);
  }

  //DOCUMENTOS
  public ListarDocumentos(id: number, page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filtro?: string, include?: string) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/${id}/Documento${this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns, filtro)}`, this.GetHeader(include));
  }

  public ObterDocumentoPorId(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public SalvarDocumento(model: any) : Promise<any>
  public SalvarDocumento(model: any, documentoId: number) : Promise<any>
  public SalvarDocumento(model: any, documentoId?: number) : Promise<any>{
    if(documentoId){
      if(documentoId > 0){
        return http.patch(`${this.GetNomeControle()}/Documento/${documentoId}`, model);
      }
    }

    return http.post(`${this.GetNomeControle()}/Documento`, model);
  }

  public DeletarDocumento(documentoId: number) : Promise<any>{
    return http.delete(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public DownloadDocumento(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}/Download`, {responseType: 'blob'});
  }
  
}