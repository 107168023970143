

























































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { MotoristaService, EnderecoService, UsuarioService, PerfilUsuarioService, EmpresaService } from "@/core/services/geral";
import { Motorista, MotoristaDocumento, PerfilUsuario, Usuario } from "@/core/models/geral";
import { ArquivoService } from "@/core/services/shared";
import { TransportadoraService } from "@/core/services/residuo";
import { PageBase } from "@/core/models/shared";

@Component
export default class CadastroMotorista extends PageBase {
  @Prop() private item!: Motorista;
  @Prop() private value!: string;

  itemOriginal!: Motorista;

  service = new MotoristaService();
  enderecoService = new EnderecoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  emailRules: any[] = [
    (v: any) => !!v || "Campo obrigatório",
    (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail deve ser válido'
  ]
  tabActive: any = {};
  mskCpf = process.env.VUE_APP_MSK_CPF;
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;
  estados: any[] = [];
  municipios: any[] = [];
  perfis: PerfilUsuario[] = [];
  $refs!: {
    formMotorista: HTMLFormElement
  }

  dialogFoto: boolean = false;
  foto:any = null;
  cnhFoto:any = null;
  moppFoto:any = null;
  asoFoto:any = null
  carouselFoto: any = null;

  transportadoras: any[] = []
  transportadoraService = new TransportadoraService()

  usuario = new Usuario(); 
  usuarioService = new UsuarioService()

  headerDocumentos: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Descrição', value: 'documento.descricao' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Tipo', value: 'documento.tipo.nome' },
    { text: 'Data Validade', value: 'documento.dataValidade' },
  ];

  documento = new MotoristaDocumento();
  documentos: MotoristaDocumento[] = [];
  totalDocumentos: number = 0;
  optionsDocumentos: any = {
    itemsPerPage: -1
  }
  loadingDocumentoDialog: boolean = false;
  loadingDocumentos: boolean = false;
  dialogDocumento: boolean = false;
  loadingDownload: boolean = false;

  @Watch('item')
  Item() {
    if (this.$refs.formMotorista) {
      this.$refs.formMotorista.resetValidation();
    }
    if(this.item.id > 0){
      this.CarregarDocumentos();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      if(this.item.usuario){
        this.usuario = this.item.usuario;
      }
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }

    if(this.dialog && !(this.item.id > 0)){
      const empresaService = new EmpresaService();
      empresaService.ObterPorId(this.app.empresaId).then(
        res => {
          this.item.endereco.estadoId = res.data.endereco.estadoId;
          this.item.endereco.municipioId = res.data.endereco.municipioId;
        }
      )
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged() {
    this.CarregarMunicipio();
  }

  mounted() {

    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')}
    );

    this.transportadoraService.ListarTudo().then(
      res=>{
        this.transportadoras = res.data.items
        this.transportadoras.unshift({
          id: null,
          nome: ''
        })
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )

    const perfilervice = new PerfilUsuarioService();
    perfilervice.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );

  }

  @Watch('optionsDocumentos', { deep: true })
  CarregarDocumentos(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsDocumentos;
    this.loadingDocumentos = true;
    this.service.ListarDocumentos(this.item.id, page, itemsPerPage, sortBy, sortDesc, search, this.headerDocumentos).then(
      res =>{
        this.documentos = res.data.items;
        this.totalDocumentos = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loadingDocumentos = false;
    })
  }

  CarregarMunicipio(){
    this.enderecoService.ObterMunicipios(this.item.endereco.estadoId)
      .then(
        res => {
          this.municipios = res.data.items;
        },
        err => this.$swal('Aviso', err.message, 'error')
      );
  }

  ObterEndereco(cep: string){
      this.enderecoService.ObterPorCep(cep).then(
        res => {
          const endereco = res.data;
          if (endereco) {
            this.item.endereco.logradouro = endereco.logradouro;
            this.item.endereco.bairro = endereco.bairro;
            this.item.endereco.estadoId = endereco.estadoId;
            this.item.endereco.municipioId = endereco.municipioId;
          }
        },
        err => {
          this.$swal('Aviso', err.message, 'error');
        }
      );
  }

  AbrirCadastroDocumento(documento?: MotoristaDocumento){
    if(documento){
      this.service.ObterDocumentoPorId(documento.id).then(
        res=> {
          this.documento = res.data;
          this.documento.documento.dataValidade = this.documento.documento.dataValidade? this.documento.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogDocumento = true;
        },
        err=> {
          this.$swal('Aviso', err.message, 'error');
        }
      )
    }
    else{
      this.documento = new MotoristaDocumento();
      this.documento.motoristaId = this.item.id;
      this.documento.documento.empresaId = this.app.empresaId;
      this.dialogDocumento = true;
    }

  }

  ExcluirDocumento(documento: MotoristaDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(documento.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
    .then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        this.CarregarDocumentos();
      }
    })
  }

  AbrirLink(link){
    if(!!link)
      window.open(link,"_blank");
  }

  DownloadDocumento(documento: MotoristaDocumento){
    this.loadingDownload = true;
    this.service.DownloadDocumento(documento.id).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.documento.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }

  Salvar() {
    if (this.$refs.formMotorista.validate()) {

      if(this.app.transportadoraId != null)
      {
        this.item.transportadoraId = this.app.transportadoraId;
      }

      this.usuario.perfilId = 4;
      this.usuario.transportadoraId = this.item.transportadoraId;
      this.usuario.ativo = this.item.ativo;
      this.usuario.empresaId = this.app.empresaId;
      this.usuario.complementar.nome = this.item.nome;
      this.usuario.complementar.telefone = this.item.telefone1;
      this.usuario.complementar.celular = this.item.telefone2;
      this.usuario.complementar.email = this.item.email;
      this.usuario.login = this.item.email;

      if(this.item.usuario){
        this.item.usuario = new Usuario(this.usuario);
      }      
      else if(this.item.usuario == null && this.usuario.senha.length > 0){
        this.item.usuario = new Usuario(this.usuario);
      }

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );      
    }
  }

  RemoveImage(){
    this.item.usuario.foto = null;
    this.foto = null;
  }

  async LoadImage(){

    if (!this.foto)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.foto);
    this.usuario.foto = dados.replace(/^[^,]*,/, "");
  }

  Close() {
    this.documentos = [];
    this.usuario = new Usuario();
    this.dialog = false;
  }
}
