import Vue from "vue";

import MasterPage from "./shared/MasterPage.vue";
import RelatorioPage from "./shared/RelatorioPage.vue";

import CadastrarUsuario from "./geral/usuario/Cadastro.vue";
import CadastrarEmpresa from "./geral/empresa/Cadastro.vue";
import CadastrarUnidadeMedida from "./geral/unidademedida/Cadastro.vue";
import CadastrarVeiculo from "./geral/veiculo/Cadastro.vue";
import CadastrarTipoVeiculo from "./geral/tipoveiculo/Cadastro.vue";
import CadastrarTipoDocumento from "./geral/tipodocumento/Cadastro.vue";
import CadastrarMotorista from "./geral/motorista/Cadastro.vue";
import CadastrarCliente from "./geral/cliente/Cadastro.vue";
import CadastroGrupoDocumento from "./geral/grupodocumento/Cadastro.vue"
import CadastroDocumentoEmpresa from "./geral/documentoempresa/Cadastro.vue"
import CadastroDocumentoReceptor from "./residuo/documentoreceptor/Cadastro.vue"
import CadastroReceptor from "./residuo/receptor/Cadastro.vue"
import CadastroTransportadora from "./residuo/transportadora/Cadastro.vue"
import CadastroRegiao from "./geral/regiao/Cadastro.vue"

import CadastroLocatario from "./geral/locatario/Cadastro.vue";

import CadastroCartaRastreabilidade from "./residuo/cartarastreabilidade/Cadastro.vue"
import CadastrarPorto from "./residuo/porto/Cadastro.vue";
import CadastrarGrupoServico from "./residuo/grupoServico/Cadastro.vue";
import CadastrarGrupoResiduo from "./residuo/grupoResiduo/Cadastro.vue";
import CadastrarGrupoEquipamento from "./residuo/grupoEquipamento/Cadastro.vue"

import CadastroOrdemServicoResiduo from "./residuo/ordemservico/CadastroOSResiduo.vue"
import CadastroOrdemServicoFoto from "./residuo/ordemservico/CadastroOSFoto.vue"

import CadastrarEquipamento from "./residuo/equipamento/Cadastro.vue";
import CadastrarResiduo from "./residuo/residuo/Cadastro.vue";
import CadastrarServico from "./residuo/servico/Cadastro.vue";
import CadastrarOrdemServico from "./residuo/ordemservico/Cadastro.vue";
import CadastrarOrdemServicoLocacao from "./residuo/ordemservico/CadastroOSLocacao.vue";
import SelecionarOrdemServicoResiduo from "./residuo/ordemservico/SelecionaResiduo.vue";
import CadastroAcondicionamento from "./residuo/acondicionamento/Cadastro.vue";
import CadastroArmazenamento from "./residuo/armazenamento/Cadastro.vue";
import CadastroPortoReceptor from "./residuo/porto/CadastroPortoReceptor.vue";

import CadastrarOrdemDestinacao from "./residuo/ordemdestinacao/Cadastro.vue";
import SelecionarOrdemDestinacao from "./residuo/ordemdestinacao/Seleciona.vue";

import CadastroMedicao from "./residuo/medicao/Cadastro.vue"
import CadastroMedicaoReceptor from "./residuo/medicaoreceptor/Cadastro.vue"

import SelecionarOrdemServico from "./residuo/ordemservico/Seleciona.vue"

import CarouselFotos from "./shared/Carousel.vue"

import CadastrarContrato from "./residuo/contrato/Cadastro.vue";
import CadastrarContratoReceptor from "./residuo/contratoReceptor/Cadastro.vue";

import CadastroTecnologia from "./residuo/tecnologia/Cadastro.vue";
import CadastrarTipoEmbarcacao from "./residuo/tipoembarcacao/Cadastro.vue";

import CadastroPergunta from "./monitoramento/pergunta/Cadastro.vue"
import SelecionaPergunta from "./monitoramento/pergunta/Seleciona.vue"
import CadastroAgendamento from "./monitoramento/agendamento/Cadastro.vue"
import CadastroAviso from "./monitoramento/aviso/Cadastro.vue"
import CadastroOperacao from "./monitoramento/operacao/Cadastro.vue"

import CadastroOrdemServicoInterna from "./interna/ordemservico/Cadastro.vue"
import CadastroOrdemServicoFotoInterna from "./interna/ordemservico/CadastroOSFoto.vue"

import Mapa from "./shared/Mapa/Mapa.vue"

import BarChart from "./shared/chartJs/BarChart.vue"
import BarChart2 from "./shared/chartJs/BarChart2.vue"
import PieChart from "./shared/chartJs/PieChart.vue"

import CadastrarDocumento from "./shared/CadastroDocumento.vue"
import CadastrarDocumentoGeral from "./geral/documento/Cadastro.vue"


Vue.component("master-page", MasterPage);
Vue.component("cadastro-usuario", CadastrarUsuario);
Vue.component("cadastro-empresa", CadastrarEmpresa);
Vue.component("cadastro-unidademedida", CadastrarUnidadeMedida);
Vue.component("cadastro-veiculo", CadastrarVeiculo);
Vue.component("cadastro-tipoveiculo", CadastrarTipoVeiculo);
Vue.component("cadastro-tipodocumento", CadastrarTipoDocumento);
Vue.component("cadastro-motorista", CadastrarMotorista);
Vue.component("cadastro-cliente", CadastrarCliente);
Vue.component("cadastro-documentoempresa", CadastroDocumentoEmpresa);
Vue.component("cadastro-documentoreceptor", CadastroDocumentoReceptor);
Vue.component("cadastro-receptor", CadastroReceptor);
Vue.component("cadastro-transportadora", CadastroTransportadora);
Vue.component("cadastro-regiao", CadastroRegiao)

Vue.component("cadastro-cartarastreabilidade", CadastroCartaRastreabilidade);
Vue.component("cadastro-porto", CadastrarPorto);
Vue.component("cadastro-grupo-servico", CadastrarGrupoServico);
Vue.component("cadastro-grupo-residuo", CadastrarGrupoResiduo);
Vue.component("cadastro-grupo-equipamento", CadastrarGrupoEquipamento);
Vue.component("cadastro-grupo-documento", CadastroGrupoDocumento);

Vue.component("cadastro-locatario", CadastroLocatario);

Vue.component("cadastro-equipamento", CadastrarEquipamento);
Vue.component("cadastro-residuo", CadastrarResiduo);
Vue.component("cadastro-servico", CadastrarServico);
Vue.component("cadastro-ordemservico", CadastrarOrdemServico);
Vue.component("seleciona-ordemservico-residuo", SelecionarOrdemServicoResiduo);
Vue.component("cadastro-ordemservico-residuo", CadastroOrdemServicoResiduo);
Vue.component("cadastro-ordemservico-locacao", CadastrarOrdemServicoLocacao);
Vue.component("cadastro-ordemservico-foto", CadastroOrdemServicoFoto);
Vue.component("cadastro-acondicionamento", CadastroAcondicionamento);
Vue.component("cadastro-armazenamento", CadastroArmazenamento);
Vue.component("cadastro-porto-receptor", CadastroPortoReceptor);

Vue.component("carousel-foto", CarouselFotos);

Vue.component("seleciona-ordemservico", SelecionarOrdemServico);

Vue.component("cadastro-ordemdestinacao", CadastrarOrdemDestinacao);
Vue.component("seleciona-ordemdestinacao", SelecionarOrdemDestinacao);

Vue.component("cadastro-medicao", CadastroMedicao);
Vue.component("cadastro-medicao-receptor", CadastroMedicaoReceptor);

Vue.component("cadastro-contrato", CadastrarContrato);
Vue.component("cadastro-contrato-receptor", CadastrarContratoReceptor);

Vue.component("cadastro-tecnologia", CadastroTecnologia);
Vue.component("cadastro-tipoembarcacao", CadastrarTipoEmbarcacao);

Vue.component("cadastro-pergunta", CadastroPergunta);
Vue.component("seleciona-pergunta", SelecionaPergunta);
Vue.component("cadastro-agendamento", CadastroAgendamento);
Vue.component("cadastro-aviso", CadastroAviso);
Vue.component("cadastro-operacao", CadastroOperacao);

Vue.component("master-page", MasterPage);
Vue.component("relatorio-page", RelatorioPage);

Vue.component("cadastro-ordemservico-interna", CadastroOrdemServicoInterna);
Vue.component("cadastro-ordemservico-interna-foto", CadastroOrdemServicoFotoInterna);

Vue.component("mapa", Mapa);

Vue.component("bar-chart", BarChart);
Vue.component("bar-chart2", BarChart2);
Vue.component("pie-chart", PieChart);

Vue.component("cadastro-documento-geral", CadastrarDocumentoGeral);
Vue.component("cadastro-documento", CadastrarDocumento);
