








































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { ContratoReceptor, ContratoReceptorResiduo, Receptor, Residuo } from "@/core/models/residuo";
import { ContratoReceptorService, ReceptorService, ResiduoService } from "@/core/services/residuo";
import { UnidadeMedidaService } from "@/core/services/geral";
import { UnidadeMedida } from "@/core/models/geral";

@Component
export default class CadastroContratoReceptor extends Vue {
  @Prop() private item!: ContratoReceptor;
  @Prop() private value!: string;

  itemOriginal!: ContratoReceptor;

  service = new ContratoReceptorService();
  receptorService = new ReceptorService();
  residuoService = new ResiduoService();
  valid = true;
  validDialogResiduo = true;
  dialog = false;
  dialogResiduo = false;
  isResiduoLoading = false;
  isReceptorLoading = false;
  onSearchReceptor: any = null;
  tabActive: any = {};
  contratoReceptorResiduo: ContratoReceptorResiduo = new ContratoReceptorResiduo();
  receptores: Receptor[] = [];
  residuos: Residuo[] = [];
  unidades: UnidadeMedida[] = [];
  headerResiduo: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Resíduo', value: 'residuo.codigoNomeTecnologiaGrupo' },
    { text: 'Unidade', value: 'unidadeMedida.sigla' },
    { text: 'Preço', value: 'preco', type: 'number' }
  ];
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formContrato: HTMLFormElement,
    formDialogResiduo: HTMLFormElement,
  }

  itemExcluido(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else
      return ''
  }

  @Watch('item')
  Item() {
    if (this.$refs.formContrato) {
      this.$refs.formContrato.resetValidation();
    }
    if(this.$refs.formDialogResiduo){
      this.$refs.formDialogResiduo.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  @Watch('onSearchReceptor')
  SearchCliente (val: string) {

    if (this.item.receptorId) return;
    if (this.isReceptorLoading) return;
    if (!val) return;

    this.isReceptorLoading = true
    this.receptorService.AutoComplete(val).then(
      res => {
        this.receptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
      ).finally(() => (this.isReceptorLoading = false));
  }

  CarregaResiduo(){
    this.isResiduoLoading = true
      this.residuoService.ListarTudoOrdenado('nome', false, "Tecnologia, Grupo").then(
        res => {
          this.residuos = res.data.items;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      ).finally(() => (this.isResiduoLoading = false));
  }

  mounted() {
    const unidadeService = new UnidadeMedidaService();
    unidadeService.ListarTudo()
      .then(
        res => {
          this.unidades = res.data.items;
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );

    this.CarregaResiduo();
  }

  Salvar() {
    if (this.$refs.formContrato.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
      
    }
  }

  AbrirDialogResiduo(contratoReceptorResiduo?: ContratoReceptorResiduo){
    this.contratoReceptorResiduo = contratoReceptorResiduo ? contratoReceptorResiduo : new ContratoReceptorResiduo();
    this.dialogResiduo = true;
  }

  ExcluirResiduo(contratoResiduo: ContratoReceptorResiduo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.residuos.indexOf(contratoResiduo);
        this.item.residuos.splice(index,1);
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  SalvarResiduo(){

    if (this.$refs.formDialogResiduo.validate()) {
      if (this.contratoReceptorResiduo.id == 0){
        const residuo = this.residuos.find(x => x.id == this.contratoReceptorResiduo.residuoId);
        const unidade = this.unidades.find(x => x.id == this.contratoReceptorResiduo.unidadeMedidaId);

        this.contratoReceptorResiduo.residuo = residuo!;
        this.contratoReceptorResiduo.unidadeMedida = unidade!;
        
        this.item.residuos.push(this.contratoReceptorResiduo);
      }
      this.dialogResiduo = false;
    }
  }

  Close() {
    this.dialog = false;
  }
}
