import { OrdemServico } from '.';
import { Servico } from '../residuo';

export class OrdemServicoServico implements Shared.IEntity{
    
    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    servicoId: number = 0;
    servico!: Servico;
    quantidade: number = 1;
    valor: number = 0;
    excluido: boolean = false;

    constructor(model?: OrdemServicoServico){

        if(!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.servicoId = model.servicoId;
        this.quantidade = model.quantidade;
        this.valor = model.valor;
    }
} 