import { Regiao } from '../geral';
import { Endereco } from '../shared';
import { PortoReceptor } from './PortoReceptor';

export class Porto implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    nome: string = '';
    ativo: boolean = true;
    endereco: Endereco = new Endereco();
    mapaUrl: string = '';
    regiaoId?: number;
    regiao!: Regiao;
    excluido: boolean = false;
    pcpCodigo: string = '';
    portoReceptores: PortoReceptor[] = [];

    constructor(model?: Porto) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;
        this.endereco = model.endereco;
        this.mapaUrl = model.mapaUrl;
        this.regiaoId = model.regiaoId;
        this.pcpCodigo = model.pcpCodigo;
        this.portoReceptores = model.portoReceptores;
    }
}