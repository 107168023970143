import { Motorista } from './../geral/Motorista';
import { OrdemDestinacao } from './../residuo/OrdemDestinacao';
import { Veiculo, Empresa } from './../geral/';
import { Receptor, Porto, Base, OrdemServico } from './../residuo/';
import { OrdemServico as OrdemServicoInterna } from '../interna';

export class Agendamento implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    motoristaId?: number;
    motorista!: Motorista;
    dataHora: string = '';
    portoId?: number;
    porto!: Porto;
    baseId?: number;
    base!: Base;
    receptorId?: number;
    receptor!: Receptor;
    veiculoId: number = 0;
    veiculo!: Veiculo;
    ordemServicoId?: number;
    ordemServico!: OrdemServico;
    ordemServicoInternaId?: number;
    ordemServicoInterna!: OrdemServicoInterna;
    ordemDestinacaoId?: number;
    ordemDestinacao!: OrdemDestinacao;

    constructor(model?: Agendamento){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.motoristaId = model.motoristaId;
        this.dataHora = model.dataHora;
        this.portoId = model.portoId;
        this.baseId = model.baseId;
        this.receptorId = model.receptorId;
        this.veiculoId = model.veiculoId;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.ordemServicoInternaId = model.ordemServicoInternaId;
        this.ordemServicoInterna = model.ordemServicoInterna;
        this.ordemDestinacaoId = model.ordemDestinacaoId;
        this.ordemDestinacao = model.ordemDestinacao;
    }
    
}