import { MedicaoOrdemServico, OrdemServico } from '@/core/models/residuo';
import { OrdemServicoServico, OrdemServicoResiduo } from './../../models/residuo/';
import { http, httpHeader } from '../../ApiConfig'
import { Service } from '../shared';

export class OrdemServicoService extends Service {

  constructor() {
    super('ordemServico');
  }

  private GetFiltro(parametros: string, filter: any) {

    if (filter) {
      let paramTemp = '';

      if (filter.numero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }

      if (filter.contratoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `contratoId eq ${filter.contratoId}`;
      }

      if (filter.clienteId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `contrato/clienteId eq ${filter.clienteId}`;
      }

      if (filter.transportadoraId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `transportadoraId eq ${filter.transportadoraId}`;
      }

      if (filter.embarcacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `embarcacaoId eq ${filter.embarcacaoId}`;
      }

      if (filter.motoristaId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `motoristaId eq ${filter.motoristaId}`;
      }

      if (filter.veiculoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `veiculoId eq ${filter.veiculoId}`;
      }

      if (filter.situacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `situacaoId in (${filter.situacaoId})`;
      }

      if (filter.protocolo) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `protocolo eq '${filter.protocolo}'`;
      }

      if (filter.dataInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }

      if (filter.dataFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      //FILTROS POR MR
      if (filter.mr01) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo1/mr01 eq '${filter.mr01}')`;
      }

      if (filter.mr02) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo2/mr02 eq '${filter.mr02}')`;
      }

      if (filter.mr03) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo3/mr03 eq '${filter.mr03}')`;
      }

      if (filter.mr04) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo4/mr04 eq '${filter.mr04}')`;
      }

      //FILTROS POR CDF
      if (filter.cdf01) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo1/cdf01 eq '${filter.cdf01}')`;
      }

      if (filter.cdf02) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo2/cdf02 eq '${filter.cdf02}')`;
      }

      if (filter.cdf03) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo3/cdf03 eq '${filter.cdf03}')`;
      }

      if (filter.cdf04) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo4/cdf04 eq '${filter.cdf04}')`;
      }

      if (paramTemp) {

        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  ListarArquivosProblema() {
    return http.get(`${this.GetNomeControle()}/ArquivosProblema`);
  }

  ListarAgrupadoPorClienteComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/agrupadoPorCliente${parametros}`);
  }

  ListarAgrupadoPorResiduoComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/agrupadoPorResiduo${parametros}`);
  }

  ListarResiduoExcelBruto(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/residuoexcelbruto${parametros}`);
  }



  GetResiduos(page: number, take: number, filtro?: any) {

    let params: any = null;
    let skip: number = 0;

    skip = (page - 1) * take;

    if (filtro) {
      params = {
        take: take,
        skip: skip,
        numeroOS: filtro.numeroOS,
        numeroOD: filtro.numeroOD,
        tecnologiaId: filtro.tecnologiaId,
        residuoId: filtro.residuoId,
        protocolo: filtro.protocolo,
        dataColetaInicial: filtro.dataColetaInicial,
        dataColetaFinal: filtro.dataColetaFinal,
        fcdr: filtro.fcdr,
        mr01: filtro.mr01,
        mr02: filtro.mr02,
        mr03: filtro.mr03,
        clienteId: filtro.clienteId,
        hasOd: filtro.hasOd
      };
    }

    return http.get(`${this.GetNomeControle()}/residuo`, {
      params: params,
      headers: httpHeader.headers
    });
  }


  ConcluirManualmente(id: number) {
    return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`, null, {
      headers: httpHeader.headers
    });
  }

  Cancelar(id: number, justificativa: string) {
    return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, null, {
      params: {
        justificativa: justificativa,
      },
      headers: httpHeader.headers
    });
  }

  Reabrir(id: number) {
    return http.post(`${this.GetNomeControle()}/${id}/Reabrir`);
  }

  Download(id: number, ordemServicoResiduoId: number, tipo: string) {
    return http.get(`${this.GetNomeControle()}/${id}/residuo/${ordemServicoResiduoId}/download/${tipo}`, { responseType: 'blob' });
  }

  AtualizarValorResiduo(item: OrdemServicoResiduo) {
    return http.post(`${this.GetNomeControle()}/AtualizarValorResiduo`, null, {
      params: {
        ordemServicoResiduoId: item.id,
        ordemServicoId: item.ordemServicoId
      },
      headers: httpHeader.headers
    });
  }

  AtualizarValorServico(item: OrdemServicoServico) {
    return http.post(`${this.GetNomeControle()}/AtualizarValorServico`, null, {
      params: {
        ordemServicoServicoId: item.id,
        ordemServicoId: item.ordemServicoId
      },
      headers: httpHeader.headers
    });
  }

  AtualizarValores(item: MedicaoOrdemServico) {
    return http.post(`${this.GetNomeControle()}/AtualizarValores`, null, {
      params: {
        ordemServicoId: item.ordemServicoId
      },
      headers: httpHeader.headers
    });
  }

  AtualizarValoresReceptor(id: number) {
    return http.post(`${this.GetNomeControle()}/AtualizarValoresReceptor`, null, {
      params: {
        ordemServicoId: id
      },
      headers: httpHeader.headers
    });
  }

  public RelatorioComItens(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/RelatorioComItens${parametros}`);

    // return http.get(`${this.GetNomeControle()}/RelatorioComItens`, {
    //   params: {

    //   },
    //   headers: httpHeader.headers
    // });
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public ListarLocacoesTroca(contratoId: number, embarcacaoId?: number) {
    return http.get(`${this.GetNomeControle()}/ListarLocacoesTroca`, {
      params: {
        contratoId,
        embarcacaoId
      }
    });
  }

  public ListarControleResiduos(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltroControleResiduos(parametros, filter);

    return http.get(`${this.GetNomeControle()}/ListarResiduos${parametros}`);

  }

  public ExportarExcel(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltroControleResiduos(parametros, filter);

    return http.get(`${this.GetNomeControle()}/GerarExcel${parametros}`, { responseType: 'blob' });
  }

  public GerarPCP(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, layout: number, completo: boolean) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltroControleResiduos(parametros, filter);

    return http.get(`${this.GetNomeControle()}/GerarPcp${parametros}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/octet-stream',
          'Access-Control-Allow-Origin': '*'
        },
        params: {
          layout,
          completo
        }
      });

  }

  private GetFiltroControleResiduos(parametros: string, filter: any) {

    if (filter) {
      let paramTemp = '';

      //Cliente
      if (filter.clienteId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ordemServico/contrato/clienteId in (${filter.clienteId})`;
      }

      //Embarcacao
      if (filter.embarcacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ordemServico/embarcacaoId in (${filter.embarcacaoId})`;
      }

      //Receptor
      if (filter.receptorId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ordemDestinacao/receptorId in (${filter.receptorId})`;
      }

      //Residuo
      if (filter.residuoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuoId in (${filter.residuoId})`;
      }

      //Situacao
      if (filter.situacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `situacaoId in (${filter.situacaoId})`;
      }

      //# OS
      if (filter.numeroOs) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ordemServico/numero in (${filter.numeroOs})`;
      }

      //# OD
      if (filter.numeroOd) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `ordemDestinacao/numero in (${filter.numeroOd})`;
      }

      //Tipo Residuo
      if (filter.tipoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuo/grupoId in (${filter.tipoId})`;
      }

      //FILTROS POR MR
      if (filter.mr01) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `processo1/mr01 eq '${filter.mr01}'`;
      }

      if (filter.mr02) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo2/mr02, '${filter.mr02}') or Contains(ordemDestinacao/processo2/mr02, '${filter.mr02}') )`;
      }

      if (filter.mr03) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo3/mr03, '${filter.mr03}') or Contains(ordemDestinacao/processo3/mr03, '${filter.mr03}') )`;
      }

      if (filter.mr04) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo4/mr04, '${filter.mr04}') or Contains(ordemDestinacao/processo4/mr04, '${filter.mr04}') )`;
      }

      //FILTROS POR CDF
      if (filter.cdf01) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `processo1/cdf01 eq '${filter.cdf01}'`;
      }

      if (filter.cdf02) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo2/cdf02, '${filter.cdf02}') or Contains(ordemDestinacao/processo2/cdf02, '${filter.cdf02}') )`;
      }

      if (filter.cdf03) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo3/cdf03, '${filter.cdf03}') or Contains(ordemDestinacao/processo3/cdf03, '${filter.cdf03}') )`;
      }

      if (filter.cdf04) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `( Contains(processo4/cdf04, '${filter.cdf04}') or Contains(ordemDestinacao/processo4/cdf04, '${filter.cdf04}') )`;
      }

      //FILTRO POR DATA
      if (filter.dataInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataColeta ge ${filter.dataInicial}`;
      }

      if (filter.dataFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataColeta le ${filter.dataFinal}`;
      }

      if (paramTemp) {

        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  public QuantidadeResiduos(residuos: any) {
    return http.post(`${this.GetNomeControle()}/QuantidadeResiduos`, residuos);
  }

  public RelatorioLocacao(numOS?: number, clienteId?: number, embarcacaoId?: number, equipamentoId?: number, equipamentoTrocaId?: number, dataInicio?: string, dataFim?: string, ordenacao?: string) {
    return http.get(`${this.GetNomeControle()}/RelatorioLocacao`, {
      params: {
        numOS,
        clienteId,
        embarcacaoId,
        equipamentoId,
        equipamentoTrocaId,
        dataInicio,
        dataFim,
        ordenacao
      }
    });
  }

  public RelatorioDestinacao(numOs?: number, numOd?: number, clienteId?: number, embarcacaoId?: number, mr1?: string, residuoId?: number, dataInicio?: string, dataFim?: string) {
    return http.get(`${this.GetNomeControle()}/RelatorioDestinacao`, {
      params: {
        numOs,
        numOd,
        clienteId,
        embarcacaoId,
        mr1,
        residuoId,
        dataInicio,
        dataFim
      }
    });
  }

  public RelatorioOsSemOd() {
    return http.get(`${this.GetNomeControle()}/RelatorioOsSemOd`);
  }

  public DownloadDocumentos(id: number, consolidado: boolean) {
    return http.get(`${this.GetNomeControle()}/${id}/GetDocumentos`,      
      {
        params: {
          consolidado
        },
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/octet-stream',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
  }
}