import { Documento, Veiculo } from ".";

export class VeiculoDocumento implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    veiculoId: number = 0;
    veiculo!: Veiculo;

    constructor(model?: VeiculoDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
    }
}