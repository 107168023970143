var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.Close()}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.Close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Selecionar Ordem de Destinação")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","disabled":_vm.localSelected.length == 0},on:{"click":function($event){return _vm.Selecionar()}}},[_vm._v("Selecionar")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLista,"sort-by":"data","sort-desc":true,"footer-props":{ showFirstLastPage: true},"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"fab":"","small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){_vm.sheet = true}}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])])],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":isSelected},on:{"change":function($event){return select($event)},"input":function($event){return select($event)}}})]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.toDateDDMMYYYY())+" ")]}},{key:"item.valorResiduos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.valorResiduos.toMoeda())+" ")]}},{key:"item.valorResiduosReceptor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.valorResiduosReceptor.toMoeda())+" ")]}}],null,true),model:{value:(_vm.localSelected),callback:function ($$v) {_vm.localSelected=$$v},expression:"localSelected"}})],1),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"size":"30","color":"primary"}},[_vm._v("mdi-filter")]),_c('div',{staticClass:"pl-3"},[_c('h4',{staticClass:"font-weight-medium"},[_vm._v("Filtros")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Número","dense":"","outlined":""},model:{value:(_vm.filtro.numero),callback:function ($$v) {_vm.$set(_vm.filtro, "numero", _vm._n($$v))},expression:"filtro.numero"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-text-field',{attrs:{"type":"date","label":"Data Inicial","dense":"","outlined":""},model:{value:(_vm.filtro.dataInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "dataInicial", $$v)},expression:"filtro.dataInicial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-text-field',{attrs:{"type":"date","label":"Data Final","dense":"","outlined":""},model:{value:(_vm.filtro.dataFinal),callback:function ($$v) {_vm.$set(_vm.filtro, "dataFinal", $$v)},expression:"filtro.dataFinal"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.Atualizar(true)}}},[_vm._v("Consultar")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }