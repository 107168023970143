import { Empresa, GrupoDocumentoUsuario } from ".";

export class GrupoDocumento implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    descricao: string = "";
    editor: boolean = false;
    
    usuarios: GrupoDocumentoUsuario[] = [];

    constructor(model?: GrupoDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.descricao = model.descricao;
        this.editor = model.editor;

        this.usuarios = model.usuarios;
    }
}