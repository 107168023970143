import { Service } from '../shared/';
import {http, httpHeader} from '../../ApiConfig'

export class ResiduoService extends Service{

  constructor(){
    super('residuo');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
            q: q
        },
        headers: httpHeader.headers
      }
    );
  } 

  MontaFiltro(filter: any){

    if (filter) {
        let paramTemp = '';

        if (filter.nome){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `Contains(nome,'${filter.nome}')`;
        }

        if (filter.tecnologiaId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `tecnologiaId eq ${filter.tecnologiaId}`;
        }

        if (filter.grupoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `grupoId eq ${filter.grupoId}`;
        }

        if (filter.unidadeMedidaId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `unidadeMedidaId eq ${filter.unidadeMedidaId}`;
        }

        if(filter.ativo != null){
          if (paramTemp){ paramTemp += ' and '; }
          paramTemp += `ativo eq ${filter.ativo}`;
        }
        
        return paramTemp;
    }
  }
}