import { OrdemDestinacao, Servico } from ".";
import { UnidadeMedida } from "../geral";

export class OrdemDestinacaoServicoTransportadora implements Shared.IEntity{
    
    id: number = 0;
    ordemDestinacaoId: number = 0;
    ordemDestinacao!: OrdemDestinacao;
    servicoId: number = 0;
    servico!: Servico;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    valor: number = 0;
    quantidade: number = 0;

    excluido: boolean = false;

    constructor(model?: OrdemDestinacaoServicoTransportadora){

        if(!model)
            return;

        this.id = model.id;
        this.ordemDestinacaoId = model.ordemDestinacaoId;
        this.ordemDestinacao = model.ordemDestinacao;
        this.servicoId = model.servicoId;
        this.servico = model.servico;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.valor = model.valor;
        this.quantidade = model.quantidade;
    }

}