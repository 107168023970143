import { Empresa, GrupoDocumento, Usuario } from ".";

export class GrupoDocumentoUsuario implements Shared.IEntity{
    
    id: number = 0;
    usuarioId: number = 0;
    usuario!: Usuario;
    grupoId: number = 0;
    grupo!: GrupoDocumento;
    

    constructor(model?: GrupoDocumentoUsuario){
        
        if(!model)
            return;

        this.id = model.id;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
    }

}