import { OrdemDestinacao } from './OrdemDestinacao';
import { Residuo, OrdemServico, SituacaoOrdemServicoResiduo } from './';
import { UnidadeMedida } from '../geral/index';
import { OrdemServicoResiduoArquivo } from './OrdemServicoResiduoArquivo';
import { EnumSituacaoOrdemServicoResiduo } from './Enumerados';
import { Tecnologia } from './Tecnologia';

export class OrdemServicoResiduo implements Shared.IEntity {

    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    ordemDestinacaoId?: number;
    ordemDestinacao!: OrdemDestinacao;
    dataColeta: string = new Date().toYYYYMMDD();
    residuoId: number = 0;
    residuo!: Residuo;
    unidadeId: number = 0;
    unidade!: UnidadeMedida;
    quantidadeEstimada: number = 0;
    quantidade: number = 0;
    quantidadeReceptor: number = 0;
    valor: number = 0;
    registroTransporte: string = '';
    processo1: OrdemServicoProcesso1 = new OrdemServicoProcesso1();
    processo2: OrdemServicoProcesso2 = new OrdemServicoProcesso2();
    processo3: OrdemServicoProcesso3 = new OrdemServicoProcesso3();
    processo4: OrdemServicoProcesso4 = new OrdemServicoProcesso4();
    arquivo: OrdemServicoResiduoArquivo = new OrdemServicoResiduoArquivo();
    situacaoId: EnumSituacaoOrdemServicoResiduo = EnumSituacaoOrdemServicoResiduo.EmAndamento;
    situacao?: SituacaoOrdemServicoResiduo;
    quantidadeOriginal: number = 0;
    quantidadeOriginalReceptor: number = 0;
    contentor: string = '';
    
    change: boolean = false;
    excluido: boolean = false;

    constructor(model?: OrdemServicoResiduo) {

        if (!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.ordemDestinacaoId = model.ordemDestinacaoId;
        this.ordemDestinacao = model.ordemDestinacao;
        this.dataColeta = model.dataColeta.toDateYYYYMMDD();
        this.residuoId = model.residuoId;
        this.residuo = model.residuo;
        this.unidadeId = model.unidadeId;
        this.unidade = model.unidade;
        this.quantidade = model.quantidade;
        this.quantidadeReceptor = this.quantidadeReceptor;
        this.quantidadeEstimada = model.quantidadeEstimada;
        this.valor = model.valor;
        this.registroTransporte = model.registroTransporte;
        this.processo1 = model.processo1;
        this.processo2 = model.processo2;
        this.processo3 = model.processo3;
        this.processo4 = model.processo4;
        this.arquivo = model.arquivo;
        this.situacaoId = model.situacaoId;
        this.contentor = model.contentor;
    }
}

export class OrdemServicoProcesso1 {
    mr01: string = '';
    dataMr01?: string;
    cdf01: string = '';
    dataCdf01?: string;
    fcdr: string = '';
    dataFcdr?: string;
    cartaResponsabilidade: string = '';
    dataCartaResponsabilidade?: string;
    ticketPesagem: string = '';
    dataTicketPesagem?: string;
    relatorioObservacao: string = '';
    dataRelatorioObservacao?: string;
    tecnologiaId?: number;
    tecnologia!: Tecnologia;
    dataTecnologia?: string;

    constructor(model?: OrdemServicoProcesso1) {

        if (!model)
            return;

        this.mr01 = model.mr01;
        this.dataMr01 = model.dataMr01;
        this.cdf01 = model.cdf01;
        this.dataCdf01 = model.dataCdf01;
        this.fcdr = model.fcdr;
        this.dataFcdr = model.dataFcdr;
        this.cartaResponsabilidade = model.cartaResponsabilidade;
        this.dataCartaResponsabilidade = model.cartaResponsabilidade;
        this.ticketPesagem = model.ticketPesagem;
        this.dataTicketPesagem = model.dataTicketPesagem;
        this.relatorioObservacao = model.relatorioObservacao;
        this.dataRelatorioObservacao = model.dataRelatorioObservacao;
        this.tecnologiaId = model.tecnologiaId;
        this.tecnologia = model.tecnologia;
        this.dataTecnologia = model.dataTecnologia;
    }
}

export class OrdemServicoProcesso2 {
    mr02: string = '';
    dataMr02?: string;
    cdf02: string = '';
    dataCdf02?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;

    constructor(model?: OrdemServicoProcesso2) {

        if (!model)
            return;

        this.mr02 = model.mr02;
        this.dataMr02 = model.dataMr02;
        this.cdf02 = model.cdf02;
        this.dataCdf02 = model.dataMr02;
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
    }
}

export class OrdemServicoProcesso3 {
    mr03: string = '';
    dataMr03?: string;
    cdf03: string = '';
    dataCdf03?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;

    constructor(model?: OrdemServicoProcesso3) {

        if (!model)
            return;

        this.mr03 = model.mr03;
        this.dataMr03 = model.dataMr03;
        this.cdf03 = model.cdf03;
        this.dataCdf03 = model.dataCdf03
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
    }
}

export class OrdemServicoProcesso4 {
    mr04: string = '';
    dataMr04?: string;
    cdf04: string = '';
    dataCdf04?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;

    constructor(model?: OrdemServicoProcesso4) {

        if (!model)
            return;

        this.mr04 = model.mr04;
        this.dataMr04 = model.dataMr04;
        this.cdf04 = model.cdf04;
        this.dataCdf04 = model.dataCdf04;
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
    }
}