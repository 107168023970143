import { OrdemServicoLocacao } from './OrdemServicoLocacao';
import { Medicao } from './Medicao';
import { SituacaoOrdemServico } from './SituacaoOrdemServico';
import { Motorista, Veiculo } from '../geral';
import { Base, Embarcacao, OrdemServicoResiduo, Porto, OrdemServicoServico, Contrato, Transportadora, OrdemServicoFoto } from './';
import { Cancelamento } from '../shared/Cancelamento';
import { EnumSituacaoOrdemServico } from './Enumerados';
import { OrdemServicoServicoTransportadora } from './OrdemServicoServicoTransportadora';

export class OrdemServico implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    data: string = new Date().toYYYYMMDD();
    contratoId: number = 0;
    contrato!: Contrato;    
    portoId?: number;
    porto!: Porto;
    embarcacaoId?: number;
    embarcacao!: Embarcacao;
    protocolo: string = '';
    baseId?: number;
    base!: Base;
    motoristaId?: number;
    motorista!: Motorista;
    veiculoId?: number;
    veiculo!: Veiculo;
    situacaoId: EnumSituacaoOrdemServico = EnumSituacaoOrdemServico.Aberta;
    situacao!: SituacaoOrdemServico;
    transportadoraId?: number;
    transportadora!: Transportadora;
    locacoes: OrdemServicoLocacao[]=[];
    medicaoId?: number;
    medicao!: Medicao; 
    residuos: OrdemServicoResiduo[]=[];
    servicos: OrdemServicoServico[]=[];
    servicosTransportadora: OrdemServicoServicoTransportadora[] = [];
    fotos: OrdemServicoFoto[] = [];
    controleCliente: OrdemServicoControle = new OrdemServicoControle();
    controleEmpresa: OrdemServicoControle = new OrdemServicoControle();
    observacao: string = '';
    acrescimo: number = 0;
    decrescimo: number = 0;
    excluido: boolean = false;
    atualizado: boolean = false;
    cancelamento?: Cancelamento;
    unidadeGeradora: string = '';
    total: number = 0;

    constructor(model?: OrdemServico){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.data = model.data.toDateYYYYMMDD();
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.portoId = model.portoId;
        this.embarcacaoId = model.embarcacaoId;
        this.protocolo = model.protocolo;
        this.baseId = model.baseId;
        this.motoristaId = model.motoristaId;
        this.motorista = model.motorista;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.situacaoId = model.situacaoId;
        this.transportadoraId = model.transportadoraId;
        this.locacoes = model.locacoes;
        this.residuos = model.residuos;
        this.servicos = model.servicos;
        this.servicosTransportadora = model.servicosTransportadora;
        this.fotos = model.fotos;
        this.controleCliente = model.controleCliente;
        this.controleEmpresa = model.controleEmpresa;
        this.acrescimo = model.acrescimo;
        this.decrescimo = model.decrescimo;
        this.observacao = model.observacao;
        this.cancelamento = model.cancelamento;
        this.unidadeGeradora = model.unidadeGeradora;
        this.total = model.total;
    }
}

export class OrdemServicoControle{
    
    dataHoraChegada: string = '';
    kmChegada: number = 0;
    dataHoraSaida: string = '';
    kmSaida: number = 0;

    constructor(model?: OrdemServicoControle){
        
        if(!model)
            return;

        this.dataHoraChegada = model.dataHoraChegada.toDateYYYYMMDD();
        this.kmChegada = model.kmChegada;
        this.dataHoraSaida = model.dataHoraSaida.toDateYYYYMMDD();
        this.kmSaida = model.kmSaida;
    }
}