import { Cliente } from './../geral/Cliente';
import { GrupoEquipamento } from './';
import { Locatario } from '../geral';

export class Equipamento implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    codigo: string = '';
    nome: string = '';
    grupoId: number = 0;
    grupo!: GrupoEquipamento;
    clienteId?: number;
    cliente!: Cliente;
    locatarioId?: number;
    locatario!: Locatario;
    ativo: boolean = true;

    vincular: boolean = true;

    constructor(model?: Equipamento){
        
        if(!model)
            return;
            
        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.grupoId = model.grupoId;
        this.clienteId = model.clienteId;
        this.ativo = model.ativo;
    }
}