
































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { VeiculoService, TipoVeiculoService } from "@/core/services/geral";
import { Veiculo, VeiculoDocumento } from "@/core/models/geral";
import { ArquivoService } from "@/core/services/shared";
import { TransportadoraService } from "@/core/services/residuo";
import { PageBase } from "@/core/models/shared";

@Component
export default class CadastroVeiculo extends PageBase {
  @Prop() private item!: Veiculo;
  @Prop() private value!: string;

  itemOriginal!: Veiculo;

  service = new VeiculoService();
  valid = true;
  validArquivo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tipos: any[] = []
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  $refs!: {
    formVeiculo: HTMLFormElement,
  }

  dialogFoto: boolean = false;
  carouselFoto: any = null;

  transportadoras: any[] = []
  transportadoraService = new TransportadoraService()

  headerDocumentos: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Tipo', value: 'documento.tipo.nome' },
    { text: 'Data Validade', value: 'documento.dataValidade' },
  ];

  documento = new VeiculoDocumento();
  documentos: VeiculoDocumento[] = [];
  totalDocumentos: number = 0;
  optionsDocumentos: any = {
    itemsPerPage: -1
  }
  loadingDocumentoDialog: boolean = false;
  loadingDocumentos: boolean = false;
  dialogDocumento: boolean = false;
  loadingDownload: boolean = false;

  @Watch('item')
  Item() {
    if (this.$refs.formVeiculo) {
      this.$refs.formVeiculo.resetValidation();
    }
    if(this.item.id > 0){
      this.CarregarDocumentos();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    const tipoVeiculoSerivce = new TipoVeiculoService();
    tipoVeiculoSerivce.ListarTudo(undefined, "Modal").then(
      res => {
        this.tipos = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );

    this.transportadoraService.ListarTudo().then(
      res=>{
        this.transportadoras = res.data.items
        this.transportadoras.unshift({
          id: null,
          nome: ''
        })
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )
  }

  @Watch('optionsDocumentos', { deep: true })
  CarregarDocumentos(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsDocumentos;
    this.loadingDocumentos = true;
    this.service.ListarDocumentos(this.item.id, page, itemsPerPage, sortBy, sortDesc, search, this.headerDocumentos).then(
      res =>{
        this.documentos = res.data.items;
        this.totalDocumentos = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loadingDocumentos = false;
    })
  }

  AbrirCadastroDocumento(documento?: VeiculoDocumento){
    if(documento){
      this.loadingDocumentoDialog = true;
      this.service.ObterDocumentoPorId(documento.id).then(
        res=> {
          this.documento = res.data;
          this.documento.documento.dataValidade = this.documento.documento.dataValidade? this.documento.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogDocumento = true;
        },
        err=> {
          this.$swal('Aviso', err.message, 'error');
        }
      ).finally(() => {
        this.loadingDocumentoDialog = false;
      })
    }
    else{
      this.documento = new VeiculoDocumento();
      this.documento.veiculoId = this.item.id;
      this.documento.documento.empresaId = this.app.empresaId;
      this.dialogDocumento = true;
    }
  }

  ExcluirDocumento(documento: VeiculoDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(documento.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
    .then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        this.CarregarDocumentos();
      }
    })
  }

  AbrirLink(link){
    if(!!link)
      window.open(link,"_blank");
  }

  DownloadDocumento(documento: VeiculoDocumento){
    this.loadingDownload = true;
    this.service.DownloadDocumento(documento.id).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.documento.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }

  Salvar() {
    if (this.$refs.formVeiculo.validate()) {

      if(this.app.transportadoraId){
        this.item.transportadoraId = this.app.transportadoraId;
      }

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.documentos = [];
    this.dialog = false;
  }
}
