















































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { PerfilUsuarioService, UsuarioService, ClienteService } from "@/core/services/geral";
import { Usuario, PerfilUsuario, Cliente, UsuarioOrdemServicoRestricao } from "@/core/models/geral";
import { ArquivoService } from '@/core/services/shared';
import { Embarcacao, Transportadora } from "@/core/models/residuo";
import { TransportadoraService } from "@/core/services/residuo";

@Component
export default class CadastroUsuario extends Vue {
  @Prop() private item!: Usuario;
  @Prop() private value!: string;

  itemOriginal!: Usuario;
  service = new UsuarioService();

  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  $refs!: {
    form: HTMLFormElement
  }
  
  foto: any = null;
  assinatura: any = null;
  
  perfis: PerfilUsuario[] = [];
  
  clienteEmbarcacoes: Embarcacao[] = [];
  
  clientes: Cliente[] = [];
  clienteService: ClienteService = new ClienteService();
  onSearchCliente: any = null;
  isClienteLoading: boolean = false;
  
  transportadoras: Transportadora[] = [];
  onSearchTransportadora: any = null;
  isTransportadoraLoading: boolean = false;

  headerOrdemServicos: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Embarcações', value: 'embarcacoes'},
    { text: 'Data Inicial', value: 'dataInicial'},
    { text: 'Data Final', value: 'dataFinal'}
  ];

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      
      this.WatchCliente();

      this.item.ordemServicoRestricoes.forEach(restricao => {
        restricao.embarcacoesSelecionadas = restricao.embarcacoes.split(",").map(x => Number.parseInt(x));
      });
    }
    else{
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('item.clienteId')
  WatchCliente(){
    if(!!this.item.clienteId){

      this.clientes = [];
      this.clientes.push(this.item.cliente);

      this.clienteService.Embarcacoes(this.item.clienteId).then(
        res => {
          this.clienteEmbarcacoes = res.data;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      )
    }
  }

  @Watch('onSearchCliente')
  searchCliente (val: string) {
    if(this.item.clienteId) return;
    if (this.isClienteLoading) return;
    if (!val) return;

    this.isClienteLoading = true;    
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isClienteLoading = false
    });
  }

  @Watch('onSearchTransportadora')
  searchTransportadora (val: string) {
    if (this.item.transportadoraId) return;
    if (this.isTransportadoraLoading) return;
    if (!val) return;

    this.isTransportadoraLoading = true
    const transportadoraService = new TransportadoraService();
    transportadoraService.AutoComplete(val).then(
      res => {
        this.transportadoras = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isTransportadoraLoading = false
    });
  }

  mounted() {
    const perfilervice = new PerfilUsuarioService();
    perfilervice.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );
  }

  async LoadImage(){
    if (!this.foto)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.foto);
    this.item.foto = dados.replace(/^[^,]*,/, "");
  }

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  async LoadAssinatura(){
    if (!this.assinatura)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.assinatura);
    this.item.assinatura = dados.replace(/^[^,]*,/, "");
  }

  RemoveAssinatura(){
    this.item.assinatura = null;
    this.assinatura = null;
  }

  AdicionarOrdemServicoRestricao(){
    var obj = new UsuarioOrdemServicoRestricao();
    obj.usuarioId = this.item.id;
    this.item.ordemServicoRestricoes.push(obj);
  }

  ExcluirOrdemServicoRestricao(item: UsuarioOrdemServicoRestricao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const index = this.item.ordemServicoRestricoes.indexOf(item);
        this.item.ordemServicoRestricoes.splice(index, 1);
      }
    })
  }

  AtribuirEmbarcacoes(){
    this.item.ordemServicoRestricoes.forEach(restricao => {
      if(restricao.embarcacoesSelecionadas.length == 1)
        restricao.embarcacoes = restricao.embarcacoesSelecionadas[0].toString();
      else
        restricao.embarcacoes = restricao.embarcacoesSelecionadas.join(",");

      delete restricao.embarcacoesSelecionadas;
    });
  }

  Salvar() {
    if (this.$refs.form.validate()) {

      this.AtribuirEmbarcacoes();
      
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);

      if(!this.item.ordemServicoRestricoes.every(x => x.embarcacoes)){
        this.$swal('Aviso', "Não é possível inserir uma restrição de OS sem nenhuma embarcação selecionada!", 'warning');
        return;
      }

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
