import { ContratoReceptor } from './ContratoReceptor';
import { Transportadora } from './Transportadora';
import { Medicao, OrdemDestinacaoServicoTransportadora, OrdemServicoResiduo, Tecnologia } from '.';
import { Receptor } from './Receptor';
import { OrdemDestinacaoArquivo } from './OrdemDestinacaoArquivo';
import { Motorista, Veiculo } from '../geral';

export class OrdemDestinacao implements Shared.IEntity {

    id: number = 0;
    numero: number = 0;
    empresaId: number = 0;
    receptorId: number = 0;
    receptor!: Receptor;
    transportadoraId: number = 0;
    transportadora!: Transportadora;
    motoristaId?: number;
    motorista!: Motorista;
    veiculoId?: number;
    veiculo!: Veiculo;
    contratoReceptorId?: number;
    contratoReceptor!: ContratoReceptor;
    medicaoId?: number;
    medicao!: Medicao;
    data: string = new Date().toYYYYMMDD();
    servicosTransportadora: OrdemDestinacaoServicoTransportadora[] = [];
    residuos: OrdemServicoResiduo[] = [];
    observacao: string = '';
    processo2: OrdemDestinacaoProcesso2 = new OrdemDestinacaoProcesso2();
    processo3: OrdemDestinacaoProcesso3 = new OrdemDestinacaoProcesso3();
    processo4: OrdemDestinacaoProcesso4 = new OrdemDestinacaoProcesso4();
    arquivo: OrdemDestinacaoArquivo = new OrdemDestinacaoArquivo();
    detalhes: string = '';
    atualizado: boolean = false;
    excluido: boolean = false;

    constructor(model?: OrdemDestinacao) {

        if (!model)
            return;

        this.id = model.id;
        this.numero = model.numero;
        this.receptorId = model.receptorId;
        this.receptor = model.receptor;
        this.transportadoraId = model.transportadoraId;
        this.transportadora = model.transportadora;
        this.motoristaId = model.motoristaId;
        this.motorista = model.motorista;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.medicaoId = model.medicaoId;
        this.medicao = model.medicao;
        this.contratoReceptorId = model.contratoReceptorId;
        this.contratoReceptor = model.contratoReceptor;
        this.data = model.data.toDateYYYYMMDD();
        this.servicosTransportadora = model.servicosTransportadora;
        this.residuos = model.residuos;
        this.observacao = model.observacao;
        this.processo2 = model.processo2;
        this.processo3 = model.processo3;
        this.processo4 = model.processo4;
        this.arquivo = model.arquivo;
        this.detalhes = model.detalhes;
    }
}

export class OrdemDestinacaoProcesso2 {
    mr02: string = '';
    dataMr02?: string;
    cdf02: string = '';
    dataCdf02?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;
    tecnologiaId?: number;
    tecnologia!: Tecnologia;
    dataTecnologia?: string;

    constructor(model?: OrdemDestinacaoProcesso2) {

        if (!model)
            return;

        this.mr02 = model.mr02;
        this.dataMr02 = model.dataMr02;
        this.cdf02 = model.cdf02;
        this.dataCdf02 = model.dataCdf02;
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
        this.tecnologiaId = model.tecnologiaId;
        this.tecnologia = model.tecnologia;
        this.dataTecnologia = model.dataTecnologia;
    }
}
export class OrdemDestinacaoProcesso3 {
    mr03: string = '';
    dataMr03?: string;
    cdf03: string = '';
    dataCdf03?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;
    tecnologiaId?: number;
    tecnologia!: Tecnologia;
    dataTecnologia?: string;

    constructor(model?: OrdemDestinacaoProcesso3) {

        if (!model)
            return;

        this.mr03 = model.mr03;
        this.dataMr03 = model.mr03;
        this.cdf03 = model.cdf03;
        this.dataCdf03 = model.dataCdf03;
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
        this.tecnologiaId = model.tecnologiaId;
        this.tecnologia = model.tecnologia;
        this.dataTecnologia = model.dataTecnologia;
    }
}
export class OrdemDestinacaoProcesso4 {
    mr04: string = '';
    dataMr04?: string;
    cdf04: string = '';
    dataCdf04?: string;
    relatorioRecebimento: string = '';
    dataRelatorioRecebimento?: string;
    tecnologiaId?: number;
    tecnologia!: Tecnologia;
    dataTecnologia?: string;

    constructor(model?: OrdemDestinacaoProcesso4) {

        if (!model)
            return;

        this.mr04 = model.mr04;
        this.dataMr04 = model.dataMr04;
        this.cdf04 = model.cdf04;
        this.dataCdf04 = model.dataCdf04;
        this.relatorioRecebimento = model.relatorioRecebimento;
        this.dataRelatorioRecebimento = model.dataRelatorioRecebimento;
        this.tecnologiaId = model.tecnologiaId;
        this.tecnologia = model.tecnologia;
        this.dataTecnologia = model.dataTecnologia;
    }
}