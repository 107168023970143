













































































import { Vue, Component, Watch } from 'vue-property-decorator';
import {AutenticadorService, EmpresaService}  from '@/core/services/geral';

@Component
export default class Login extends Vue {
    //nomeLogo: string = 'transparente.png';
    //nomeLogo: string = '1.png';
    logo: string = '';
    hidePassword: boolean = true;
    loading: boolean = false;
    showPassword: boolean = false;
    acesso: {login: string, senha: string} = {
      login: '',
      senha: ''
    };
    fieldRules: any[] = [
      (v: any) => !!v || 'Campo obrigatório',
    ]
    service = new AutenticadorService();
    recuperarSenha: boolean = false;
    token: string = "";

    @Watch('recuperarSenha')
    ResetarForm(){
      this.acesso.login = "";
      this.acesso.senha = "";
    }
    
    Autenticar(){
      this.loading = true;
      this.service.AutenticarUsuario(this.acesso).then(
        res => {
          this.$swal('Aviso', res.data.message, res.status == 200 ? 'success' : 'warning');
          localStorage.setItem('sessionApp', JSON.stringify(res.data));
          this.$router.push({ name: 'home' });
        }, 
        err => {
          localStorage.clear();
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
        }
      ).finally(() => this.loading = false);
    }

    Redefinir(){
      this.loading = true;
      this.service.ResetarSenha(this.acesso.login, this.token, this.acesso.senha).then(
        res => {
          this.$swal('Aviso', res.data, 'success');
          this.$router.replace({name:'login'})            
          setTimeout(() => {
            location.reload(true);
          }, 3000);
        },
        err => {
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
        }
      ).finally(() => {
        this.loading = false;
      })
    }

    mounted() {

      if(this.$route.params.token){
        this.token = this.$route.params.token;
      }      
      const empresaService = new EmpresaService();
      empresaService.ObterLogo().then(
        res => {
          this.logo = res.data;
        }
      );

    }

}
