import { Contrato } from ".";
import { Usuario } from "../geral";

export class UsuarioContrato implements Shared.IEntity{
    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    usuarioId: number = 0;
    usuario!: Usuario;

    constructor(model?: UsuarioContrato){
        
        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.usuarioId = model.usuarioId;
    }
}