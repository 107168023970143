import { Service } from '../shared/';
import {http, httpHeader} from '../../ApiConfig'

export class ServicoService extends Service{

  constructor(){
    super('servico');
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
            q: q
        },
        headers: httpHeader.headers
    });
  } 

  MontaFiltro(filter: any){

    if (filter) {
        let paramTemp = '';

        if (filter.nome){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `Contains(nome,'${filter.nome}')`;
        }

        if (filter.grupoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `grupoId eq ${filter.grupoId}`;
        }

        if(filter.ativo != null){
          if (paramTemp){ paramTemp += ' and '; }
          paramTemp += `ativo eq ${filter.ativo}`;
        }
        
        return paramTemp;
    }
  }

}