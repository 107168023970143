import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class MedicaoReceptorService extends Service{

  constructor(){
    super('medicaoReceptor');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if (filter.numero){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }
  
      if (filter.receptorId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `receptorId eq ${filter.receptorId}`;
      }
  
      if (filter.dataInicial){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }
      
      if (filter.dataFinal){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }
    return parametros;
  }

  VincularOrdemDestinacao(odId: number,id?: number){
    return http.post(`${this.GetNomeControle()}/VincularOrdemDestinacao`, null, {
      params: {
        id: id,
        ordemDestinacaoId: odId
      },
      headers: httpHeader.headers
    });
  }

  Detalhar(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
  }

  MontaFiltro(filter: any){
    
    let paramTemp = '';

    if (filter.numero){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `numero eq ${filter.numero}`;
    }

    if (filter.receptorId){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `receptorId eq ${filter.receptorId}`;
    }

    if (filter.dataInicial){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `data ge ${filter.dataInicial}`;
    }
    
    if (filter.dataFinal){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `data le ${filter.dataFinal}`;
    }

    return paramTemp;  
  }

  ExcelBruto(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/excelBruto${parametros}`);
  }
}