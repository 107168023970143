import { TipoVeiculo } from "../geral";
import { Pergunta } from "./Pergunta";

export class TipoVeiculoPerguntas implements Shared.IEntity{

    id: number = 0;
    tipoVeiculoId: number = 0;
    tipoVeiculo!: TipoVeiculo;
    perguntaId: number = 0;
    pergunta!: Pergunta; 
}