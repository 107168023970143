import { OrdemServico } from '../interna';
import { Medicao } from './Medicao';

export class MedicaoOrdemServico{
    
    medicaoId: number = 0;
    medicao!: Medicao;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    excluido: boolean = false;
    atualizado: boolean = false;

    constructor(model?: MedicaoOrdemServico){
        
        if(!model)
            return;

        this.medicaoId = model.medicaoId;
        this.medicao = model.medicao;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
    }

}