import { Service } from '../shared/';
import {http, httpHeader} from '../../ApiConfig'

export class ClienteService extends Service{

  constructor(){
    super('cliente');
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public Embarcacoes(id: number) {
    return http.get(`${this.GetNomeControle()}/${id}/Embarcacoes`);
  }

  BaixarEmbarcacaoDocumento(id: number, embarcacaoId: number, documentoId: number){
    return http.get(`${this.GetNomeControle()}/${id}/embarcacao/${embarcacaoId}/documento/${documentoId}`, {responseType: 'blob'});
  }

}