import { Empresa } from '../geral';
import { Contato } from './../shared/Contato';
import { PessoaJuridica } from './../shared/Pessoa';
import { ReceptorTecnologia } from './';

export class Receptor extends PessoaJuridica {

    empresaId: number = 0;
    empresa!: Empresa;
    contato: Contato = new Contato();
    mapaUrl: string = '';    
    pcpCodigo: string = '';
    licenca: string = '';

    tecnologias: ReceptorTecnologia[] = [];

    constructor(model?: Receptor) {

        super(model);

        if (!model)
            return;

        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.contato = model.contato;
        this.mapaUrl = model.mapaUrl;        
        this.pcpCodigo = model.pcpCodigo;
        this.licenca = model.licenca;

        this.tecnologias = model.tecnologias;
    }

}