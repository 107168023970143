



























import { Pergunta } from "@/core/models/monitoramento";
import { PerguntaService } from "@/core/services/monitoramento";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelecionarPergunta extends Vue {
  @Prop() private selected!: Pergunta[];
  @Prop() private value!: boolean;

  service = new PerguntaService();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  options: any = {
    itemsPerPage: 15
  };
  totalLista: number = 0;
  localSelected: Pergunta[] = [];
  loading: boolean = false;
  lista: any[] = [];
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "Texto", value: "texto" }, 
  ];


  @Watch("options", { deep: true })
  Atualizar() {

    this.loading = true;

    this.service.ListarTudo()
      .then(
        res => {
          this.lista = res.data.items;
          this.totalLista = res.data.count;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
      .finally(
        () => (this.loading = false)
      );
  }

  @Watch("value")
  Value() {
    if (this.value){
      this.localSelected.length = 0;
      this.Atualizar();
    }
  }

  mounted() {
    
  }

  Selecionar() {

    this.$emit('update:selected', this.localSelected);

    if (this.localSelected.length > 0){
      this.$emit('selecionou');
    }

    this.Close();
  }

  Close() {
    this.$emit("fechou");
  }
}
