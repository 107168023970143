
























































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { OrdemDestinacaoService, ReceptorService, OrdemServicoService, TransportadoraService, ContratoReceptorService, TecnologiaService } from "@/core/services/residuo";
import { OrdemDestinacao,OrdemDestinacaoServicoTransportadora,OrdemServicoResiduo, Tecnologia, Transportadora, TransportadoraServico } from "@/core/models/residuo";
import { Arquivo,PageBase } from '@/core/models/shared';
import { ArquivoService } from '@/core/services/shared';
import { EmpresaService, MotoristaService, VeiculoService } from "@/core/services/geral";
import { EmpresaConfiguracao, Motorista, Veiculo } from "@/core/models/geral";

@Component
export default class CadastroGrupoServico extends PageBase {
  @Prop() private item!: OrdemDestinacao;
  @Prop() private value!: string;

  itemOriginal!: OrdemDestinacao;
  arquivoService = new ArquivoService();
  save:boolean = false
  service = new OrdemDestinacaoService();
  receptor = new ReceptorService();
  ordemServico = new OrdemServicoService();
  valid = true;
  dialog = false;
  tabActive: any = {};
  tabActive2: any = {};
  dialogSelecionarResiduo = false;
  receptores: any[] = [];
  ordemServicoResiduos: OrdemServicoResiduo[] = [];
  isReceptorLoading = false;
  onSearchReceptor: any = null;
  headerResiduo: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "OS #", value: "ordemServico.numero" },
    { text: "Cliente", value: "ordemServico.contrato.cliente.nome" },
    { text: "Protocolo", value: "ordemServico.protocolo" },
    { text: "Resíduo", value: "residuo.nome" },
    { text: "MR01", value: "processo1.mr01" },
    { text: "MMR / FCDR", value: "processo1.fcdr" },
    { text: "Situação", value: "situacaoId" },
    { text: "Coleta", value: "dataColeta" },
    { text: "Unidade", value: "unidade.sigla" },
    { text: "Qtd. Ordem Serviço", value: "quantidade" },
    { text: "Qtd. Receptor", value: "quantidadeReceptor" }
  ];

  ordemDestinacaoServicoTransportadora: OrdemDestinacaoServicoTransportadora = new OrdemDestinacaoServicoTransportadora();
  servicosTransportadora: TransportadoraServico[] = [];
  transportadoraServico: TransportadoraServico = new TransportadoraServico();
  validServicoTransportadora: boolean = false;
  headerServicoTransportadora: any[] = [
    { text: '',value:'actions', sortable: false },
    { text: 'Serviço', value: 'servico.codigoNome' },
    { text: 'Unidade Medida', value: 'unidadeMedida.nome' },
    { text: 'Quantidade', value: 'quantidade' },
  ];

  arquivo: any  = {
    mr02: null,
    mr02Chip: true,
    cdf02: null,
    cdf02Chip: true,
    relatorioRecebimento2: null,
    relatorioRecebimento2Chip: true,
    mr03: null,
    mr03Chip: true,
    cdf03: null,
    cdf03Chip: true,
    relatorioRecebimento3: null,
    relatorioRecebimento3Chip: true,
    mr04: null,
    mr04Chip: true,
    cdf04: null,
    cdf04Chip: true,
    relatorioRecebimento4: null,
    relatorioRecebimento4Chip: true
  }
  transportadoraService = new TransportadoraService();
  transportadoras: any[] = [];
  isTransportadoraLoading: boolean = false;
  onSearchTransportadora: any = null;

  contratoReceptorService = new ContratoReceptorService();
  contratoReceptores: any[] = [];
  isContratoReceptorLoading: boolean = false;
  onSearchContratoReceptor: any = null;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formOrdemDestinacao: HTMLFormElement,
    formServicoTransportadora: HTMLFormElement
  }

  qtdOs: number = 0;
  qtdReceptor: number = 0;
  tecnologias: Tecnologia[] = [];

  veiculos: any[] = [];
  motoristas: any[] = [];

  configuracao: EmpresaConfiguracao = new EmpresaConfiguracao();

  itemExcluido(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else if(item.change)
      return 'changed'
    else
      return ''
  }

  @Watch('item')
  Item(value: OrdemDestinacao) {

    if (this.$refs.formOrdemDestinacao) {
      this.$refs.formOrdemDestinacao.resetValidation();
    }

    this.item.residuos.forEach(residuo => {
      residuo.quantidadeOriginal = residuo.quantidade;
      residuo.quantidadeOriginalReceptor = residuo.quantidadeReceptor;
    });

    if(this.item.id > 0){
      this.transportadoras.push(this.item.transportadora);
    }

    this.AtualizarQuantidade();
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }

  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('onSearchReceptor')
  SearchReceptor (val: string) {

    if(this.item.receptorId) return;
    if (this.isReceptorLoading) return;
    if (!val) return;

    this.isReceptorLoading = true
    this.receptor.AutoComplete(val).then(
      res => {
        this.receptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isReceptorLoading = false));
  }

  @Watch('onSearchTransportadora')
  SearchTransportadora (val: string) {

    if (this.item.transportadoraId) return;
    if (this.isTransportadoraLoading) return;
    if (!val) return;

    this.isTransportadoraLoading = true
    this.transportadoraService.AutoComplete(val).then(
      res => {
        this.transportadoras = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isTransportadoraLoading = false));
  }

  @Watch('onSearchContratoReceptor')
  SearchContratoReceptor (val: string) {

    if (this.item.contratoReceptorId) return;
    if (this.isContratoReceptorLoading) return;
    if (!val) return;

    this.isContratoReceptorLoading = true
    this.contratoReceptorService.AutoComplete(val).then(
      res => {
        this.contratoReceptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => (this.isContratoReceptorLoading = false));
  }

  @Watch('item.contratoReceptorId')
  SetReceptor(val: string){

    // if(this.item.id != 0) return;
    if(!this.item.contratoReceptorId) return;

    this.contratoReceptorService.ObterPorId(this.item.contratoReceptorId, "Receptor").then(
      res => {
        this.item.contratoReceptor = res.data;
        this.item.receptor = res.data.receptor;
        this.receptores.push(this.item.receptor);
        this.item.receptorId = res.data.receptorId;
        this.SearchReceptor(res.data.receptor.nome);
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    
  }

  @Watch('item.receptorId')
  WatchReceptor(){
    
    if(this.item.receptorId != 0)
      return;

    this.item.receptor = this.receptores.find(x => x.id == this.item.receptorId);
  }

  @Watch('item.id')
  @Watch('item.transportadoraId')
  GetVeiculoMotoristaByTransportadora(){
    if(this.item.transportadoraId > 0){
      this.WatchTransportadora();      

      const veiculoService = new VeiculoService();
      veiculoService.VeiculoByTransportadora(this.item.transportadoraId)
      .then(
        res => {
          this.veiculos = [];
          this.veiculos = res.data;
          if(this.veiculos.length > 0){
            this.veiculos.unshift({
              id: undefined,
              placa: ''
            });
          }          
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );      
      
      const motoristaService = new MotoristaService();
      motoristaService.MotoristaByTransportadora(this.item.transportadoraId)
      .then(
        res => {
          this.motoristas = [];
          this.motoristas = res.data;
          if(this.motoristas.length > 0){
            this.motoristas.unshift({
              id: undefined,
              cpfNome: ''
            });
          }         
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );
      
    }
  }

  WatchTransportadora(){
    if(this.item.transportadoraId > 0){
      this.transportadoraService.ObterPorId(this.item.transportadoraId, "Servicos.Servico, Servicos.UnidadeMedida").then(
        res => {
          let transportadora = new Transportadora(res.data);
          this.servicosTransportadora = transportadora.servicos;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      )
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    new TecnologiaService().ListarTudo().then(
      res =>{
        var recebido = res.data.items;
        // recebido = recebido.filter(x => x.pcp == true);
        recebido.forEach(x => {
          this.tecnologias.push(x);
        });
      },
      err => {
        this.$swal('Aviso',err.message,'error');
      }
    )
    new EmpresaService().ObterPorId(this.app.empresaId, "Configuracao").then(
      res => {
        this.configuracao = res.data.configuracao;
      }
    )
  }

  AtualizarQuantidade(){

    this.qtdOs = this.item.residuos.reduce(function (a, b) {
      return a + b.quantidade;
    }, 0);

    var residuos: any[] = [];

    this.item.residuos.forEach(x => {
      var teste = {
        Id: x.id,
        Quantidade: x.quantidade,
        QuantidadeReceptor: x.quantidadeReceptor
      }
      residuos.push(teste);
    });

    this.ordemServico.QuantidadeResiduos(residuos).then(
      res => {
        this.qtdReceptor = res.data;
      },
      err => {
        this.$swal("Aviso", err.response.data, "error");
      }
    )

  }

  AtualizarResiduoValorReceptor(item: OrdemServicoResiduo){
    this.service.AtualizarResiduoValorReceptor(item.ordemDestinacaoId!, item.id).then(
      res => {
        item.change = true;
        this.dialog = false;
        this.dialog = true;
      },
      err => this.$swal("Aviso", err.response.data, "error")
    )
  }

  SelectedOrdemServicoResiduo(){
    
    this.ordemServicoResiduos.forEach(ordemServicoResiduo => {
      ordemServicoResiduo.ordemDestinacaoId = this.item.id > 0 ? this.item.id : 0;
      this.item.residuos.push(ordemServicoResiduo);
    });;
    
  }

  ConcluirResiduos(){
    this.item.residuos.forEach(residuo => {
      if(residuo.situacaoId != 2){
        residuo.situacaoId = 3;
        residuo['change'] = true;
      }      
    });
    this.dialog = false;
    this.dialog = true;
  }

  Salvar() {

    if (this.$refs.formOrdemDestinacao.validate()) {
      
      this.save = true;
      
      this.SalvarArquivoProcessos().finally(() => {

        let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {

            if (this.item.id > 0){
              // if(this.item.residuos.length > 0)
              // {
                // this.item.residuos.forEach(ordemServicoResiduo => {
                //   this.service.VincularOrdemServicoResiduo(ordemServicoResiduo).then(
                //     res => {
                //       this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                //       this.$emit("salvou");
                //       this.Close();
                //     }
                //   );
                // });
              // }
              // else{
                this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                this.$emit("salvou");
                this.Close();
              // }
            }          
            else {
              this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
              this.$emit("salvou");
              this.Close();
            }
          },
          err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data.title, "warning");
            } 
            else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        ).finally(() => {
          this.save = false;
        });
      })
    }
  }

  async SalvarArquivoProcessos(){
    if (this.arquivo.mr02) {
      let dados = await this.arquivoService.Ler(this.arquivo.mr02);
      this.item.arquivo.mr02 = new Arquivo();
      this.item.arquivo.mr02.nome = this.arquivo.mr02.name;
      this.item.arquivo.mr02.tamanho = this.arquivo.mr02.size / 1024;
      this.item.arquivo.mr02.tipo = this.arquivo.mr02.type;
      this.item.arquivo.mr02.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.mr02 && !this.arquivo.mr02Chip){
      this.item.arquivo.mr02 = undefined;
    }

    if (this.arquivo.cdf02) {
      let dados = await this.arquivoService.Ler(this.arquivo.cdf02);
      this.item.arquivo.cdf02 = new Arquivo();
      this.item.arquivo.cdf02.nome = this.arquivo.cdf02.name;
      this.item.arquivo.cdf02.tamanho = this.arquivo.cdf02.size / 1024;
      this.item.arquivo.cdf02.tipo = this.arquivo.cdf02.type;
      this.item.arquivo.cdf02.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.cdf02 && !this.arquivo.cdf02Chip){
      this.item.arquivo.cdf02 = undefined;
    }

    if(this.arquivo.relatorioRecebimento2){
      let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento2);
      this.item.arquivo.relatorioRecebimento2 = new Arquivo();
      this.item.arquivo.relatorioRecebimento2.nome = this.arquivo.relatorioRecebimento2.name;
      this.item.arquivo.relatorioRecebimento2.tamanho = this.arquivo.relatorioRecebimento2.size / 1024;
      this.item.arquivo.relatorioRecebimento2.tipo = this.arquivo.relatorioRecebimento2.type;
      this.item.arquivo.relatorioRecebimento2.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.relatorioRecebimento2 && !this.arquivo.relatorioRecebimento2Chip){
      this.item.arquivo.relatorioRecebimento2 = undefined;
    }

    if (this.arquivo.mr03) {
      let dados = await this.arquivoService.Ler(this.arquivo.mr03);
      this.item.arquivo.mr03 = new Arquivo();
      this.item.arquivo.mr03.nome = this.arquivo.mr03.name;
      this.item.arquivo.mr03.tamanho = this.arquivo.mr03.size / 1024;
      this.item.arquivo.mr03.tipo = this.arquivo.mr03.type;
      this.item.arquivo.mr03.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.mr03 && !this.arquivo.mr03Chip){
      this.item.arquivo.mr03 = undefined;
    }

    if (this.arquivo.cdf03) {
      let dados = await this.arquivoService.Ler(this.arquivo.cdf03);
      this.item.arquivo.cdf03 = new Arquivo();
      this.item.arquivo.cdf03.nome = this.arquivo.cdf03.name;
      this.item.arquivo.cdf03.tamanho = this.arquivo.cdf03.size / 1024;
      this.item.arquivo.cdf03.tipo = this.arquivo.cdf03.type;
      this.item.arquivo.cdf03.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.cdf03 && !this.arquivo.cdf03Chip){
      this.item.arquivo.cdf03 = undefined;
    }

    if(this.arquivo.relatorioRecebimento3){
      let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento3);
      this.item.arquivo.relatorioRecebimento3 = new Arquivo();
      this.item.arquivo.relatorioRecebimento3.nome = this.arquivo.relatorioRecebimento3.name;
      this.item.arquivo.relatorioRecebimento3.tamanho = this.arquivo.relatorioRecebimento3.size / 1024;
      this.item.arquivo.relatorioRecebimento3.tipo = this.arquivo.relatorioRecebimento3.type;
      this.item.arquivo.relatorioRecebimento3.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.relatorioRecebimento3 && !this.arquivo.relatorioRecebimento3Chip){
      this.item.arquivo.relatorioRecebimento3 = undefined;
    }

    if (this.arquivo.mr04) {
      let dados = await this.arquivoService.Ler(this.arquivo.mr04);
      this.item.arquivo.mr04 = new Arquivo();
      this.item.arquivo.mr04.nome = this.arquivo.mr04.name;
      this.item.arquivo.mr04.tamanho = this.arquivo.mr04.size / 1024;
      this.item.arquivo.mr04.tipo = this.arquivo.mr04.type;
      this.item.arquivo.mr04.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.mr04 && !this.arquivo.mr04Chip){
      this.item.arquivo.mr04 = undefined;
    }

    if (this.arquivo.cdf04) {
      let dados = await this.arquivoService.Ler(this.arquivo.cdf04);
      this.item.arquivo.cdf04 = new Arquivo();
      this.item.arquivo.cdf04.nome = this.arquivo.cdf04.name;
      this.item.arquivo.cdf04.tamanho = this.arquivo.cdf04.size / 1024;
      this.item.arquivo.cdf04.tipo = this.arquivo.cdf04.type;
      this.item.arquivo.cdf04.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.cdf04 && !this.arquivo.cdf04Chip){
      this.item.arquivo.cdf04 = undefined;
    }

    if(this.arquivo.relatorioRecebimento4){
      let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento4);

      this.item.arquivo.relatorioRecebimento4 = new Arquivo();
      this.item.arquivo.relatorioRecebimento4.nome = this.arquivo.relatorioRecebimento4.name;
      this.item.arquivo.relatorioRecebimento4.tamanho = this.arquivo.relatorioRecebimento4.size / 1024;
      this.item.arquivo.relatorioRecebimento4.tipo = this.arquivo.relatorioRecebimento4.type;
      this.item.arquivo.relatorioRecebimento4.dados = dados.replace(/^[^,]*,/, "");
    }
    else if(!this.arquivo.relatorioRecebimento4 && !this.arquivo.relatorioRecebimento4Chip){
      this.item.arquivo.relatorioRecebimento4 = undefined;
    }

    this.arquivo = {
      mr02: null,
      mr02Chip: true,
      cdf02: null,
      cdf02Chip: true,
      relatorioRecebimento2: null,
      relatorioRecebimento2Chip: true,
      mr03: null,
      mr03Chip: true,
      cdf03: null,
      cdf03Chip: true,
      relatorioRecebimento3: null,
      relatorioRecebimento3Chip: true,
      mr04: null,
      mr04Chip: true,
      cdf04: null,
      cdf04Chip: true,
      relatorioRecebimento4: null,
      relatorioRecebimento4Chip: true
    }
  }

  ExcluirResiduo(ordemServicoResiduo: OrdemServicoResiduo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemServicoResiduo.ordemDestinacaoId = undefined;
        ordemServicoResiduo.excluido = true;
        
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  AdicionarServicoTransportadora(){
    if(this.$refs.formServicoTransportadora.validate()){
      if(!this.item.servicosTransportadora.find(x => x.servicoId == this.transportadoraServico.servicoId && x.unidadeMedidaId == this.transportadoraServico.unidadeMedidaId)){
        this.ordemDestinacaoServicoTransportadora.ordemDestinacaoId = this.item.id;
        this.ordemDestinacaoServicoTransportadora.servicoId = this.transportadoraServico.servicoId;
        this.ordemDestinacaoServicoTransportadora.servico = this.transportadoraServico.servico;
        this.ordemDestinacaoServicoTransportadora.unidadeMedidaId = this.transportadoraServico.unidadeMedidaId;
        this.ordemDestinacaoServicoTransportadora.unidadeMedida = this.transportadoraServico.unidadeMedida;
        this.ordemDestinacaoServicoTransportadora.valor = this.transportadoraServico.valor;
        this.item.servicosTransportadora.push(this.ordemDestinacaoServicoTransportadora);
        this.ordemDestinacaoServicoTransportadora = new OrdemDestinacaoServicoTransportadora();
        this.transportadoraServico = new TransportadoraServico();
      }else {
        this.$swal("Aviso", "Item já adicionado!", "warning");
      }
    }
  }

  ExcluirServicoTransportadora(ordemDestinacaoServicoTransportadora: OrdemDestinacaoServicoTransportadora){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {          
        const index = this.item.servicosTransportadora.indexOf(ordemDestinacaoServicoTransportadora);
        this.item.servicosTransportadora.splice(index, 1);
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })     
  }

  Close() {
    this.dialog = false;
  }

}
