import { Contrato, GrupoEquipamento } from './';

export class ContratoEquipamento implements Shared.IEntity{
    
    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    grupoEquipamentoId: number = 0;
    grupoEquipamento!: GrupoEquipamento;
    valorUnitario: number = 0;
    valorFixo: number = 0;
    limiar: number = 0;

    constructor(model?: ContratoEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.grupoEquipamentoId = model.grupoEquipamentoId;
        this.valorUnitario = model.valorUnitario;
        this.valorFixo = model.valorFixo;
        this.limiar = model.limiar;
    }

}