import { Medicao } from './Medicao';
import { OrdemServicoLocacao } from './OrdemServicoLocacao';

export class MedicaoOrdemServicoLocacao{
    
    medicaoId: number = 0;
    medicao!: Medicao;
    ordemServicoLocacaoId: number = 0;
    ordemServicoLocacao!: OrdemServicoLocacao;
    excluido: boolean = false;

    constructor(model?: MedicaoOrdemServicoLocacao){
        
        if(!model)
            return;

        this.medicaoId = model.medicaoId;
        this.medicao = model.medicao;
        this.ordemServicoLocacaoId = model.ordemServicoLocacaoId;
        this.ordemServicoLocacao = model.ordemServicoLocacao;
    }

}