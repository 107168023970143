



















































import { OrdemServicoFoto } from "@/core/models/residuo";
import { ArquivoService } from "@/core/services/shared";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";


@Component
export default class CadastroOrdemServicoFoto extends Vue {
    @Prop() private item!: OrdemServicoFoto;
    @Prop() private value!: string;

    arquivoService = new ArquivoService();

    validDialogFoto = true;
    dialog = false;
    
    foto: any = null; 

    $refs!: {
        formDialogFoto: HTMLFormElement
    }
    
    @Watch('item')
    Item(){

        if(this.$refs.formDialogFoto){
            this.$refs.formDialogFoto.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Salvar(){
        if(this.$refs.formDialogFoto.validate()){

            this.$emit("salvou");
            this.dialog = false;
        }
    }

    async LoadImage(){

        if (!this.foto)
        return;

        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.dados = dados.replace(/^[^,]*,/, "");
        this.item.nome = this.foto.name;
        this.item.tamanho = this.foto.size / 1024;
        this.item.tipo = this.foto.type;
        
    }

    RemoveImage(){
        this.item.dados = '';
        this.item.nome = '';
        this.item.tamanho = 0;
        this.item.tipo = '';
        this.foto = null;
    }

    Close() {
        this.dialog = false;
    }
}
