import { Porto } from "./Porto";
import { Receptor } from "./Receptor";

export class PortoReceptor implements Shared.IEntity {
    id: number = 0;
    portoId: number = 0;
    porto: Porto = new Porto();
    receptorId: number = 0;
    receptor: Receptor = new Receptor();
    pcpCodigo: string = "";
    estados: string = '';
    distancia: number = 0;

    constructor(model?: PortoReceptor) {
        if (!model)
            return;

        this.id = model.id;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.receptorId = model.receptorId;
        this.receptor = model.receptor;
        this.pcpCodigo = model.pcpCodigo;
        this.estados = model.estados;
        this.distancia = model.distancia;
    }
}