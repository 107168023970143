











































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { Arquivo, PageBase } from '@/core/models/shared';
import { ContratoResiduo, OrdemServicoResiduo } from '@/core/models/residuo';
import { EnumSituacaoOrdemServicoResiduo } from '@/core/models/residuo/Enumerados';
import { SituacaoOrdemServicoResiduoService, TecnologiaService } from '@/core/services/residuo';
import { ArquivoService } from '@/core/services/shared';
import { EmpresaConfiguracao } from "@/core/models/geral";
import { EmpresaService } from "@/core/services/geral";

@Component
export default class CadastroOrdemServicoResiduo extends PageBase{
    @Prop() private item!: OrdemServicoResiduo;
    @Prop() private value!: string;
    @Prop() private residuos!: ContratoResiduo[];
    @Prop() private ordemServicoSituacaoId!: number;

    arquivoService = new ArquivoService();

    tecnologias: any[] = [];
    tecnologiaService= new TecnologiaService();
    validDialogResiduo = true;

    dialog = false;
    situacaoResiduos: any[] = [];

    tabActive: any = {};

    arquivo: any  = {
        mr01: null,
        mr01Chip: true,
        cdf01: null,
        cdf01Chip: true,
        cartaResponsabilidade: null,
        cartaResponsabilidadeChip: true,
        ticketPesagem: null,
        ticketPesagemChip: true,
        fcdr: null,
        fcdrChip: true,
        relatorioObservacao: null,
        relatorioObservacaoChip: true,
        mr02: null,
        mr02Chip: true,
        cdf02: null,
        cdf02Chip: true,
        relatorioRecebimento2: null,
        relatorioRecebimento2Chip: true,
        mr03: null,
        mr03Chip: true,
        cdf03: null,
        cdf03Chip: true,
        relatorioRecebimento3: null,
        relatorioRecebimento3Chip: true,
        mr04: null,
        mr04Chip: true,
        cdf04: null,
        cdf04Chip: true,
        relatorioRecebimento4: null,
        relatorioRecebimento4Chip: true
    }

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        formDialogResiduo: HTMLFormElement
    }

    empresaConfiguracao: EmpresaConfiguracao = new EmpresaConfiguracao();

    mounted() {

        const situacaoOrdemServicoResiduoService = new SituacaoOrdemServicoResiduoService();

        situacaoOrdemServicoResiduoService.ListarTudo()
            .then(
                res=>{
                    this.situacaoResiduos = res.data.items;
                },
                err=>{
                    this.$swal('Aviso', err.message, 'error')
                }
            );

        const empresaService = new EmpresaService();
        empresaService.GetConfiguracao(this.app.empresaId).then(
            res =>{
                this.empresaConfiguracao = new EmpresaConfiguracao(res.data);
            },
            err => this.$swal('Aviso', err.message, 'error')
        )

        this.tecnologiaService.ListarTudo().then(
            res =>{
                var recebido = res.data.items;
                // recebido = recebido.filter(x => x.pcp == true);
                recebido.forEach(x => {
                    this.tecnologias.push(x);
                });
            },
            err => {
                this.$swal('Aviso',err.message,'error');
            }
        )
        
    }

    itemExcluido(item: any){
        return item.excluido ? 'itemExcluido' : '';
    }

    itemResiduoClass(item: any){

        if(item.situacaoId == EnumSituacaoOrdemServicoResiduo.Cancelado)
        return 'red lighten-4';

        return this.itemExcluido(item);
    
    }

    VerificaProcessosDocumentos(processo: number){
        var value = false;

        if(this.item.id > 0){
            if(this.item['processo'+ processo]['mr0'+ processo] == null ? false : this.item['processo'+ processo]['mr0'+ processo].length > 0 || (this.item.arquivo['mr0'+ processo] == null ? false : this.item.arquivo['mr0'+ processo].nome.length > 0)){
                value = true;
            }
            else if(this.item['processo' + processo]['cdf0' + processo] == null ? false : this.item['processo' + processo]['cdf0' + processo].length > 0 || (this.item.arquivo['cdf0' + processo] == null ? false : this.item.arquivo['cdf0' + processo].nome.length > 0)){
                value = true;
            }
            else if(this.item['processo' + processo].relatorioRecebimento == null ? false : this.item['processo' + processo].relatorioRecebimento.length > 0 || (this.item.arquivo['relatorioRecebimento' + processo] == null ? false : this.item.arquivo['relatorioRecebimento' + processo].nome.length > 0)){
                value = true;
            }
            else{
                value = false;
            }
        }

        return value;
    }

    SelectedResiduo(){

        if (this.item.id == 0){
            const contratoResiduo = this.residuos.find(x=> x.residuoId == this.item.residuoId);
            this.item.residuo = contratoResiduo!.residuo;
            this.item.valor = contratoResiduo!.preco;
            this.item.unidadeId = contratoResiduo!.unidadeMedidaId;
            this.item.unidade = contratoResiduo!.unidadeMedida;
        }
    }
    
    async Salvar(){
        await this.SalvarResiduoArquivo().finally(() => {
            if (this.$refs.formDialogResiduo.validate()) {
                this.$emit("salvou");
                this.dialog = false;
            }
        })
        // this.SalvarResiduoArquivo();
        // if (this.$refs.formDialogResiduo.validate()) {
        //     this.$emit("salvou");
        //     this.dialog = false;
        // }
    }

    async SalvarResiduoArquivo(){
        
        if (this.arquivo.fcdr) {
            let dados = await this.arquivoService.Ler(this.arquivo.fcdr);

            this.item.arquivo.fcdr = new Arquivo();
            this.item.arquivo.fcdr.nome = this.arquivo.fcdr.name;
            this.item.arquivo.fcdr.tamanho = this.arquivo.fcdr.size / 1024;
            this.item.arquivo.fcdr.tipo = this.arquivo.fcdr.type;
            this.item.arquivo.fcdr.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.fcdr && !this.arquivo.fcdrChip){
            this.item.arquivo.fcdr = undefined;
        }

        if (this.arquivo.mr01) {
            let dados = await this.arquivoService.Ler(this.arquivo.mr01);

            this.item.arquivo.mr01 = new Arquivo();
            this.item.arquivo.mr01.nome = this.arquivo.mr01.name;
            this.item.arquivo.mr01.tamanho = this.arquivo.mr01.size / 1024;
            this.item.arquivo.mr01.tipo = this.arquivo.mr01.type;
            this.item.arquivo.mr01.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.mr01 && !this.arquivo.mr01Chip){
            this.item.arquivo.mr01 = undefined;
        }

        if (this.arquivo.cdf01) {
            let dados = await this.arquivoService.Ler(this.arquivo.cdf01);
            
            this.item.arquivo.cdf01 = new Arquivo();
            this.item.arquivo.cdf01.nome = this.arquivo.cdf01.name;
            this.item.arquivo.cdf01.tamanho = this.arquivo.cdf01.size / 1024;
            this.item.arquivo.cdf01.tipo = this.arquivo.cdf01.type;
            this.item.arquivo.cdf01.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.cdf01 && !this.arquivo.cdf01Chip){
            this.item.arquivo.cdf01 = undefined;
        }

        if (this.arquivo.cartaResponsabilidade) {
            let dados = await this.arquivoService.Ler(this.arquivo.cartaResponsabilidade);
            
            this.item.arquivo.cartaResponsabilidade = new Arquivo();
            this.item.arquivo.cartaResponsabilidade.nome = this.arquivo.cartaResponsabilidade.name;
            this.item.arquivo.cartaResponsabilidade.tamanho = this.arquivo.cartaResponsabilidade.size / 1024;
            this.item.arquivo.cartaResponsabilidade.tipo = this.arquivo.cartaResponsabilidade.type;
            this.item.arquivo.cartaResponsabilidade.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.cartaResponsabilidade && !this.arquivo.cartaResponsabilidadeChip){
            this.item.arquivo.cartaResponsabilidade = undefined;
        }

        if (this.arquivo.ticketPesagem) {
            let dados = await this.arquivoService.Ler(this.arquivo.ticketPesagem);
            
            this.item.arquivo.ticketPesagem = new Arquivo();
            this.item.arquivo.ticketPesagem.nome = this.arquivo.ticketPesagem.name;
            this.item.arquivo.ticketPesagem.tamanho = this.arquivo.ticketPesagem.size / 1024;
            this.item.arquivo.ticketPesagem.tipo = this.arquivo.ticketPesagem.type;
            this.item.arquivo.ticketPesagem.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.ticketPesagem && !this.arquivo.ticketPesagemChip){
            this.item.arquivo.ticketPesagem = undefined;
        }

        if (this.arquivo.relatorioObservacao) {
            let dados = await this.arquivoService.Ler(this.arquivo.relatorioObservacao);
            
            this.item.arquivo.relatorioObservacao = new Arquivo();
            this.item.arquivo.relatorioObservacao.nome = this.arquivo.relatorioObservacao.name;
            this.item.arquivo.relatorioObservacao.tamanho = this.arquivo.relatorioObservacao.size / 1024;
            this.item.arquivo.relatorioObservacao.tipo = this.arquivo.relatorioObservacao.type;
            this.item.arquivo.relatorioObservacao.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.relatorioObservacao && !this.arquivo.relatorioObservacaoChip){
            this.item.arquivo.relatorioObservacao = undefined;
        }

        if (this.arquivo.mr02) {
            let dados = await this.arquivoService.Ler(this.arquivo.mr02);

            this.item.arquivo.mr02 = new Arquivo();
            this.item.arquivo.mr02.nome = this.arquivo.mr02.name;
            this.item.arquivo.mr02.tamanho = this.arquivo.mr02.size / 1024;
            this.item.arquivo.mr02.tipo = this.arquivo.mr02.type;
            this.item.arquivo.mr02.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.mr02 && !this.arquivo.mr02Chip){
            this.item.arquivo.mr02 = undefined;
        }

        if (this.arquivo.cdf02) {
            let dados = await this.arquivoService.Ler(this.arquivo.cdf02);

            this.item.arquivo.cdf02 = new Arquivo();
            this.item.arquivo.cdf02.nome = this.arquivo.cdf02.name;
            this.item.arquivo.cdf02.tamanho = this.arquivo.cdf02.size / 1024;
            this.item.arquivo.cdf02.tipo = this.arquivo.cdf02.type;
            this.item.arquivo.cdf02.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.cdf02 && !this.arquivo.cdf02Chip){
            this.item.arquivo.cdf02 = undefined;
        }

        if(this.arquivo.relatorioRecebimento2){
            let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento2);

            this.item.arquivo.relatorioRecebimento2 = new Arquivo();
            this.item.arquivo.relatorioRecebimento2.nome = this.arquivo.relatorioRecebimento2.name;
            this.item.arquivo.relatorioRecebimento2.tamanho = this.arquivo.relatorioRecebimento2.size / 1024;
            this.item.arquivo.relatorioRecebimento2.tipo = this.arquivo.relatorioRecebimento2.type;
            this.item.arquivo.relatorioRecebimento2.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.relatorioRecebimento2 && !this.arquivo.relatorioRecebimento2Chip){
            this.item.arquivo.relatorioRecebimento2 = undefined;
        }

        if (this.arquivo.mr03) {
            let dados = await this.arquivoService.Ler(this.arquivo.mr03);

            this.item.arquivo.mr03 = new Arquivo();
            this.item.arquivo.mr03.nome = this.arquivo.mr03.name;
            this.item.arquivo.mr03.tamanho = this.arquivo.mr03.size / 1024;
            this.item.arquivo.mr03.tipo = this.arquivo.mr03.type;
            this.item.arquivo.mr03.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.mr03 && !this.arquivo.mr03Chip){
            this.item.arquivo.mr03 = undefined;
        }

        if (this.arquivo.cdf03) {
            let dados = await this.arquivoService.Ler(this.arquivo.cdf03);

            this.item.arquivo.cdf03 = new Arquivo();
            this.item.arquivo.cdf03.nome = this.arquivo.cdf03.name;
            this.item.arquivo.cdf03.tamanho = this.arquivo.cdf03.size / 1024;
            this.item.arquivo.cdf03.tipo = this.arquivo.cdf03.type;
            this.item.arquivo.cdf03.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.cdf03 && !this.arquivo.cdf03Chip){
            this.item.arquivo.cdf03 = undefined;
        }

        if(this.arquivo.relatorioRecebimento3){
            let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento3);

            this.item.arquivo.relatorioRecebimento3 = new Arquivo();
            this.item.arquivo.relatorioRecebimento3.nome = this.arquivo.relatorioRecebimento3.name;
            this.item.arquivo.relatorioRecebimento3.tamanho = this.arquivo.relatorioRecebimento3.size / 1024;
            this.item.arquivo.relatorioRecebimento3.tipo = this.arquivo.relatorioRecebimento3.type;
            this.item.arquivo.relatorioRecebimento3.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.relatorioRecebimento3 && !this.arquivo.relatorioRecebimento3Chip){
            this.item.arquivo.relatorioRecebimento3 = undefined;
        }

        if (this.arquivo.mr04) {
            let dados = await this.arquivoService.Ler(this.arquivo.mr04);

            this.item.arquivo.mr04 = new Arquivo();
            this.item.arquivo.mr04.nome = this.arquivo.mr04.name;
            this.item.arquivo.mr04.tamanho = this.arquivo.mr04.size / 1024;
            this.item.arquivo.mr04.tipo = this.arquivo.mr04.type;
            this.item.arquivo.mr04.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.mr04 && !this.arquivo.mr04Chip){
            this.item.arquivo.mr04 = undefined;
        }

        if (this.arquivo.cdf04) {
            let dados = await this.arquivoService.Ler(this.arquivo.cdf04);

            this.item.arquivo.cdf04 = new Arquivo();
            this.item.arquivo.cdf04.nome = this.arquivo.cdf04.name;
            this.item.arquivo.cdf04.tamanho = this.arquivo.cdf04.size / 1024;
            this.item.arquivo.cdf04.tipo = this.arquivo.cdf04.type;
            this.item.arquivo.cdf04.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.cdf04 && !this.arquivo.cdf04Chip){
            this.item.arquivo.cdf04 = undefined;
        }

        if(this.arquivo.relatorioRecebimento4){
            let dados = await this.arquivoService.Ler(this.arquivo.relatorioRecebimento4);

            this.item.arquivo.relatorioRecebimento4 = new Arquivo();
            this.item.arquivo.relatorioRecebimento4.nome = this.arquivo.relatorioRecebimento4.name;
            this.item.arquivo.relatorioRecebimento4.tamanho = this.arquivo.relatorioRecebimento4.size / 1024;
            this.item.arquivo.relatorioRecebimento4.tipo = this.arquivo.relatorioRecebimento4.type;
            this.item.arquivo.relatorioRecebimento4.dados = dados.replace(/^[^,]*,/, "");
        }
        else if(!this.arquivo.relatorioRecebimento4 && !this.arquivo.relatorioRecebimento4Chip){
            this.item.arquivo.relatorioRecebimento4 = undefined;
        }

        this.arquivo = {
            mr01: null,
            mr01Chip: true,
            cdf01: null,
            cdf01Chip: true,
            cartaResponsabilidade: null,
            cartaResponsabilidadeChip: true,
            ticketPesagem: null,
            ticketPesagemChip: true,
            fcdr: null,
            fcdrChip: true,
            relatorioObservacao: null,
            relatorioObservacaoChip: true,
            mr02: null,
            mr02Chip: true,
            cdf02: null,
            cdf02Chip: true,
            relatorioRecebimento2: null,
            relatorioRecebimento2Chip: true,
            mr03: null,
            mr03Chip: true,
            cdf03: null,
            cdf03Chip: true,
            relatorioRecebimento3: null,
            relatorioRecebimento3Chip: true,
            mr04: null,
            mr04Chip: true,
            cdf04: null,
            cdf04Chip: true,
            relatorioRecebimento4: null,
            relatorioRecebimento4Chip: true
        }
    }

    @Watch('item')
    Item(){

        if(this.$refs.formDialogResiduo){
            this.$refs.formDialogResiduo.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Close() {
        this.dialog = false;
    }

}   

