







































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { GrupoServicoService, ServicoService } from "@/core/services/residuo";
import { Servico } from "@/core/models/residuo";

@Component
export default class CadastroServico extends Vue {
  @Prop() private item!: Servico;
  @Prop() private value!: string;

  itemOriginal!: Servico;

  service = new ServicoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  grupos: any[] = [];
  $refs!: {
    formServico: HTMLFormElement
  }

  @Watch('item')
  Item() {
    if (this.$refs.formServico) {
      this.$refs.formServico.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {
    const grupoService = new GrupoServicoService();
    grupoService.ListarTudo()
        .then(
            res => {
                this.grupos = res.data.items;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        );

  }

  Salvar() {
    if (this.$refs.formServico.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
