import { PessoaJuridica } from './../shared/Pessoa';
import { EmpresaConfiguracao } from './EmpresaConfiguracao';

export class Empresa extends PessoaJuridica {

    dominio: string = '';
    logo: any = null;
    assinatura: any = null;
    configuracao!: EmpresaConfiguracao
    pcpCodigo: string = '';

    constructor(model?: Empresa) {

        super(model);

        if (!model)
            return;

        this.dominio = model?.dominio;
        this.logo = model.logo;
        this.assinatura = model.assinatura;
        this.configuracao = model.configuracao;
        this.pcpCodigo = model.pcpCodigo;
    }
}