import { DocumentoReceptor } from '.';
import { GrupoDocumento } from '../geral';

export class DocumentoReceptorGrupo implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento!: DocumentoReceptor;
    grupoId: number = 0;
    grupo!: GrupoDocumento;

    constructor(model?: DocumentoReceptorGrupo){

        if(!model)
            return;
        
        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
    }
}