import { Service } from '../shared';
import {http, httpHeader} from '../../ApiConfig'

export class ContratoReceptorService extends Service{

  constructor(){
    super('contratoReceptor');
  }
  
  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
  
}