import { GrupoEquipamento } from '../residuo';
import { Locatario } from '.';

export class LocatarioGrupoEquipamento implements Shared.IEntity{
    
    id: number = 0;
    locatarioId: number = 0;
    locatario!: Locatario;
    grupoEquipamentoId: number = 0;
    grupoEquipamento!: GrupoEquipamento;
    valor: number = 0;

    constructor(model?: LocatarioGrupoEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.locatarioId = model.locatarioId;
        this.locatario = model.locatario;
        this.grupoEquipamentoId = model.grupoEquipamentoId;
        this.grupoEquipamento = model.grupoEquipamento;
        this.valor = model.valor;
    }
}