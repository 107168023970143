import { Documento, GrupoDocumento } from ".";

export class DocumentoGrupo implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento!: Documento;
    grupoId: number = 0;
    grupo!: GrupoDocumento;

    constructor(model?: DocumentoGrupo){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
    }
}