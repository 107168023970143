import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import Home from '../views/Home.vue';
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const verificaPerfil = (next: NavigationGuardNext<Vue>, isCliente: boolean) => {

  var sessionApp: any;
  if (localStorage.sessionApp) {
    sessionApp = JSON.parse(localStorage.sessionApp);
    if (sessionApp.dados.clienteId && !isCliente) {
      next({ name: 'home' });
    }
    else {
      next();
    }
  }
  else {
    next({ name: 'login' });
  }

}

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: Home },
  { path: "/login", name: "login", component: Login },
  { path: "/resetSenha/:token", name: "resetSenha", component: Login },
  //GERAL
  { path: "/geral/usuario", name: "listaUsuario", component: () => import("../views/geral/usuario/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/empresa", name: "listaEmpresa", component: () => import("../views/geral/empresa/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/unidademedida", name: "listaUnidadeMedida", component: () => import("../views/geral/unidademedida/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/veiculo", name: "listaVeiculo", component: () => import("../views/geral/veiculo/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/tipoveiculo", name: "listaTipoVeiculo", component: () => import("../views/geral/tipoveiculo/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/tipodocumento", name: "listaTipoDocumento", component: () => import("../views/geral/tipodocumento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/motorista", name: "listaMotorista", component: () => import("../views/geral/motorista/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/cliente", name: "listaCliente", component: () => import("../views/geral/cliente/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/locatario", name: "listaLocatario", component: () => import("../views/geral/locatario/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/documento", name: "documentoEmpresa", component: () => import("../views/geral/documentoempresa/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/geral/grupodocumento", name: "grupoDocumento", component: () => import("../views/geral/grupodocumento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/geral/documentos", name: "documentos", component: () => import("../views/geral/documento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/geral/regiao", name: "regiao", component: () => import("../views/geral/regiao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  //RESIDUO
  { path: "/residuo/documentoreceptor", name: "documentoReceptor", component: () => import("../views/residuo/documentoreceptor/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/gruposervico", name: "listaGrupoServico", component: () => import("../views/residuo/grupoServico/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/gruporesiduo", name: "listaGrupoResiduo", component: () => import("../views/residuo/grupoResiduo/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/grupoequipamento", name: "listaGrupoEquipamento", component: () => import("../views/residuo/grupoEquipamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/porto", name: "listaPorto", component: () => import("../views/residuo/porto/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/equipamento", name: "listaEquipamento", component: () => import("../views/residuo/equipamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuos", name: "listaResiduo", component: () => import("../views/residuo/residuo/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/servico", name: "listaServico", component: () => import("../views/residuo/servico/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/controleResiduo", name: "listaControleResiduo", component: () => import("../views/residuo/controleresiduo/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico", name: "listaOrdemServico", component: () => import("../views/residuo/ordemservico/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/detalhar/:id", name: "detalharOrdemServico", component: () => import("../views/residuo/ordemservico/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/medicao/:id", name: "medicaoOrdemServico", component: () => import("../views/residuo/ordemservico/Medicao.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/relatorio", name: "relatorioOrdemServico", component: () => import("../views/residuo/ordemservico/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/relatorioCliente", name: "relatorioOrdemServicoCliente", component: () => import("../views/residuo/ordemservico/RelatorioPorCliente.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/relatorioResiduo", name: "relatorioOrdemServicoResiduo", component: () => import("../views/residuo/ordemservico/RelatorioPorResiduo.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/ordemservico/relatorioResiduoComItens", name: "relatorioOrdemServicoResiduoComItens", component: () => import("../views/residuo/ordemservico/RelatorioComItens.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/cartarastreabilidade", name: "listaCartaRastreabilidade", component: () => import("../views/residuo/cartarastreabilidade/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/cartarastreabilidade/:id", name: "detalharCartaRastreabilidade", component: () => import("../views/residuo/cartarastreabilidade/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/cartarastreabilidade2/:id", name: "detalharCartaRastreabilidade2", component: () => import("../views/residuo/cartarastreabilidade/Detalhar2.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/ordemdestinacao", name: "listaOrdemDestinacao", component: () => import("../views/residuo/ordemdestinacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/ordemdestinacao/relatorio", name: "relatorioOrdemDestinacao", component: () => import("../views/residuo/ordemdestinacao/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/ordemservico/ArquivosProblema", name: "relatorioArquivosProblema", component: () => import("../views/residuo/ordemservico/RelatorioArquivosProblema.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/ordemservico/RelatorioOsSemOd", name: "relatorioOsSemOd", component: () => import("../views/residuo/ordemservico/RelatorioOsSemOd.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/ordemdestinacao/detalhar/:id", name: "detalharOrdemDestinacao", component: () => import("../views/residuo/ordemdestinacao/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/tecnologia", name: "listaTecnologia", component: () => import("../views/residuo/tecnologia/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/tipoembarcacao", name: "listaTipoEmbarcacao", component: () => import("../views/residuo/tipoembarcacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/contrato", name: "listaContrato", component: () => import("../views/residuo/contrato/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/contratoreceptor", name: "listaContratoReceptor", component: () => import("../views/residuo/contratoReceptor/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/receptor", name: "listaReceptor", component: () => import("../views/residuo/receptor/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/transportadora", name: "listaTransportadora", component: () => import("../views/residuo/transportadora/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/acondicionamento", name: "listaAcondicionamento", component: () => import("../views/residuo/acondicionamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/armazenamento", name: "listaArmazenamento", component: () => import("../views/residuo/armazenamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  //Medicao CLIENTE
  { path: "/residuo/medicao", name: "listaMedicao", component: () => import("../views/residuo/medicao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/medicao/relatorio", name: "relatorioMedicao", component: () => import("../views/residuo/medicao/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/medicao/detalhar/:id", name: "detalharMedicao", component: () => import("../views/residuo/medicao/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/medicao/detalhar2/:id", name: "detalharMedicao2", component: () => import("../views/residuo/medicao/Detalhar2.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  { path: "/residuo/medicao/detalhar3/:id", name: "detalharMedicao3", component: () => import("../views/residuo/medicao/Detalhar3.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  //Medicao RECEPTOR
  { path: "/residuo/medicaoreceptor", name: "listaMedicaoReceptor", component: () => import("../views/residuo/medicaoreceptor/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/medicaoreceptor/relatorio", name: "relatorioMedicaoReceptor", component: () => import("../views/residuo/medicaoreceptor/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/residuo/medicaoreceptor/detalhar/:id", name: "detalharMedicaoReceptor", component: () => import("../views/residuo/medicaoreceptor/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, true) },
  //INTERNA
  { path: "/interna/ordemservico", name: "listaOrdemServicoInterna", component: () => import("../views/interna/ordemservico/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/interna/ordemservico/detalhar/:id", name: "detalharOrdemServicoInterna", component: () => import("../views/interna/ordemservico/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/interna/ordemservico/relatorio", name: "relatorioOrdemServicoInterna", component: () => import("../views/interna/ordemservico/Relatorio.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/interna/ordemservico/relatorioCliente", name: "relatorioOrdemServicoClienteInterna", component: () => import("../views/interna/ordemservico/RelatorioPorCliente.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/interna/ordemservico/relatorioResiduoComItens", name: "relatorioOrdemServicoResiduoComItensInterna", component: () => import("../views/interna/ordemservico/RelatorioComItens.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  //Monitoramento
  { path: "/monitoramento/pergunta", name: "pergunta", component: () => import("../views/monitoramento/pergunta/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/monitoramento/operacao", name: "operacao", component: () => import("../views/monitoramento/operacao/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/monitoramento/aviso", name: "aviso", component: () => import("../views/monitoramento/aviso/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/monitoramento/agendamento", name: "agendamento", component: () => import("../views/monitoramento/agendamento/Lista.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  //Relatorio
  { path: "/relatorio/locacao/configuracao", name: "configRelatorioLocacao", component: () => import("../views/residuo/relatorioLocacao/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/locacao/detalhar", name: "detalharRelatorioLocacao", component: () => import("../views/residuo/relatorioLocacao/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/configuracao", name: "configRelatorioProvisionamento", component: () => import("../views/residuo/relatorioProvisionamento/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/detalharGastoReceptor", name: "detalharGastoReceptor", component: () => import("../views/residuo/relatorioProvisionamento/DetalharGastoReceptor.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/detalharGastoReceptorTecnologia", name: "detalharGastoReceptorTecnologia", component: () => import("../views/residuo/relatorioProvisionamento/DetalharGastoReceptorTecnologia.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/detalharGastoTransporte", name: "detalharGastoTransporte", component: () => import("../views/residuo/relatorioProvisionamento/DetalharGastoTransporte.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/detalharGastoLocatario", name: "detalharGastoLocatario", component: () => import("../views/residuo/relatorioProvisionamento/DetalharGastoLocatario.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/provisionamento/detalharGastoLocatarioGrupo", name: "detalharGastoLocatarioGrupo", component: () => import("../views/residuo/relatorioProvisionamento/DetalharGastoLocatarioGrupo.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  //ResiduoDestinacao
  { path: "/relatorio/residuoDestinacacao/configuracao", name: "configuracaoRelatorioResiduoDestinacao", component: () => import("../views/residuo/residuoDestinacao/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
  { path: "/relatorio/residuoDestinacao/detalhar", name: "detalharRelatorioResiduoDestinacao", component: () => import("../views/residuo/residuoDestinacao/Detalhar.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, false) },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
