import { Empresa } from "../geral";

export class Pergunta implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    texto: string = ''

    constructor(model?: Pergunta){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.texto = model.texto;
    }
}