import { Servico, Transportadora } from ".";
import { UnidadeMedida } from "../geral";

export class TransportadoraServico {

    transportadoraId: number = 0;
    transportadora!: Transportadora;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    servicoId: number = 0;
    servico!: Servico;
    valor: number = 0;

    constructor(model?: TransportadoraServico) {

        if (!model)
            return;
        
        this.transportadoraId = model.transportadoraId;
        this.transportadora = model.transportadora;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.servicoId = model.servicoId;
        this.servico = model.servico;
        this.valor = model.valor;
    }
}