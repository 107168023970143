import { Arquivo } from './../shared/Arquivo';
import { OrdemServico } from '@/core/models/residuo';

export class OrdemServicoFoto extends Arquivo implements Shared.IEntity{
    
    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico?: OrdemServico;
    descricao: string = '';
    excluido: boolean = false;

    constructor(model?: OrdemServicoFoto){

        super();
        
        if(!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.descricao = model.descricao;
    }
}