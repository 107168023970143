import { Empresa } from '../geral';
import { Agendamento, OperacaoFoto, SituacaoOperacao, Trajeto, OperacaoPergunta } from './';

export class Operacao implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    situacaoOperacaoId: number = 0;
    situacaoOperacao!: SituacaoOperacao;
    agendamentoId: number = 0;
    agendamento!: Agendamento;
    trajetos: Trajeto[] = [];
    operacaoFotos: OperacaoFoto[] = [];
    operacaoPerguntas: OperacaoPergunta[] = [];
    inconformidade: boolean = false;
    descricaoInconformidade: string = '';

    constructor(model?: Operacao){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.situacaoOperacaoId = model.situacaoOperacaoId;
        this.agendamentoId = model.agendamentoId;
        this.trajetos = model.trajetos;
        this.operacaoFotos = model.operacaoFotos;
        this.operacaoPerguntas = model.operacaoPerguntas;
        this.inconformidade = model.inconformidade;
        this.descricaoInconformidade = model.descricaoInconformidade;
    }

}