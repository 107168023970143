






























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { TipoVeiculoModalService, TipoVeiculoService } from "@/core/services/geral";
import { TipoVeiculo, TipoVeiculoModal } from "@/core/models/geral";
import { TipoVeiculoPerguntas } from "@/core/models/monitoramento";
import { PerguntaService } from "@/core/services/monitoramento";

@Component
export default class CadastroTipoVeiculo extends Vue {
  @Prop() private item!: TipoVeiculo;
  @Prop() private value!: string;

  itemOriginal!: TipoVeiculo;

  service = new TipoVeiculoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formTipoVeiculo: HTMLFormElement
  }

  dialogSelecionaPergunta = false;
  perguntas: any[] = [];
  headerPerguntas: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "Pergunta", value: "pergunta.texto" },
  ]

  perguntaService = new PerguntaService();

  modais: TipoVeiculoModal[] = [];

  @Watch('item')
  Item() {
    if (this.$refs.formTipoVeiculo) {
      this.$refs.formTipoVeiculo.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {
    const tipoVeiculoModalService = new TipoVeiculoModalService();
    tipoVeiculoModalService.ListarTudo().then(
      res =>{
        this.modais = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    )
  }

  SelectedPergunta(){
    this.perguntas.forEach(item => {
      
      const pergunta = new TipoVeiculoPerguntas();
      pergunta.perguntaId = item.id;
      pergunta.tipoVeiculoId = this.item.id;

      this.perguntaService.ObterPorId(item.id).then(
        res => pergunta.pergunta = res.data
      ).finally(() => this.item.perguntas.push(pergunta))
    })
  }

  ExcluirPergunta(pergunta: TipoVeiculoPerguntas){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.perguntas.indexOf(pergunta);
        this.item.perguntas.splice(index,1);

        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
        }
      })
  }

  Salvar() {
    if (this.$refs.formTipoVeiculo.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
