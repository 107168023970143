

























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { AcondicionamentoService, ArmazenamentoService, GrupoResiduoService, ResiduoService, TecnologiaService } from "@/core/services/residuo";
import { UnidadeMedidaService } from '@/core/services/geral'
import { Acondicionamento, Armazenamento, Residuo } from "@/core/models/residuo";

@Component
export default class CadastroResiduo extends Vue {
  @Prop() private item!: Residuo;
  @Prop() private value!: string;

  itemOriginal!: Residuo;

  service = new ResiduoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  grupos: any[] = [];
  tecnologias: any[] = [];
  unidadeMedidas: any[] = [];
  armazenamentos: Armazenamento[] = [];
  acondicionamentos: Acondicionamento[] = [];

  $refs!: {
    formResiduo: HTMLFormElement
  }

  @Watch('item')
  Item() {
    if (this.$refs.formResiduo) {
      this.$refs.formResiduo.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item)
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {

    const grupoService = new GrupoResiduoService();
    grupoService.ListarTudo().then(
      res => {
        this.grupos = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );
    const tecnologiaService = new TecnologiaService();
    tecnologiaService.ListarTudo().then(
      res=> {
        this.tecnologias = res.data.items;
        //REMOVIDO - CAUSANDO CONFUSAO NOS CADASTROS DE RESIDUOS
        // this.tecnologias = this.tecnologias.filter( x => x.pcp == false);
        // this.tecnologias.forEach( x => {
        //   this.tecnologias.push(x);
        // });
      },
      err => {
        this.$swal('Aviso', err.message,'error');
      }
    )
    const unidadeMedidaService = new UnidadeMedidaService();
    unidadeMedidaService.ListarTudo().then(
      res=>{
        this.unidadeMedidas = res.data.items;
      }
    )
    const acondicionamentoSerivce = new AcondicionamentoService();
    acondicionamentoSerivce.ListarTudo().then(
      res => {
        this.acondicionamentos = res.data.items;
      }
    )
    const armazenamentoSerice = new ArmazenamentoService();
    armazenamentoSerice.ListarTudo().then(
      res => {
        this.armazenamentos = res.data.items;
      }
    )
  }

  Salvar() {
    if (this.$refs.formResiduo.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
