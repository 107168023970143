





























































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { ContratoService, GrupoEquipamentoService, ResiduoService, ServicoService } from "@/core/services/residuo";
import { Contrato, ContratoEquipamento, ContratoResiduo, ContratoServico, UsuarioContrato } from "@/core/models/residuo";
import { Cliente, Usuario } from '@/core/models/geral';
import { ClienteService, UnidadeMedidaService, UsuarioService } from '@/core/services/geral';

@Component
export default class CadastroContrato extends Vue {
  @Prop() private item!: Contrato;
  @Prop() private value!: string;

  itemOriginal!: Contrato;

  service = new ContratoService();
  clienteService = new ClienteService();
  residuoService = new ResiduoService();
  servicoService = new ServicoService();
  grupoEquipamentoService = new GrupoEquipamentoService();
  valid = true;
  validDialogResiduo = true;
  validDialogServico = true;
  validDialogEquipamento = true;
  dialog = false;
  dialogResiduo = false;
  dialogServico = false;
  dialogEquipamento = false;
  isResiduoLoading = false;
  isServicoLoading = false;
  isClienteLoading = false;
  isGrupoLoading = false;
  onSearchCliente: any = null;
  tabActive: any = {};
  contratoResiduo: ContratoResiduo = new ContratoResiduo();
  contratoServico: ContratoServico = new ContratoServico();
  contratoEquipamento: ContratoEquipamento = new ContratoEquipamento();
  clientes: Cliente[] = [];
  residuos: any[] = [];
  servicos: any[] = [];
  unidades: any[] = [];
  grupos: any[] = [];
  headerResiduo: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Resíduo', value: 'residuo.codigoNomeTecnologiaGrupo' },
    { text: 'Unidade', value: 'unidadeMedida.sigla' },
    { text: 'Preço', value: 'preco', type: 'number' }
  ];
  headerServico: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Serviço', value: 'servico.codigoNome' },
    { text: 'Preço', value: 'preco', type: 'number' },
    { text: 'Transporte', value: 'transporte', type: 'boolean' }
  ];
  headerEquipamento: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Grupo', value: 'grupoEquipamento.nome' },
    { text: 'Valor Unitário', value: 'valorUnitario', type: 'number' },
    { text: 'Valor Fixo', value: 'valorFixo', type: 'number' },
    { text: 'Limiar (dias)', value: 'limiar', type: 'number' }
  ];
  headerUsuario: any[] = [
    { text: '', value:'actions', sortable: false },
    { text: 'Nome', value: 'usuario.complementar.nome' },
    { text: 'Login', value: 'usuario.login' },
    { text: 'E-mail', value: 'usuario.complementar.email' },
  ];
  usuarioContrato = new UsuarioContrato();
  usuarios: Usuario[] = [];
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tipos: any[] = [];
  $refs!: {
    formContrato: HTMLFormElement,
    formDialogResiduo: HTMLFormElement,
    formDialogServico: HTMLFormElement,
    formDialogEquipamento: HTMLFormElement
  }

  itemExcluido(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else
      return ''
  }

  @Watch('item')
  Item() {
    if (this.$refs.formContrato) {
      this.$refs.formContrato.resetValidation();
    }
    if(this.$refs.formDialogResiduo){
      this.$refs.formDialogResiduo.resetValidation();
    }
    if(this.$refs.formDialogServico){
      this.$refs.formDialogServico.resetValidation();
    }
    if(this.$refs.formDialogEquipamento){
      this.$refs.formDialogEquipamento.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  @Watch('onSearchCliente')
  SearchCliente (val: string) {

      if (this.item.clienteId) return;
      if (this.isClienteLoading) return;
      if (!val) return;

      this.isClienteLoading = true
      this.clienteService.AutoComplete(val)
          .then(
              res => {
                this.clientes = res.data;
              },
              err => this.$swal('Aviso', err.response.data, 'error')
          )
          .finally(() => (this.isClienteLoading = false));
  }

  CarregaServico(){
    this.isServicoLoading = true
      this.servicoService.ListarTudoOrdenado('nome')
        .then(
            res => {
              this.servicos = res.data.items;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isServicoLoading = false));
    new UsuarioService().ListarTudoFiltro('perfilId eq 8').then(
      res => {
        this.usuarios = res.data.items
      }
    )
  }

  CarregaResiduo(){
    this.isResiduoLoading = true
      this.residuoService.ListarTudoOrdenado('nome', false, "Tecnologia, Grupo")
        .then(
            res => {
              this.residuos = res.data.items;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isResiduoLoading = false));
  }

  CarregaGrupo(){
    this.isGrupoLoading = true
      this.grupoEquipamentoService.ListarTudoOrdenado('nome')
        .then(
            res => {
              this.grupos = res.data.items;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isGrupoLoading = false));
  }

  mounted() {
    const unidadeService = new UnidadeMedidaService();
    unidadeService.ListarTudo()
      .then(
        res => {
            this.unidades = res.data.items;
        },
        err => {
            this.$swal('Aviso', err.message, 'error')
        }
      );

    this.CarregaResiduo();
    this.CarregaServico();
    this.CarregaGrupo();
  }

  Salvar() {
    if (this.$refs.formContrato.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
      
    }
  }

  AbrirDialogResiduo(contratoResiduo?: ContratoResiduo){
    this.contratoResiduo = contratoResiduo ? contratoResiduo : new ContratoResiduo();
    this.dialogResiduo = true;
  }

  ExcluirResiduo(contratoResiduo: ContratoResiduo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
          const index = this.item.residuos.indexOf(contratoResiduo);
          this.item.residuos.splice(index,1);

          return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  SalvarResiduo(){

    if (this.$refs.formDialogResiduo.validate()) {
      if (this.contratoResiduo.id == 0){
        const residuo = this.residuos.find(x => x.id == this.contratoResiduo.residuoId);
        const unidade = this.unidades.find(x => x.id == this.contratoResiduo.unidadeMedidaId);

        this.contratoResiduo.residuo = residuo;
        this.contratoResiduo.unidadeMedida = unidade;
        this.item.residuos.push(this.contratoResiduo);
      }
      this.dialogResiduo = false;
    }
  }

  AbrirDialogServico(contratoServico?: ContratoServico){
    this.contratoServico = contratoServico ? contratoServico : new ContratoServico();
    this.dialogServico = true;
  }

  ExcluirServico(contratoServico: ContratoServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
          const index = this.item.servicos.indexOf(contratoServico);
          this.item.servicos.splice(index,1);

          return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Serviço removido com sucesso!", "success");
          }
      })
  }

  SalvarServico(){

    if (this.$refs.formDialogServico.validate()) {
      if (this.contratoServico.id == 0){
        const servico = this.servicos.find(x => x.id == this.contratoServico.servicoId);

        this.contratoServico.servico = servico;
        this.item.servicos.push(this.contratoServico);
      }

      this.dialogServico = false;
    }
  }

  AbrirDialogEquipamento(contratoEquipamento?: ContratoEquipamento){
    this.contratoEquipamento = contratoEquipamento ? contratoEquipamento : new ContratoEquipamento();
    this.dialogEquipamento = true;
  }

  ExcluirEquipamento(contratoEquipamento: ContratoEquipamento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
          const index = this.item.equipamentos.indexOf(contratoEquipamento);
          this.item.equipamentos.splice(index,1);

          return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  SalvarEquipamento(){

    if (this.$refs.formDialogEquipamento.validate()) {
      if (this.contratoEquipamento.id == 0){

        const grupo = this.grupos.find(x => x.id == this.contratoEquipamento.grupoEquipamentoId);
        this.contratoEquipamento.grupoEquipamento = grupo;
        
        this.item.equipamentos.push(this.contratoEquipamento);
      }
      this.dialogEquipamento = false;
    }
  }

  AdicionarUsuario(){
    if(this.usuarioContrato.usuarioId > 0){
      this.usuarioContrato.contratoId = this.item.id;
      this.usuarioContrato.usuario = this.usuarios.find(x => x.id == this.usuarioContrato.usuarioId)!;
      this.item.usuarios.push(this.usuarioContrato);
      this.usuarioContrato = new UsuarioContrato();
    }
  }

  ExcluirUsuario(usuarioContrato: UsuarioContrato){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {          
        const index = this.item.usuarios.indexOf(usuarioContrato);
        this.item.usuarios.splice(index,1);
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if(result.value) {
          this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
        }
      })
  }

  Close() {
    this.dialog = false;
  }
}
