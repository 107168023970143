



























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { DocumentoReceptorService, ReceptorService } from "@/core/services/residuo";
import { ArquivoService } from '@/core/services/shared';
import { Arquivo, PageBase } from '@/core/models/shared';
import { DocumentoReceptor, DocumentoReceptorGrupo } from '@/core/models/residuo';
import { GrupoDocumento } from "@/core/models/geral";
import { GrupoDocumentoService } from "@/core/services/geral";

@Component
export default class CadastroDocumentoReceptor extends PageBase {
  @Prop() private item!: DocumentoReceptor;
  @Prop() private value!: string;

  itemOriginal!: DocumentoReceptor;

  service = new DocumentoReceptorService();  
  arquivoService = new ArquivoService();
  receptorService = new ReceptorService();
  salvando: boolean = false;
  onSearchReceptor: any = null;
  arquivo: any = {
    documento: null,
    documentoChip: true
  }
  valid = true;
  dialog = false;
  receptores: any [] = [];
  isReceptorLoading = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formDocumentoReceptor: HTMLFormElement
    formGrupo: HTMLFormElement
  }
  grupoHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Grupo', value: 'grupo.descricao' }
  ];
  grupos: GrupoDocumento[] = [];
  grupoDocumentoService = new GrupoDocumentoService();
  documentoReceptorGrupo = new DocumentoReceptorGrupo();
  validGrupo: boolean = true;

  @Watch('item')
  Item() {

    if (this.$refs.formDocumentoReceptor) {
      this.$refs.formDocumentoReceptor.resetValidation();
    }

    if (this.$refs.formGrupo) {
      this.$refs.formGrupo.resetValidation();
    }

  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }

  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  mounted(){
    this.grupoDocumentoService.ListarTudo().then(
      res =>{
        this.grupos = res.data.items;
      }
    )
  }

  AdicionarReceptorGrupo(){
    if(this.item.todos){
      this.$swal("Aviso", "Não é possível adicionar um grupo em um documento marcado como 'TODOS'!", "warning");
    }
    else if(this.$refs.formGrupo.validate()){
      if(this.item.grupos.find(x => x.grupoId == this.documentoReceptorGrupo.grupoId)){
        this.$swal("Aviso", "Esse grupo já se encontra adicionado!", "warning");
      }
      else{
        this.documentoReceptorGrupo.grupo = this.grupos.find(x => x.id == this.documentoReceptorGrupo.grupoId)!;
        this.item.grupos.push(this.documentoReceptorGrupo);
        if (this.$refs.formGrupo) {
          this.$refs.formGrupo.resetValidation();
        }
      }
      this.documentoReceptorGrupo = new DocumentoReceptorGrupo();
    }
  }

  ExcluirDocumentoReceptorGrupo(usuario: DocumentoReceptorGrupo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.grupos.indexOf(usuario);
        this.item.grupos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  @Watch('onSearchReceptor')
  SearchEquipamento(val: string) {

      if (this.isReceptorLoading) return;
      if (!val) return;

      this.isReceptorLoading = true
      this.receptorService.AutoComplete(val)
          .then(
              res => {
                this.receptores = res.data;
              },
              err => this.$swal('Aviso', err.response.data, 'error')
          )
          .finally(() => (this.isReceptorLoading = false));
  }
 
  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  } 

  async Salvar() {
    if (this.$refs.formDocumentoReceptor.validate()) {  
      
      var arquivoPesado: boolean = false;

      this.item.empresaId = this.app.empresaId;

      if(this.arquivo.documento){
        let dados = await this.arquivoService.Ler(this.arquivo.documento);

        this.item.arquivo = new Arquivo();
        this.item.arquivo.nome = this.arquivo.documento.name;
        this.item.arquivo.tamanho = this.arquivo.documento.size / 1024;
        this.item.arquivo.dados = dados.replace(/^[^,]*,/, "");

        if(this.arquivo.documento.type.length > 0){
          this.item.arquivo.tipo = this.arquivo.documento.type;
        }
        else{
          this.item.arquivo.tipo = 'application/x-rar-compressed';
        }

        if(this.item.arquivo.tamanho > 20000){
          arquivoPesado = true;
        }
        else{
          arquivoPesado = false;
        }
      }
      else if(!this.arquivo.documento && !this.arquivo.documentoChip){
        this.item.arquivo = undefined;
      }

      if(arquivoPesado){
        this.$swal("Aviso", 'Não é possíve fazer upload de um arquivo maior que 20Mb', "error");
      }

      else{
        this.salvando = true;
        let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
        .then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data.title, "warning");
            } else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        ).finally(() =>{
          this.salvando = false;
        });
      }
    }
  }

  Close() {
    this.arquivo = {
      documento: null,
      documentoChip: true
    }

    this.dialog = false;
  }
}
