import { ContratoEquipamento, ContratoResiduo, ContratoServico, UsuarioContrato } from './';
import { Cliente } from '../geral';

export class Contrato implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    clienteId: number = 0;
    cliente!: Cliente;
    descricao: string = '';    
    ativo: boolean = true;
    spot: boolean = false;
    observacao: string = "";
    residuos: ContratoResiduo[] = [];
    servicos: ContratoServico[] = [];
    equipamentos: ContratoEquipamento[] = [];
    usuarios: UsuarioContrato[] = [];

    constructor(model?: Contrato){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.descricao = model.descricao;
        this.ativo = model.ativo;
        this.spot = model.spot;
        this.observacao = model.observacao;
        this.residuos = model.residuos;
        this.servicos = model.servicos;
        this.equipamentos = model.equipamentos;
        this.usuarios = model.usuarios;
    }

}