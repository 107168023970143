








































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { EmpresaService, EnderecoService } from "@/core/services/geral";
import { Empresa, EmpresaConfiguracao } from "@/core/models/geral";
import { ArquivoService } from '@/core/services/shared';

@Component
export default class CadastroEmpresa extends Vue {
  @Prop() private item!: Empresa;
  @Prop() private value!: string;

  itemOriginal!: Empresa;

  service = new EmpresaService();
  enderecoService = new EnderecoService();
  estados: any[] = [];
  municipios: any[] = [];

  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskCep = process.env.VUE_APP_MSK_CEP;

  logo: any = null;

  assinatura: any = null;

  $refs!: {
    formEmpresa: HTMLFormElement
  }
  onSearchCliente: any = null;
  isClienteLoading: boolean = false;
  foto: any = null;
  tabActive: any = {};

  tags: string =  "Cliente - {{cliente}}                                Cliente Endereço - {{clienteEndereco}} \n" + 
                  " Cliente CNPJ - {{clienteCnpj}}             Empresa - {{empresa}} \n" +
                  " Empresa CNPJ - {{empresaCnpj}}      Tabela Resíduos - {{residuos}}\n"+
                  " Embarcação - {{embarcacao}}            Data Validade - {{validade}}";
  
  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  } 

  @Watch('item')
  Item() {
    if (this.$refs.formEmpresa) {
      this.$refs.formEmpresa.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      if(this.item.configuracao == null){
        this.item.configuracao = new EmpresaConfiguracao();
      }
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  async LoadImage(){
    if(!this.logo){
      return;
    }

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.logo);
    this.item.logo = dados.replace(/^[^,]*,/, "");
  }

  RemoveImage(){
    this.item.logo = null;
    this.logo = null;
  }

  async LoadAssinatura(){

    if (!this.assinatura)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.assinatura);
    this.item.assinatura = dados.replace(/^[^,]*,/, "");
  }

  RemoveAssinatura(){
    this.item.assinatura = null;
    this.assinatura = null;
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')}
    );
  }

  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId)
      .then(
          res => {
              this.municipios = res.data.items;
          },
          err => this.$swal('Aviso', err.message, 'error')      
        );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  Salvar() {
    if (this.$refs.formEmpresa.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}

