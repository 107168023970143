













































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { OrdemServicoService, SituacaoOrdemServicoService } from "@/core/services/residuo/";
import { OrdemServico } from "@/core/models/residuo/";
import { SituacaoOrdemServico } from "@/core/models/residuo/SituacaoOrdemServico";

@Component
export default class SelecionarOrdemServico extends Vue {
  @Prop() private selected!: OrdemServico[];
  @Prop() private clienteId?: number;
  @Prop() private value!: boolean;

  service = new OrdemServicoService();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  options: any = {
    itemsPerPage: 15
  };
  totalLista: number = 0;
  localSelected: OrdemServico[] = [];
  loading: boolean = false;
  lista: any[] = [];
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "OS #", value: "numero" },
    { text: "Contrato", value: "contrato.numeroDescricao", sortable: false },
    { text: "Protocolo", value: "protocolo" },
    { text: "Data", value: "data" },
    { text: "Porto", value: "porto.nome" },
    { text: "Embarcação", value: "embarcacao.nome" },
    { text: "Base", value: "base.nome" },  
  ];
  sheet: boolean = false;
  filtro: any = {
    numero: null,
    clienteId: null,
    dataInicial: '',
    dataFinal: '',
    protocolo: '',
    situacaoId: []
  }

  listaSituacaoOrdemServico: SituacaoOrdemServico[] = [];
  selectedSituacaoOrdemServicos: SituacaoOrdemServico[] = [];

  @Watch("options", { deep: true })
  Atualizar(isFiltro: boolean = false) {

    if (isFiltro == true) {
      this.options.page = 1;
    }

    this.sheet = false;
    this.loading = true;

    const { sortBy, sortDesc, page, itemsPerPage } = this.options;

    this.filtro.clienteId = this.clienteId;
    
    this.filtro.situacaoId = [];    
    this.selectedSituacaoOrdemServicos.forEach(situacaoOS => {
      this.filtro.situacaoId.push(situacaoOS.id);
    });

    if(this.selectedSituacaoOrdemServicos.length == 0) this.filtro.situacaoId = null //EVITA ERRO QUANDO NAO FOR INFORMADO SITUACAO

    this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, null, this.headers, this.filtro, "Contrato.Cliente.Embarcacoes, Contrato.Cliente.Bases, Porto, Residuos, Servicos")
      .then(
        res => {
          this.lista = res.data.items;
          this.totalLista = res.data.count;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
      .finally(
        () => (this.loading = false)
      );
  }

  @Watch("value")
  Value() {
    if (this.value){
      this.localSelected = [];
      this.Atualizar();
    }
  }

  mounted() {
    const situacaoOSService = new SituacaoOrdemServicoService()
    situacaoOSService.ListarTudo().then(
      res => { 
        this.listaSituacaoOrdemServico = res.data.items;
        this.selectedSituacaoOrdemServicos = [];
        this.selectedSituacaoOrdemServicos.push( this.listaSituacaoOrdemServico.find(x => x.id == 3)! );
        this.selectedSituacaoOrdemServicos.push( this.listaSituacaoOrdemServico.find(x => x.id == 6)! );
        this.selectedSituacaoOrdemServicos.push( this.listaSituacaoOrdemServico.find(x => x.id == 7)! );
      },
      err => this.$swal("Aviso", err.response.data, "error")
    )
  }

  Selecionar() {

    this.$emit('update:selected', this.localSelected);

    if (this.localSelected.length > 0){
      this.$emit('selecionou');
    }

    this.Close();

  }

  Close() {
    this.$emit("fechou");
  }
}
