import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueTheMask);
Vue.use(VueSweetalert2);

import './registerServiceWorker'

require('./components/');
import './assets/scripts/formatter'
//import './assets/scripts/helper'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
