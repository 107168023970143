import { Usuario } from ".";

export class UsuarioOrdemServicoRestricao implements Shared.IEntity{

    id: number = 0;
    usuarioId: number = 0;
    usuario!: Usuario;
    dataInicial?: string;
    dataFinal?: string;
    embarcacoes: string = "";

    embarcacoesSelecionadas: number[] = [];

    constructor(model?: UsuarioOrdemServicoRestricao){

        if(!model)
            return;

        this.id = model.id;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.dataInicial = model.dataInicial ? model.dataInicial.toDateYYYYMMDD() : undefined;
        this.dataFinal = model.dataFinal ? model.dataFinal.toDateYYYYMMDD() : undefined;
        this.embarcacoes = model.embarcacoes;

    }
}