import { Porto } from ".";
import { Documento } from "../geral";


export class PortoDocumento implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    portoId: number = 0;
    porto!: Porto;

    constructor(model?: PortoDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.portoId = model.portoId;
        this.porto = model.porto;
    }
}