










































import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class CarouselFoto extends Vue {
    @Prop() private titulo!: string;
    @Prop() private item!: any;
    @Prop() private value!: string;

    dialog = false;

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }
    
    beforeUpdate() {
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Close() {
        this.dialog = false;
    }
}
