import { Transportadora } from '.';
import { Documento } from '../geral';

export class TransportadoraDocumento implements Shared.IEntity {

    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    transportadoraId: number = 0;
    transportadora!: Transportadora;

    constructor(model?: TransportadoraDocumento) {

        if (!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.transportadoraId = model.transportadoraId;
        this.transportadora = model.transportadora;
    }

}