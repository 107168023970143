



















































































import { OrdemDestinacao } from "@/core/models/residuo";
import { OrdemDestinacaoService } from "@/core/services/residuo";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelecionarOrdemDestinacao extends Vue {
    @Prop() private value!: boolean;
    @Prop() private selected!: OrdemDestinacao[];
    @Prop() private receptorId!: number;

    service = new OrdemDestinacaoService();
    options: any = {
        itemsPerPage: 15
    };
    totalLista: number = 0;
    loading: boolean = false;
    lista: any[] = [];
    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "OD#", value: "numero" },
        { text: "Contrato", value: "contratoReceptor.numeroReceptor", sortable: false },
        { text: "Data", value: "data" },
        { text: "Total", value: "valorResiduos", sortable: false },
        { text: "Total (Receptor)", value: "valorResiduosReceptor", sortable: false }
    ];

    localSelected: OrdemDestinacao[] = [];
    sheet: boolean = false;

    filtro: any = {
        numero: null,
        receptorId: null,
        dataInicial: null,
        dataFinal: null
    }

    @Watch("value")
    Value() {
        if (this.value){
            this.localSelected.length = 0;
            this.Atualizar();
        }
    }

    mounted() {
        this.Atualizar();
    }

    @Watch("options", { deep: true })
    Atualizar(isFiltro: boolean = false) {

        if (isFiltro == true) {
            this.options.page = 1;
        }

        this.sheet = false;
        this.loading = true;

        this.filtro.receptorId = this.receptorId;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, null, this.headers, this.filtro, "ContratoReceptor.Receptor, Residuos").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else {
                this.$swal("Aviso", err.response.data, "error");
            }
            }
        )
        .finally(
            () => (this.loading = false)
        );
    }

    Selecionar() {
        this.$emit('update:selected', this.localSelected);
        if (this.localSelected.length > 0){
            this.$emit('selecionou');
        }
        this.Close();
    }

    Close() {
        this.$emit("fechou");
    }
}
