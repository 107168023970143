import { http, httpHeader } from '@/core/ApiConfig';
import { ModelCalculoLocacao } from '@/core/models/model/ModelCalculoLocacao';
import { OrdemServico } from '@/core/models/residuo';
import { Service } from '../shared';

export class MedicaoService extends Service{

  constructor(){
    super('medicao');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if (filter.numero){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }
  
      if (filter.clienteId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `clienteId eq ${filter.clienteId}`;
      }
  
      if (filter.dataInicial){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }
      
      if (filter.dataFinal){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }
    return parametros;
  }

  VincularOrdemServico(id: number, ordemServicoId: number, vincular: boolean, acrescimo?: number, decrescimo?: number){
    return http.post(`${this.GetNomeControle()}/VincularOrdemServico`, null, {
      params: {
        id,
        ordemServicoId,
        vincular,
        acrescimo,
        decrescimo
      },
      headers: httpHeader.headers
    });
  }

  VincularOrdemServicoLocacao(id: number, ordemServicoLocacaoId: number, vincular: boolean){
    return http.post(`${this.GetNomeControle()}/VincularOrdemServicoLocacao`, null, {
      params: {
        id,
        ordemServicoLocacaoId,
        vincular
      },
      headers: httpHeader.headers
    });
  }

  Detalhar(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
  }

  MontaFiltro(filter: any){
    
    let paramTemp = '';

    if (filter.numero){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `numero eq ${filter.numero}`;
    }

    if (filter.clienteId){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `clienteId eq ${filter.clienteId}`;
    }

    if (filter.dataInicial){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `data ge ${filter.dataInicial}`;
    }
    
    if (filter.dataFinal){
      if (paramTemp) { paramTemp += ' and '; }
      paramTemp += `data le ${filter.dataFinal}`;
    }

    return paramTemp;  
  }

  CalcularLocacao(model: ModelCalculoLocacao){
    return http.post(`${this.GetNomeControle()}/CalcularLocacao`, model);
  }

  ExcelBruto(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}/excelBruto${parametros}`);
  }

  PrevisaoReceitaTransporte(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoReceitaTransporte`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoGastoTransporte(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoGastoTransporte`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoRelacaoGastoTransporte(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoRelacaoGastoTransporte`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }
  
  PrevisaoGastoReceptor(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoGastoReceptor`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoRelacaoGastoReceptor(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoRelacaoGastoReceptor`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoGastoReceptorTecnologia(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoGastoReceptorTecnologia`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoRelacaoGastoReceptorTecnologia(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, odNumero?: number, receptorId?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoRelacaoGastoReceptorTecnologia`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        odNumero,
        receptorId,
        transportadorId
      }
    });
  }

  PrevisaoGastoLocatario(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoGastoLocatario`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        transportadorId
      }
    });
  }

  PrevisaoRelacaoGastoLocatario(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoRelacaoGastoLocatario`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        transportadorId
      }
    });
  }

  PrevisaoGastoLocatarioGrupo(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoGastoLocatarioGrupo`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        transportadorId
      }
    });
  }

  PrevisaoRelacaoGastoLocatarioGrupo(dataInicio: string, dataFim: string, clienteId?: number, contratoId?: number, osNumero?: number, transportadorId?: number){
    return http.get(`${this.GetNomeControle()}/PrevisaoRelacaoGastoLocatarioGrupo`, {
      params: {
        dataInicio,
        dataFim,
        clienteId,
        contratoId,
        osNumero,
        transportadorId
      }
    });
  }

}