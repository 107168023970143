import { TipoDocumento } from '../geral/TipoDocumento';
import { Arquivo } from './../shared/Arquivo';
import { Embarcacao } from './Embarcacao';

export class DocumentoEmbarcacao implements Shared.IEntity{
    
    id: number = 0;
    embarcacaoId: number = 0;
    embarcacao!: Embarcacao; 
    tipoId: number = 0;
    tipo!: TipoDocumento;
    dataValidade: string = '';
    arquivo: Arquivo = new Arquivo();
    excluido: boolean = false;

    constructor(model?: DocumentoEmbarcacao){
        
        if(!model)
            return;

        this.id = model.id;
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.dataValidade = model.dataValidade? model.dataValidade.toDateYYYYMMDD() : '';
        this.arquivo = model.arquivo;
    }
}