import { TipoVeiculoModal } from './TipoVeiculoModal';
import { Empresa } from '.';
import { TipoVeiculoPerguntas } from '../monitoramento';

export class TipoVeiculo implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    modalId: number = 0;
    modal!: TipoVeiculoModal
    perguntas: TipoVeiculoPerguntas[] = []
    
    constructor(model?: TipoVeiculo){

        if(!model)
            return;
        
        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.nome = model.nome;
        this.modalId = model.modalId;
        this.modal = model.modal;
        this.perguntas = model.perguntas;
    }

}