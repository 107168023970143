import { OrdemServicoResiduo } from './../../models/residuo/OrdemServicoResiduo';
import { http, httpHeader } from '../../ApiConfig'
import { Service } from '../shared';

export class OrdemDestinacaoService extends Service {

  constructor() {
    super('ordemDestinacao');
  }

  VincularOrdemServicoResiduo(model: OrdemServicoResiduo) {
    return http.post(`${this.GetNomeControle()}/VincularOrdemServicoResiduo`, null, {
      params: {
        id: model.id,
        ordemServicoId: model.ordemServicoId,
        ordemDestinacaoId: model.ordemDestinacaoId,
        quantidade: model.quantidade,
        quantidadeReceptor: model.quantidadeReceptor,
        situacaoResiduoId: model.situacaoId
      },
      headers: httpHeader.headers
    });
  }

  MontaFiltro(filter: any) {

    let paramTemp = '';

    if (filter) {
      if (filter.numero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }

      if (filter.transportadoraId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `transportadoraId eq ${filter.transportadoraId}`;
      }

      if (filter.receptorId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `receptorId eq ${filter.receptorId}`;
      }

      if (filter.dataInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }

      if (filter.dataFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      if (filter.mr02) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(processo2/mr02, '${filter.mr02}')`;
      }

      if (filter.mr03) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(processo3/mr03, '${filter.mr03}')`;
      }

      if (filter.mr04) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(processo4/mr04, '${filter.mr04}')`;
      }

    }

    return paramTemp;
  }

  AtualizarResiduoValorReceptor(id: number, residuoId: number) {
    return http.post(`${this.GetNomeControle()}/${id}/AtualizarResiduoValorReceptor/${residuoId}`);
  }

  AtualizarValoresReceptor(id: number) {
    return http.post(`${this.GetNomeControle()}/AtualizarValoresReceptor`, null, {
      params: {
        id
      },
      headers: httpHeader.headers
    });
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  private GetFiltro(parametros: string, filter: any) {
    if (filter) {
      let paramTemp = '';

      paramTemp += 'medicaoId eq null'

      //Receptor
      if (filter.receptorId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `receptorId in (${filter.receptorId})`;
      }

      //OD#
      if (filter.numero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero in (${filter.numero})`;
      }

      //FILTRO POR DATA
      if (filter.dataInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }

      if (filter.dataFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      if (paramTemp) {

        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }
  Download(id: number, osId: number, tipo: string) {
    return http.get(`${this.GetNomeControle()}/${id}/download/${tipo}`, 
    { 
      params: {
        osId
      },
      responseType: 'blob'
    });
  }
}