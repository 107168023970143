import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class DocumentoReceptorService extends Service{

  constructor(){
    super('documentoReceptor');
  }  

  Download(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/download`, {responseType: 'blob'});
  }

  ListarAcabandoValidade(dias: number){
    return http.get(`${this.GetNomeControle()}/acabandoValidade`, {
      params: {
          dias: dias,
      },        
      headers: httpHeader
  });
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
        params: {
            q: q
        },
        headers: httpHeader.headers
    });
}
}