import { ContratoReceptor } from './ContratoReceptor';
import { OrdemDestinacao } from './OrdemDestinacao';

export class MedicaoReceptor implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    contratoReceptorId: number = 0;
    contratoReceptor!: ContratoReceptor;
    data: string = new Date().toYYYYMMDD();
    competenciaMes?: number;
    competenciaAno?: number;
    observacao: string = "";
    ordensDestinacao: OrdemDestinacao[] = [];

    constructor(model?: MedicaoReceptor){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.contratoReceptorId = model.contratoReceptorId;
        this.contratoReceptor = model.contratoReceptor;
        this.data = model.data? model.data.toDateYYYYMMDD() : '';
        this.competenciaMes = model.competenciaMes;
        this.competenciaAno = model.competenciaAno;
        this.observacao = model.observacao;
        this.ordensDestinacao = model.ordensDestinacao;
    }

}