import { http } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class AgendamentoService extends Service{

  constructor(){
    super('agendamento');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
        let paramTemp = '';

        if (filter.transportadoraId){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `motorista/transportadoraId eq ${filter.transportadoraId}`;
        }

        if (paramTemp) {

          if(parametros){
            parametros += '&';
          }
          else {
            parametros += '?';
          }

          parametros += `$filter=${paramTemp}`;
        }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }
  
}