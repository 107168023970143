

































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { PortoService } from "@/core/services/residuo";
import { EnderecoService } from "@/core/services/geral";
import { Porto, PortoDocumento, PortoReceptor } from "@/core/models/residuo";
import { PageBase } from "@/core/models/shared";
import { ArquivoService } from "@/core/services/shared";

@Component
export default class CadastroPorto extends PageBase {
  @Prop() private item!: Porto;
  @Prop() private value!: string;

  itemOriginal!: Porto;

  enderecoService = new EnderecoService();
  portoReceptor: PortoReceptor = new PortoReceptor();
  service = new PortoService();
  valid = true;
  dialog = false;
  estados: any[] = [];
  mskCep = process.env.VUE_APP_MSK_CEP;
  municipios: any[] = [];
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tabActive: any = {};
  $refs!: {
    formPorto: HTMLFormElement
  }
  dialogPortoReceptor = false;
  editaPortoReceptor = false;
  headerPortoReceptor: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'PCP Código', value:"pcpCodigo"},
    { text: 'Receptor', value:"receptor.pcpCodigoNome"},
    { text: 'Estados', value:"estados"},
    {text: 'Distância (Km)', value:"distancia"}
  ]

  // DOCUMENTOS
  documento: PortoDocumento = new PortoDocumento();
  documentos: PortoDocumento[] = [];
  totalDocumentos: number = 0;
  optionsDocumentos: any = {
    itemsPerPage: -1
  }
  loadingDocumentos: boolean = false;
  loadingDocumentoDialog: boolean = false;
  dialogDocumento: boolean = false;
  loadingDownload: boolean = false;
  headersDocumentos: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Descrição', value: 'documento.descricao' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Validade', value: 'documento.dataValidade', type:'date' },
  ];

  @Watch('item')
  Item() {
    if (this.$refs.formPorto) {
      this.$refs.formPorto.resetValidation();
    }
    if(this.item.id > 0){
      this.CarregarDocumentos();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item)
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged() {
    this.CarregarMunicipio();
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')}
    );
  }

  CarregarMunicipio(){
    this.enderecoService.ObterMunicipios(this.item.endereco.estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')      
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  AbrirDialogPortoReceptor(portoReceptor?:PortoReceptor){
    if(portoReceptor){
      this.portoReceptor = portoReceptor;
      this.editaPortoReceptor = true;
    }
    else{
      this.portoReceptor = new PortoReceptor();
      this.portoReceptor.portoId = this.item.id;
      this.editaPortoReceptor = false;
    }
    this.dialogPortoReceptor = true;
  }

  SalvarPortoReceptor(){
    if(!this.editaPortoReceptor){
      this.item.portoReceptores.push(this.portoReceptor);
    }
    this.dialogPortoReceptor = false;
  }

  ExcluirPortoReceptor( portoReceptor: PortoReceptor){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const index = this.item.portoReceptores.indexOf(portoReceptor);
        this.item.portoReceptores.splice(index,1);

        return true;
      },
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  Salvar() {
    if (this.$refs.formPorto.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );      
    }
  }

  // DOCUMENTOS
  AbrirCadastroDocumento(documento?: PortoDocumento){
    if(documento){
      this.loadingDocumentoDialog = true;
      this.service.ObterDocumentoPorId(documento.id).then(
        res => {
          this.documento = res.data;
          this.documento.documento.dataValidade = this.documento.documento.dataValidade? this.documento.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogDocumento = true;
        },err => {
          this.$swal('Aviso',err.message,'error')
        }
      ).finally(() => {
        this.loadingDocumentoDialog = false;
      })
    }
    else{
      this.documento = new PortoDocumento();
      this.documento.portoId = this.item.id;
      this.documento.documento.empresaId = this.app.empresaId;
      this.dialogDocumento = true;
    }
  }

  @Watch('optionsDocumentos', { deep: true })
  CarregarDocumentos(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsDocumentos;
    this.loadingDocumentos = true;
    this.service.ListarDocumentos(this.item.id, page, itemsPerPage, sortBy, sortDesc, search, this.headersDocumentos).then(
      res =>{
        this.documentos = res.data.items;
        this.totalDocumentos = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loadingDocumentos = false;
    })
  }

  ExcluirDocumento(documento: PortoDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(documento.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
    .then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        this.CarregarDocumentos();
      }
    })
  }

  AbrirLink(link){
    if(!!link)
      window.open(link,"_blank");
  }

  DownloadDocumento(documento: PortoDocumento){
    this.loadingDownload = true;
    this.service.DownloadDocumento(documento.id).then(
      async (res) => {
        if (res.status == 204){
          return;
        }
        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.documento.arquivo!.nome);
      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }

  Close() {
    this.dialog = false;
  }
}
