export class OrdemServicoLocacaoModel {
    itens: OrdemServicoLocacaoItem[] = [];
    total: number = 0;
}   
export class OrdemServicoLocacaoItem {
    equipamento: ModelEquipamento = new ModelEquipamento();
    subTotal: number = 0;
}
export class ModelEquipamento {
    id: number = 0;
    dataHoraInicioCobranca: string = '';
    dataHoraTerminoCobranca: string = '';
    totalDias: number = 0;
}