









































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { CartaRastreabilidade, CartaRastreabilidadeItem, OrdemServicoResiduo } from "@/core/models/residuo";
import { CartaRastreabilidadeService } from "@/core/services/residuo";
import { Cliente, Usuario } from "@/core/models/geral";
import { ClienteService, EmpresaService, UsuarioService } from "@/core/services/geral";
import { PageBase } from "@/core/models/shared";

@Component
export default class CadastroArmazenamento extends PageBase{
    @Prop() private item!: CartaRastreabilidade;
    @Prop() private value!: string;

    itemOriginal!: CartaRastreabilidade;
    service = new CartaRastreabilidadeService();

    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    onSearchCliente: any = null;
    isClienteLoading: boolean = false;
    clientes: Cliente[] = [];
    clienteService: ClienteService = new ClienteService();

    isUsuarioLoading: boolean = false;
    usuarios: Usuario[] = [];
    usuarioService: UsuarioService = new UsuarioService();

    $refs!: {
        form: HTMLFormElement
    }

    headerItens: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "OS #", value: "ordemServicoResiduo.ordemServico.numero" },
        { text: "OD #", value: "ordemServicoResiduo.ordemDestinacao.numero" },
        { text: "Resíduo", value: "ordemServicoResiduo.residuo.nome" },
        { text: "MR01", value: "ordemServicoResiduo.processo1.mr01" },
        { text: "Coleta", value: "ordemServicoResiduo.dataColeta" },
        { text: "Unidade", value: "ordemServicoResiduo.unidade.sigla" },
        { text: "Quantidade", value: "ordemServicoResiduo.quantidade" }
    ];

    dialogSelecionarResiduo: boolean = false;
    ordemServicoResiduos: OrdemServicoResiduo[] = [];

    cartaRastreabilidadeAutorizacao: boolean = true;

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }

        if(this.item.id > 0){
            this.clientes.push(this.item.cliente);
        }
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
            this.Carregar();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;

        this.isClienteLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isClienteLoading = false));
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Carregar() {
        this.isUsuarioLoading = true;
        this.usuarioService.ListarTudo().then(
            res => {
                this.usuarios = res.data.items;
            }
        ).finally(() => {
            this.isUsuarioLoading = false;
        })

        new EmpresaService().ObterPorId(this.app.empresaId, "Configuracao").then(
            res => {
                var empresa = res.data;
                this.cartaRastreabilidadeAutorizacao = empresa.configuracao.cartaRastreabilidadeAutorizacao;
            }
        )
    }

    AbrirDialogEquipamento(){
        this.dialogSelecionarResiduo = true;
    }

    SelectedOrdemServicoResiduo(){
        this.ordemServicoResiduos.forEach(ordemServicoResiduo => {
            if(this.item.itens.find(x => x.ordemServicoResiduoId == ordemServicoResiduo.id)){

            }
            else{
                var cartaItem = new CartaRastreabilidadeItem();
                cartaItem.cartaRastreabilidadeId = this.item.id;
                cartaItem.ordemServicoResiduoId = ordemServicoResiduo.id;
                cartaItem.ordemServicoResiduo = ordemServicoResiduo;
                this.item.itens.push(cartaItem);
            }
        });
    }

    ExcluirItem(item: CartaRastreabilidadeItem){
        this.$swal({
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {                
                const index = this.item.itens.indexOf(item);
                this.item.itens.splice(index,1);
                return true;
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    Salvar (){
        if (this.$refs.form.validate()) {

            let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
            .then(
                res => {
                     this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    if (err.response.status == 400) {
                        this.$swal("Aviso", err.response.data, "warning");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            );
        }
    }

    Close() {
        this.dialog = false;
    }
}
