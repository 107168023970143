import { OrdemServico, Servico } from '.';
import { UnidadeMedida } from '../geral';

export class OrdemServicoServicoTransportadora implements Shared.IEntity{
    
    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    servicoId: number = 0;
    servico!: Servico;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    valor: number = 0;
    quantidade: number = 0;

    excluido: boolean = false;

    constructor(model?: OrdemServicoServicoTransportadora){

        if(!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.servicoId = model.servicoId;
        this.servico = model.servico;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.valor = model.valor;
        this.quantidade = model.quantidade;
    }
} 