


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { TecnologiaService } from "@/core/services/residuo";
import { Tecnologia } from "@/core/models/residuo";

@Component
export default class CadastroServico extends Vue {
  @Prop() private item!: Tecnologia;
  @Prop() private value!: string;

  itemOriginal!: Tecnologia;

  service = new TecnologiaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  grupos: any[] = [];
  $refs!: {
    formTecnologia: HTMLFormElement
  }

  @Watch('item')
  Item() {
    if (this.$refs.formTecnologia) {
      this.$refs.formTecnologia.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  Salvar() {
    if (this.$refs.formTecnologia.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
