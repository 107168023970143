















































































import { Empresa } from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';
import { EmpresaService } from '@/core/services/geral';
import router from '@/router';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RelatorioPage extends PageBase {

    @Prop() titulo!: string;
    @Prop() cabecalhoCompleto?: boolean;
    @Prop() marcaDagua?: string;
    @Prop() listaSubTitulo!: string[];
    @Prop() paisagem?: boolean;
    @Prop({default: true}) overlay!: boolean;
    @Prop() exportarExcel?: boolean;

    empresaService = new EmpresaService()
    empresa: Empresa = new Empresa();
    data: any = Date.now;

    mounted() {

        let root = document.documentElement;
        this.empresaService.ObterPorId(this.app.empresaId, 'Endereco.Municipio, Endereco.Municipio.Estado')
            .then(
                res=> this.empresa = res.data,
                err=> { }
            )

        if(this.paisagem){
            root.style.setProperty("--widthPage", 29.7 + 'cm');
            root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            root.style.setProperty("--widthPage", 21 + 'cm');
            root.style.setProperty("--min-heightPage", 26 + 'cm');
            this.setPageSize('portrait')
        }
    }

    setPageSize(size: string){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
        //router.go(-1);
    }
}
