import { Empresa } from '../geral';

export class Tecnologia implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    nome: string = '';
    pcpCodigo: string = '';
    final: boolean = false;
    pcp: boolean = false;

    constructor(model?: Tecnologia) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.id;
        this.nome = model.nome;
        this.pcpCodigo = model.pcpCodigo;
        this.final = model.final;
        this.pcp = model.pcp;
    }

}