import { Empresa } from ".";
import { Porto } from "../residuo";

export class Regiao implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    codigo: string = '';
    nome: string = '';
    portos: Porto[] = [];

    constructor(model?: Regiao){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.portos = model.portos;
    }
}