




























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { PageBase } from "@/core/models/shared";
import { Regiao } from "@/core/models/geral";
import { RegiaoService } from "@/core/services/geral";
import { Porto } from "@/core/models/residuo";
import { PortoService } from "@/core/services/residuo";

@Component
export default class CadastroVeiculo extends PageBase {
  @Prop() private item!: Regiao;
  @Prop() private value!: string;

  itemOriginal!: Regiao;
  service = new RegiaoService();

  valid = true;
  validArquivo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tipos: any[] = [];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  $refs!: {
    form: HTMLFormElement,
  }
  headersPorto: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Porto', value: 'nome' }
  ];

  portos: any[] = [];

  portosSelect: Porto[] = [];
  portoSelectId: number = 0;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }

    this.portos = JSON.parse(JSON.stringify(this.item.portos));

  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  itemExcluido(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else
      return ''
  }

  mounted() {
    new PortoService().ListarTudo().then(
      res => {
        this.portosSelect = res.data.items;
      }
    )
  }

  VincularPorto(){
    var porto = this.portosSelect.find(x => x.id == this.portoSelectId);
    this.portos.push({id: porto!.id, nome: porto!.nome, regiaoId: this.item.id});
    this.portoSelectId = 0;
  }

  Desvincular(item: Porto){
    item.regiaoId = undefined;
    item.excluido = true;

    this.dialog = false;
    this.dialog = true;
  }

  SalvarPortos(){
    this.portos.forEach(x => {
      this.service.VincularPorto(x.id, x.regiaoId).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        },
        err =>{
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    })
  }

  Salvar() {
    if (this.$refs.form.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.SalvarPortos();
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );

    }
  }

  Close() {
    this.dialog = false;
  }
}
