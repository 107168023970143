import { UnidadeMedida } from '../geral';
import { Acondicionamento, Armazenamento, GrupoResiduo, Tecnologia } from './';

export class Residuo implements Shared.IEntity {

    id: number = 0;
    empresaId: number = 0;
    codigo: string = '';
    nome: string = '';
    grupoId: number = 0;
    grupo!: GrupoResiduo;
    tecnologiaId: number = 0;
    tecnologia!: Tecnologia;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    preco: number = 0;
    ativo: boolean = true;
    pcpCodigo: string = '';
    acondicionamentoId?: number;
    acondicionamento!: Acondicionamento;
    armazenamentoId?: number;
    armazenamento!: Armazenamento;


    constructor(model?: Residuo) {

        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.grupoId = model.grupoId;
        this.tecnologiaId = model.tecnologiaId;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.preco = model.preco;
        this.ativo = model.ativo;
        this.pcpCodigo = model.pcpCodigo;
    }
}