import { TipoFoto } from './TipoFoto';
import { Arquivo } from './../shared/Arquivo';
import { Operacao } from './Operacao';

export class OperacaoFoto extends Arquivo implements Shared.IEntity{

    id: number = 0;
    operacaoId: number = 0;
    operacao?: Operacao;
    tipoFotoId: number = 0;
    tipoFoto!: TipoFoto;
    excluido: boolean = false;

    constructor(model?: OperacaoFoto){

        super();
        
        if(!model)
            return;

        this.id = model.id;
        this.operacaoId = model.operacaoId;
        this.tipoFotoId = model.tipoFotoId;
    }
}