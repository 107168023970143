
















































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';

import { GrupoDocumentoService, TipoDocumentoService } from "@/core/services/geral";
import { ArquivoService } from '@/core/services/shared';
import { Documento, DocumentoGrupo, GrupoDocumento, TipoDocumento, } from "@/core/models/geral";
import { Arquivo } from '@/core/models/shared';
import { Receptor } from "@/core/models/residuo";
import { ReceptorService } from "@/core/services/residuo";
import { DocumentoService } from "@/core/services/geral/DocumentoService";

@Component
export default class CadastroDocumentoEmpresa extends Vue {
  @Prop() private value!: string;
  @Prop() private item!: Documento;
  @Prop() private service!: DocumentoService;

  observer! : jsonpatch.Observer<Documento>;

  arquivoService = new ArquivoService();
  salvando: boolean = false;
  arquivo: any = {
    documento: null,
    documentoChip: true
  }
  valid = true;
  validGrupo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formDocumentoEmpresa: HTMLFormElement
    formGrupo: HTMLFormElement
  }  
  grupoHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Grupo', value: 'grupo.descricao' }
  ];
  documentoEmpresaGrupo = new DocumentoGrupo();
  grupos: GrupoDocumento[] = [];
  grupoDocumentoService = new GrupoDocumentoService();

  receptores: Receptor[] = [];
  receptorService: ReceptorService = new ReceptorService();

  tipos: TipoDocumento[] = [];
  tabActive: any = null;

  destinatarios: string[] = [];
  dias: string[] = [];
  
  dialogDocumentoAnexado: boolean = false;
  loadingAnexados: boolean = false;
  documentoAnexado: Documento = new Documento();
  documentosAnexados: Documento[] = [];
  documentosAnexadosHeaders: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'identificacao' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Protocolo Ext. Validade', value: 'protocoloValidade' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Arquivo', value: 'arquivo.nome' },
    { text: 'Validade', value: 'dataValidade', type:'date' }
  ];

  @Watch('item')
  Item() {

    if (this.$refs.formDocumentoEmpresa) {
      this.$refs.formDocumentoEmpresa.resetValidation();
    }
    if (this.$refs.formGrupo) {
      this.$refs.formGrupo.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.tabActive = 0;
    this.dialog = this.value ? true : false;
    if(this.dialog){
      setTimeout(() => {
        this.observer = jsonpatch.observe(this.item);
      }, 100);      
    }
    else{
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.notificacao.destinatarios")
  WatchEmail(val: any){
    if(val.length == 0){
      this.destinatarios = [];
    }
    else{
      this.destinatarios = this.item.notificacao.destinatarios.split(",");
    }
  }

  @Watch("item.notificacao.dias")
  WatchDias(val: any){
    if(val.length == 0){
      this.dias = [];
    }
    else{
      this.dias = this.item.notificacao.dias.split(",");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  } 

  mounted(){
    this.grupoDocumentoService.ListarTudo().then(
      res =>{
        this.grupos = res.data.items;
      }
    )
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      }
    )
    this.receptorService.ListarTudo().then(
      res => {
        this.receptores = res.data.items;
      }
    )
  }

  AdicionarEmpresaGrupo(){
    if(this.item.todos && this.grupos.find(x => x.id == this.documentoEmpresaGrupo.grupoId)!.editor == false){
      this.$swal("Aviso", "Não é possível adicionar um grupo de visualização em um documento marcado como 'TODOS'!", "warning");
    }
    else if(this.$refs.formGrupo.validate()){
      if(this.item.grupos.find(x => x.grupoId == this.documentoEmpresaGrupo.grupoId)){
        this.$swal("Aviso", "Esse grupo já se encontra adicionado!", "warning");
      }
      else{
        this.documentoEmpresaGrupo.grupo = this.grupos.find(x => x.id == this.documentoEmpresaGrupo.grupoId)!;
        this.item.grupos.push(this.documentoEmpresaGrupo);
        if (this.$refs.formGrupo) {
          this.$refs.formGrupo.resetValidation();
        }
      }
      this.documentoEmpresaGrupo = new DocumentoGrupo();
    }
  }

  ExcluirDocumentoGrupo(grupo: DocumentoGrupo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.grupos.indexOf(grupo);
        this.item.grupos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  AbrirLink(){
    if(!!this.item.link)
      window.open(this.item.link,"_blank");
  }

  //ANEXADOS
  CarregarAnexados(){
    this.loadingAnexados = true;
    this.service.ListarAnexados(this.item.id).then(
      res => {
        this.documentosAnexados = res.data;
      },
      err => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingAnexados = false;
    })
  }

  DialogDocumentoAnexado(item?: Documento){
    if(item){
      this.service.ObterPorId(item.id, "Tipo, Grupos.Grupo, Grupos.Grupo.Usuarios").then(
        res=>{
          this.documentoAnexado = new Documento(res.data);
          this.dialogDocumentoAnexado = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } 
          else {
            if(err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }else{
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        }
      )
    }
    else{
      this.documentoAnexado = new Documento();
      this.documentoAnexado.empresaId = this.item.empresaId;
      this.documentoAnexado.parentId = this.item.id;
      this.dialogDocumentoAnexado = true;
    }
  }

  ExcluirDocumentoAnexado(item: Documento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          },
          err => {
            if (err.response.status == 400){
              this.$swal("Aviso", err.response.data, "warning");                
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
      ).finally(() => {
        this.CarregarAnexados();
      })}
    })
  }

  DownloadDocumentoAnexado(documento: Documento){
    this.loadingAnexados = true;
    this.service.Download(documento.id).then(
      async (res) => {
        if (res.status == 204){
          return;
        }
        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.arquivo!.nome);
      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingAnexados = false;
    });
  }

  async Salvar() {
    if (this.$refs.formDocumentoEmpresa.validate()) {    

      var arquivoPesado: boolean = false;

      if(this.arquivo.documento){
        let dados = await this.arquivoService.Ler(this.arquivo.documento);

        this.item.arquivo = new Arquivo();
        this.item.arquivo.nome = this.arquivo.documento.name;
        this.item.arquivo.tamanho = this.arquivo.documento.size / 1024;
        this.item.arquivo.dados = dados.replace(/^[^,]*,/, "");

        if(this.arquivo.documento.type.length > 0){
          this.item.arquivo.tipo = this.arquivo.documento.type;
        }
        else{
          this.item.arquivo.tipo = 'application/x-rar-compressed';
        }

        if(this.item.arquivo.tamanho > 20000){
          arquivoPesado = true;
        }
        else{
          arquivoPesado = false;
        }
      }
      else if(!this.arquivo.documento && !this.arquivo.documentoChip){
        this.item.arquivo = undefined;
      }

      if(arquivoPesado){
        this.$swal("Aviso", 'Não é possíve fazer upload de um arquivo maior que 20Mb', "error");
      }
      else{
        this.salvando = true;

        let pacthModel = jsonpatch.generate(this.observer);

        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data, "warning");
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }
            else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        ).finally(() => {
          this.salvando = false;
        });
      }
    }
  }

  Close() {
    this.tabActive = 0;
    this.arquivo = {
      documento: null,
      documentoChip: true
    }
    this.dialog = false;
  }
}
