



































































































































































































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';

import { ContratoService, EquipamentoService, OrdemServicoService, PortoService, SituacaoOrdemServicoResiduoService, SituacaoOrdemServicoService, TipoOrdemServicoLocacaoService, TransportadoraService } from "@/core/services/residuo";
import { OrdemServico, OrdemServicoResiduo, OrdemServicoServico, Contrato, ContratoResiduo, ContratoServico, OrdemServicoFoto, OrdemServicoLocacao, TransportadoraServico, Transportadora} from "@/core/models/residuo";
import { VeiculoService, MotoristaService, EmpresaService } from "@/core/services/geral";
import { PageBase } from '@/core/models/shared';
import { ArquivoService } from '@/core/services/shared';
import { EnumSituacaoOrdemServicoResiduo, EnumTipoOrdemServicoLocacao, TipoOperacaoLocacao } from '@/core/models/residuo/Enumerados';
import { EmpresaConfiguracao } from "@/core/models/geral";
import { OrdemServicoServicoTransportadora } from "@/core/models/residuo/OrdemServicoServicoTransportadora";

@Component
export default class CadastroOrdemServico extends PageBase {
  @Prop() private item!: OrdemServico;
  @Prop() private value!: string;

  save: boolean = false;

  contratoId: number = this.item.contratoId;

  service = new OrdemServicoService();
  arquivoService = new ArquivoService();
  contratoService = new ContratoService();
  equipamentoService = new EquipamentoService();  
  transportadoraService = new TransportadoraService();

  valid = true;
  validServicoTransportadora = true;
  validDialogResiduo = true;
  validDialogServico = true;

  dialog = false;
  dialogResiduo = false;
  dialogServico = false;
  dialogLocacao = false;  
  dialogFoto = false;

  editaServico = false;
  editaLocacao = false;
  editaResiduo = false;
  editaFoto = false;

  isContratoLoading = false;
  isEquipamentoLoading = false;  

  onSearchContrato: any = null;
  onSearchContratoLocacao: any = null;
  onSearchEquipamento: any = null;

  ordemServicoresiduo: OrdemServicoResiduo = new OrdemServicoResiduo();
  ordemServicoServico: OrdemServicoServico = new OrdemServicoServico();
  ordemServicoServicoTransportadora: OrdemServicoServicoTransportadora = new OrdemServicoServicoTransportadora();
  ordemServicoLocacao: OrdemServicoLocacao = new OrdemServicoLocacao();
  ordemServicoFoto: OrdemServicoFoto = new OrdemServicoFoto();

  transportadoraServico: TransportadoraServico = new TransportadoraServico();

  residuos: ContratoResiduo[] = [];
  servicos: ContratoServico[] = [];
  servicosTransportadora: TransportadoraServico[] = [];
  situacaoResiduos: any[] = [];
  equipamentos: any[] = [];
  embarcacoes: any[] = [];
  bases: any[] = [];
  contratos: Contrato[] = [];
  portos: any[] = [];
  transportadoras: any[] = [];
  veiculos: any[] = [];
  situacoes: any[] = [];
  motoristas: any[] = [];
  ordemServicoLocacoes: any[] = [];  

  tipoLocacao: number = 0;

  tabActive: any = {};
  baseOrigem: boolean = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  arquivo: any  = {
    mr01: null,
    mr01Chip: true,
    cdf01: null,
    cdf01Chip: true,
    cartaResponsabilidade: null,
    cartaResponsabilidadeChip: true,
    ticketPesagem: null,
    ticketPesagemChip: true,
    fcdr: null,
    fcdrChip: true,
    mr02: null,
    mr02Chip: true,
    cdf02: null,
    cdf02Chip: true
  }

  $refs!: {
    formOrdemServico: HTMLFormElement,
    formDialogServico: HTMLFormElement,
    formServicoTransportadora: HTMLFormElement,
    formDialogLocacao: HTMLFormElement
  }

  observer! : jsonpatch.Observer<OrdemServico>;

  headerResiduo: any[] = [
    { text: '',value:'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Coleta', value: 'dataColeta' },
    { text: 'Qtd.', value: 'quantidade' },
    { text: 'Ticket', value: 'arquivo.ticketPesagem' },
    { text: 'MMR / FCDR', value: 'arquivo.fcdr' },
    { text: 'RT', value: 'registroTransporte' },
    { text: 'MR01', value: 'arquivo.mr01' },
    { text: 'CDF01', value: 'arquivo.cdf01' },
    { text: 'Rel.Rec.1', value: 'arquivo.cartaResponsabilidade' },
    { text: 'R.O.1', value: 'arquivo.relatorioObservacao' },
    { text: 'MR02', value: 'arquivo.mr02' },
    { text: 'CDF02', value: 'arquivo.cdf02' },
    { text: 'Rel.Rec.2', value: 'arquivo.relatorioRecebimento2' },
    // { text: 'R.O.2', value: 'arquivo.relatorioObservacao2' },
    { text: 'MR03', value: 'arquivo.mr03' },
    { text: 'CDF03', value: 'arquivo.cdf03' },
    { text: 'Rel.Rec.3', value: 'arquivo.relatorioRecebimento3' },
    // { text: 'R.O.3', value: 'arquivo.relatorioObservacao3' },
    { text: 'MR04', value: 'arquivo.mr04' },
    { text: 'CDF04', value: 'arquivo.cdf04' },
    { text: 'Rel.Rec.4', value: 'arquivo.relatorioRecebimento4' },
    // { text: 'R.O.4', value: 'arquivo.relatorioObservacao4' },
    { text: 'O.D.', value: 'ordemDestinacao.numero' },
    { text: 'Sit.', value: 'situacaoId' },
  ];

  headerServico: any[] = [
    { text: '',value:'actions', sortable: false },
    { text: 'Serviço', value: 'servico.codigoNome' },
    { text: 'Quantidade', value: 'quantidade' },
  ];

  headerServicoTransportadora: any[] = [
    { text: '',value:'actions', sortable: false },
    { text: 'Serviço', value: 'servico.codigoNome' },
    { text: 'Unidade Medida', value: 'unidadeMedida.nome' },
    { text: 'Quantidade', value: 'quantidade' },
  ];

  headerLocacao: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Origem', value: 'parent.equipamento.grupoNome' },
    { text: 'Data Início', value: 'dataInicio' },
    { text: 'Equipamento', value: 'equipamento.grupoNome' },
    { text: 'Data Fim', value: 'dataFim' },
    { text: 'Movimento', value: 'tipo.nome' },
    { text: 'Equipamento Troca', value: 'equipamentoTroca.grupoNome' }    
  ];

  headerFoto: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Foto', value: 'dados' },
    { text: 'Nome', value: 'nome' },
    { text: 'Descrição', value: 'descricao' }
  ];

  configuracaoEmpresa: EmpresaConfiguracao = new EmpresaConfiguracao()

  itemExcluido(item: any){
    return item.excluido ? 'itemExcluido' : '';
  }

  itemResiduoClass(item: any){

    if(item.situacaoId == EnumSituacaoOrdemServicoResiduo.Cancelado)
      return 'itemOSClass';

    return this.itemExcluido(item);
  }
  
  beforeUpdate() {
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('item')
  Item() {

    if (this.$refs.formOrdemServico) {
      this.$refs.formOrdemServico.resetValidation();
    }

    if(this.$refs.formDialogServico){
      this.$refs.formDialogServico.resetValidation();
    }

    if(this.$refs.formDialogLocacao){
      this.$refs.formDialogLocacao.resetValidation();
    }

    if (this.item.id > 0){
      this.SelectedContrato();
      this.baseOrigem = this.item.baseId != undefined;

      if(this.item.motorista){
        this.motoristas.unshift(this.item.motorista);
      }

      if(this.item.veiculo){
        this.veiculos.unshift(this.item.veiculo);
      }

      this.WatchTransportador();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
    
  }

  @Watch("item.contratoId")
  WatchContrato(){
    if(this.item.contratoId > 0){
      this.contratoId = this.item.contratoId;
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("baseOrigem")
  Origem() {
    if (this.baseOrigem) {
      this.item.portoId = undefined;
      this.item.embarcacaoId = undefined;
    }
    else {
      this.item.baseId = undefined;
    }
  }

  @Watch('onSearchContrato')
  SearchContrato (val: string) {

    if (this.item.contratoId) return;
    if (this.isContratoLoading) return;
    if (!val) return;

    this.isContratoLoading = true
    this.contratoService.AutoComplete(val).then(
      res => {
        this.contratos = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isContratoLoading = false));
  }

  @Watch("item.transportadoraId")
  WatchTransportador(){
    if(this.item.transportadoraId){
      this.transportadoraService.ObterPorId(this.item.transportadoraId, "Servicos.Servico, Servicos.UnidadeMedida").then(
        res => {
          let transportadora = new Transportadora(res.data);
          this.servicosTransportadora = transportadora.servicos;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      )
    }
  }

  // @Watch('item.transportadoraId')
  GetVeiculoMotoristaByTransportadora(val: any){
    if(val > 0){
      this.GetVeiculoByTransportadora(val);
      this.GetMorotistaByTransportadora(val);
    }
  }

  GetVeiculoByTransportadora(transportadoraId?: number){
    if(transportadoraId){
      const veiculoService = new VeiculoService();
      veiculoService.VeiculoByTransportadora(transportadoraId).then(
        res => {
          this.veiculos = [];
          this.veiculos = res.data;
          if(this.veiculos.length > 0){
            this.veiculos.unshift({
              id: undefined,
              placa: ''
            });
          }
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );
    }
  }

  GetMorotistaByTransportadora(transportadoraId?: number){
    if(transportadoraId){
      const motoristaService = new MotoristaService();
      motoristaService.MotoristaByTransportadora(transportadoraId).then(
        res => {
          this.motoristas = [];
          this.motoristas = res.data;
          if(this.motoristas.length > 0){
            this.motoristas.unshift({
              id: undefined,
              cpfNome: ''
            });
          }         
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }          
      );
    }
  }

  mounted() {

    const portoService = new PortoService();
    portoService.ListarTudo()
    .then(
      res => {
        this.portos = res.data.items;
        this.portos.unshift({
          id: undefined,
          nome: ''
        });
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const situacaoOrdemServicoResiduoService = new SituacaoOrdemServicoResiduoService();
    situacaoOrdemServicoResiduoService.ListarTudo().then(
      res=>{
        this.situacaoResiduos = res.data.items;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const situacaoOrdemServicoService = new SituacaoOrdemServicoService();
    situacaoOrdemServicoService.ListarTudo().then(
      res=>{
        this.situacoes = res.data.items;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

    this.transportadoraService.ListarTudo().then(
      res=>{
        this.transportadoras = res.data.items;
        this.transportadoras.unshift({
            id: undefined,
            nome: ''
          });
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const empresaService = new EmpresaService()
    empresaService.GetConfiguracao(this.app.empresaId).then(
      res =>{
        this.configuracaoEmpresa = new EmpresaConfiguracao(res.data);
      },
      err=> this.$swal('Aviso', err.message, 'error')      
    )
  }

  SelectedContrato(){

    if(this.item.contratoId > 0){
      this.contratoService.ObterPorId(this.item.contratoId, 'Cliente.Embarcacoes, Cliente.Bases, Residuos.UnidadeMedida, Residuos.Residuo.Tecnologia, Residuos.Residuo.Grupo, Servicos.Servico').then(
        res => {
          const contrato = res.data;

          this.residuos = contrato.residuos;
          this.servicos = contrato.servicos;

          this.embarcacoes = [...contrato.cliente.embarcacoes];
          this.embarcacoes.unshift({
            id: undefined,
            nome: ''
          });

          this.bases = [...contrato.cliente.bases];
          this.bases.unshift({
            id: undefined,
            nome: ''
          });
          
        }
      );
    }

    //const contrato = this.item.id > 0 ? this.item.contrato : this.contratos.find(x => x.id == this.item.contratoId);
  }

  ReabrirResiduo(item: OrdemServicoResiduo){
    if(item.quantidade > 0 && item.processo1.mr01){
      item.situacaoId = 3;
    }
    else{
      item.situacaoId = 1;
    }
  }

  CancelarResiduo(item: OrdemServicoResiduo){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja CANCELAR a resíudo atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        item.situacaoId = EnumSituacaoOrdemServicoResiduo.Cancelado;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then(result => {
      if (result.value) {
        this.$swal("Aviso", result.value, "success");
      }
    });
  }

  AbrirDialogResiduo(ordemServicoresiduo?: OrdemServicoResiduo){
    
    if (ordemServicoresiduo){

      ordemServicoresiduo.dataColeta = ordemServicoresiduo.dataColeta.toDateYYYYMMDD();
      // Processo 1
      ordemServicoresiduo.processo1.dataMr01 = ordemServicoresiduo.processo1.dataMr01?.toDateYYYYMMDD();
      ordemServicoresiduo.processo1.dataCdf01 = ordemServicoresiduo.processo1.dataCdf01?.toDateYYYYMMDD();  
      ordemServicoresiduo.processo1.dataFcdr = ordemServicoresiduo.processo1.dataFcdr?.toDateYYYYMMDD();
      ordemServicoresiduo.processo1.dataCartaResponsabilidade = ordemServicoresiduo.processo1.dataCartaResponsabilidade?.toDateYYYYMMDD();
      ordemServicoresiduo.processo1.dataRelatorioObservacao = ordemServicoresiduo.processo1.dataRelatorioObservacao?.toDateYYYYMMDD();
      ordemServicoresiduo.processo1.dataTicketPesagem = ordemServicoresiduo.processo1.dataTicketPesagem?.toDateYYYYMMDD();
      ordemServicoresiduo.processo1.dataTecnologia = ordemServicoresiduo.processo1.dataTecnologia?.toDateYYYYMMDD();

      // Processo 2
      ordemServicoresiduo.processo2.dataMr02 = ordemServicoresiduo.processo2.dataMr02?.toDateYYYYMMDD();
      ordemServicoresiduo.processo2.dataCdf02 = ordemServicoresiduo.processo2.dataCdf02?.toDateYYYYMMDD();
      ordemServicoresiduo.processo2.dataRelatorioRecebimento = ordemServicoresiduo.processo2.dataRelatorioRecebimento?.toDateYYYYMMDD();

      // Processo 3
      ordemServicoresiduo.processo3.dataMr03 = ordemServicoresiduo.processo3.dataMr03?.toDateYYYYMMDD();
      ordemServicoresiduo.processo3.dataCdf03 = ordemServicoresiduo.processo3.dataCdf03?.toDateYYYYMMDD();
      ordemServicoresiduo.processo3.dataRelatorioRecebimento = ordemServicoresiduo.processo3.dataRelatorioRecebimento?.toDateYYYYMMDD();

      // Processo 4
      ordemServicoresiduo.processo4.dataMr04 = ordemServicoresiduo.processo4.dataMr04?.toDateYYYYMMDD();
      ordemServicoresiduo.processo4.dataCdf04 = ordemServicoresiduo.processo4.dataCdf04?.toDateYYYYMMDD();
      ordemServicoresiduo.processo4.dataRelatorioRecebimento = ordemServicoresiduo.processo4.dataRelatorioRecebimento?.toDateYYYYMMDD();

      this.ordemServicoresiduo = ordemServicoresiduo;

      this.editaResiduo = true;
    }
    else{
      this.ordemServicoresiduo = new OrdemServicoResiduo();
      this.editaResiduo = false;
    }
    
    this.dialogResiduo = true;
  }

  AbrirDialogServico(ordemServicoServico?: OrdemServicoServico){
    
    if (ordemServicoServico){
      this.ordemServicoServico = ordemServicoServico;
      this.editaServico = true;
    }
    else {
      this.ordemServicoServico = new OrdemServicoServico();
      this.editaServico = false;
    }

    this.dialogServico = true;
  }

  AbrirDialogLocacao(tipo: number, ordemServicoLocacao?: OrdemServicoLocacao){
    
    this.tipoLocacao = tipo;

    //EDITAR
    if(ordemServicoLocacao && tipo == TipoOperacaoLocacao.Editar){
      ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Ida;
      ordemServicoLocacao.dataInicio = ordemServicoLocacao.dataInicio.toDateYYYYMMDD();
      ordemServicoLocacao.dataFim = ordemServicoLocacao.dataFim ? ordemServicoLocacao.dataFim.toDateYYYYMMDD() : undefined;
      this.ordemServicoLocacao = ordemServicoLocacao;
      this.editaLocacao = true;
      this.dialogLocacao = true;
    }
    else if(ordemServicoLocacao && tipo == TipoOperacaoLocacao.Transbordo){
      this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja realizar o transbordo do registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Transbordo;
          new TipoOrdemServicoLocacaoService().ObterPorId(ordemServicoLocacao.tipoId).then(
            res => {
              ordemServicoLocacao.tipo = res.data;
              return true;
            }
          )
          ordemServicoLocacao.dataFim = ordemServicoLocacao.dataInicio;
        },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    }
    //TROCA INTERNA
    else if(ordemServicoLocacao && tipo == TipoOperacaoLocacao.TrocaInterna){
      this.ordemServicoLocacao = new OrdemServicoLocacao();
      this.ordemServicoLocacao.ordemServicoId = this.item.id;
      this.ordemServicoLocacao.parentId = ordemServicoLocacao.id;
      this.ordemServicoLocacao.dataInicio = this.item.data;
      this.ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Ida;
      this.editaLocacao = false;
      this.dialogLocacao = true;
    }
    //TROCA EXTERNA
    else if(!ordemServicoLocacao && tipo == TipoOperacaoLocacao.TrocaExterna){
      this.ordemServicoLocacao = new OrdemServicoLocacao();
      this.ordemServicoLocacao.ordemServicoId = this.item.id;
      this.ordemServicoLocacao.dataInicio = this.item.data;
      this.ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Troca;
      this.editaLocacao = false;
      this.dialogLocacao = true;
    }
    //RETIRAR
    else if(!ordemServicoLocacao && tipo == TipoOperacaoLocacao.Retirar){
      this.ordemServicoLocacao = new OrdemServicoLocacao();
      this.ordemServicoLocacao.ordemServicoId = this.item.id;
      this.ordemServicoLocacao.dataInicio = this.item.data;
      this.ordemServicoLocacao.dataFim = new Date().toYYYYMMDD();
      this.ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Volta;
      this.editaLocacao = false;
      this.dialogLocacao = true;
    }    
    //NOVO
    else{
      this.ordemServicoLocacao = new OrdemServicoLocacao();
      this.ordemServicoLocacao.ordemServicoId = this.item.id;
      this.ordemServicoLocacao.dataInicio = this.item.data;
      this.ordemServicoLocacao.tipoId = EnumTipoOrdemServicoLocacao.Ida;
      this.editaLocacao = false;
      this.dialogLocacao = true;
    }
  }  

  AbrirDialogFoto(ordemServicoFoto?: OrdemServicoFoto){
    if(ordemServicoFoto){
      this.ordemServicoFoto = ordemServicoFoto;
      this.editaFoto = true;
    }
    else{
      this.ordemServicoFoto = new OrdemServicoFoto();
      this.ordemServicoFoto.ordemServicoId = this.item.id;
      this.editaFoto = false;
    }
    this.dialogFoto = true;
  }

  SalvarResiduo(){

    //SituacaoResiduoAutomatica
    if(this.configuracaoEmpresa.situacaoAutomaticaOSResiduo){
      if(this.ordemServicoresiduo.quantidade > 0 && this.ordemServicoresiduo.processo1.mr01){
        this.ordemServicoresiduo.situacaoId = 3;
      }
      else{
        this.ordemServicoresiduo.situacaoId = 1;
      }
    }

    // const situacao = this.situacaoResiduos.find(x => x.id == this.ordemServicoresiduo.situacaoId);
    // this.ordemServicoresiduo.situacao = situacao;
    
    if (this.editaResiduo == false){
      this.item.residuos.push(this.ordemServicoresiduo);        
    }

    this.dialogResiduo = false;
  }

  SalvarServico(){

    if (this.$refs.formDialogServico.validate()) {

      const contratoServico = this.servicos.find(x=> x.servicoId == this.ordemServicoServico.servicoId);
      this.ordemServicoServico.servico = contratoServico!.servico;
      this.ordemServicoServico.valor = contratoServico!.preco;
      
      if(this.editaServico == false) {
        this.item.servicos.push(this.ordemServicoServico);
      }
      this.dialogServico = false;
    }
  }

  AdicionarServicoTransportadora(){
    if(this.$refs.formServicoTransportadora.validate()){
      if(!this.item.servicosTransportadora.find(x => x.servicoId == this.transportadoraServico.servicoId && x.unidadeMedidaId == this.transportadoraServico.unidadeMedidaId)){
        this.ordemServicoServicoTransportadora.ordemServicoId = this.item.id;
        this.ordemServicoServicoTransportadora.servicoId = this.transportadoraServico.servicoId;
        this.ordemServicoServicoTransportadora.servico = this.transportadoraServico.servico;
        this.ordemServicoServicoTransportadora.unidadeMedidaId = this.transportadoraServico.unidadeMedidaId;
        this.ordemServicoServicoTransportadora.unidadeMedida = this.transportadoraServico.unidadeMedida;
        this.ordemServicoServicoTransportadora.valor = this.transportadoraServico.valor;
        this.item.servicosTransportadora.push(this.ordemServicoServicoTransportadora);
        this.ordemServicoServicoTransportadora = new OrdemServicoServicoTransportadora();
        this.transportadoraServico = new TransportadoraServico();
      }else {
        this.$swal("Aviso", "Item já adicionado!", "warning");
      }
    }
  }

  SalvarLocacao(){
      
    if(!this.editaLocacao){
      this.item.locacoes.push(this.ordemServicoLocacao);
    }

    var teste = this.item.locacoes.find(x => x.id == this.ordemServicoLocacao.parentId);
    
    if(teste != null){
      teste.equipamentoTrocaId = this.ordemServicoLocacao.equipamentoId;
      teste.equipamentoTroca = this.ordemServicoLocacao.equipamento;
      teste.dataFim = this.ordemServicoLocacao.dataInicio;
    }

    this.editaLocacao = false;
    this.dialogLocacao = false;
  }

  SalvarFoto(){    

    if(this.editaFoto == false){
      this.item.fotos.push(this.ordemServicoFoto);
    }

    this.dialog = false;
    this.dialog = true;

    this.dialogFoto = false;
  }

  ExcluirResiduo(ordemServicoresiduo: OrdemServicoResiduo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemServicoresiduo.excluido = true;
        const index = this.item.residuos.indexOf(ordemServicoresiduo);
        delete this.item.residuos[index];

        // const index = this.item.residuos.indexOf(ordemServicoresiduo);
        // this.item.residuos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }
  
  ExcluirServico(ordemServicoServico: OrdemServicoServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemServicoServico.excluido = true;
        const index = this.item.servicos.indexOf(ordemServicoServico);
        delete this.item.servicos[index];

        // const index = this.item.servicos.indexOf(ordemServicoServico);
        // this.item.servicos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  ExcluirServicoTransportadora(ordemServicoServicoTransportadora: OrdemServicoServicoTransportadora){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {          
        ordemServicoServicoTransportadora.excluido = true;
        const index = this.item.servicosTransportadora.indexOf(ordemServicoServicoTransportadora);
        delete this.item.servicosTransportadora[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })     
  }

  ExcluirLocacao(ordemServicoLocacao: OrdemServicoLocacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemServicoLocacao.excluido = true;
        const index = this.item.locacoes.indexOf(ordemServicoLocacao);
        delete this.item.locacoes[index];

        // const index = this.item.equipamentos.indexOf(ordemServicoEquipamento);
        // this.item.equipamentos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
  }

  ExcluirFoto(ordermServicoFoto: OrdemServicoFoto){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordermServicoFoto.excluido = true;
        const index = this.item.fotos.indexOf(ordermServicoFoto);
        delete this.item.fotos[index];

        // const index = this.item.fotos.indexOf(ordermServicoFoto);
        // this.item.fotos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  Salvar() {
    if (this.$refs.formOrdemServico.validate()) {

      this.save = true;

      // let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          this.$swal("Aviso", err.response.data, err.status >= 500 ? "error" : "warning");
        }
      ).finally(() =>{
        this.save = false;
      })
    }
  }

  Download(item: OrdemServicoResiduo, tipo: string) {
    this.service.Download(item.ordemServicoId, item.id, tipo).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        switch (tipo) {
          case 'FCDR':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.fcdr!.nome);
            break;
          case 'MR01':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr01!.nome);
            break;
          case 'CDF01':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf01!.nome);
            break;
          case 'CartaResponsabilidade':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cartaResponsabilidade!.nome);
            break;
          case 'TicketPesagem':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.ticketPesagem!.nome);
            break;
          case 'RelatorioObservacao':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioObservacao!.nome);
            break;
          case 'MR02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr02!.nome);
            break;
          case 'CDF02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf02!.nome);
            break;
          case 'RelatorioRecebimento2':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento2!.nome);
            break;
          case 'MR03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr03!.nome);
            break;
          case 'CDF03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf03!.nome);
            break;
          case 'RelatorioRecebimento3':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento3!.nome);
            break;
          case 'MR04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr04!.nome);
            break;
          case 'CDF04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf04!.nome);
            break;
          case 'RelatorioRecebimento4':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento4!.nome);
            break;
          default:
            console.warn(`Não existe esse tipo: ${tipo}.`);
        }

      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }

  Close() {
    this.equipamentos = [];
    this.dialog = false;
  }

  ExibeActionsResiduo(item: OrdemServicoResiduo) : boolean{
    return item.situacaoId != EnumSituacaoOrdemServicoResiduo.Cancelado;
  }

  AtualizarValorResiduo(item: OrdemServicoResiduo){
    this.service.AtualizarValorResiduo(item).then(
      res=>{
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
      },
      err=>{
        this.$swal("Aviso", err.response.data, err.status >= 500 ? "error" : "warning");
      }
    )
  }

  AtualizarValorServico(item: OrdemServicoServico){
    this.service.AtualizarValorServico(item).then(
      res=>{
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
      },
      err=>{
        this.$swal("Aviso", err.response.data, err.status >= 500 ? "error" : "warning");
      }
    )
  }
}
