import { DocumentoGrupo, Empresa, NotificacaoDocumento, TipoDocumento } from ".";
import { Arquivo } from "../shared";

export class Documento implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    parentId?: number;
    parent!: Documento;
    tipoId: number = 0;
    tipo!: TipoDocumento;
    identificacao: string = '';
    descricao: string = '';
    protocoloValidade: string = '';
    dataValidade?: string;
    todos: boolean = true;
    pcp: boolean = false;
    arquivo?: Arquivo;
    link?: string;
    grupos: DocumentoGrupo[] = [];    
    notificacao: NotificacaoDocumento = new NotificacaoDocumento();

    itens: Documento[] = [];

    constructor(model?: Documento){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.parentId = model.parentId;
        this.tipoId = model.tipoId;
        this.identificacao = model.identificacao;
        this.descricao = model.descricao;
        this.protocoloValidade = model.protocoloValidade;
        this.dataValidade = model.dataValidade? model.dataValidade.toDateYYYYMMDD() : '';
        this.todos = model.todos;
        this.pcp = model.pcp;
        this.arquivo = model.arquivo;
        this.link = model.link;
        this.grupos = model.grupos;
        this.notificacao = model.notificacao;

        this.itens = model.itens;
    }
    
}