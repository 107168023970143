




























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { Armazenamento } from "@/core/models/residuo";
import { ArmazenamentoService } from "@/core/services/residuo";


@Component
export default class CadastroArmazenamento extends Vue{
    @Prop() private item!:Armazenamento;
    @Prop() private value!: string;

    itemOriginal!: Armazenamento ;
    service = new ArmazenamentoService();

    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement
    }

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;

        if (this.dialog){
        this.itemOriginal = jiff.clone(this.item);
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
        this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Salvar (){
        if (this.$refs.form.validate()) {

            let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
            .then(
                res => {
                     this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    if (err.response.status == 400) {
                        this.$swal("Aviso", err.response.data.title, "warning");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            );
        }
    }

    Close() {
        this.dialog = false;
    }
}
