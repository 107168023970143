













































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';

import { PageBase } from '@/core/models/shared';
import { ArquivoService } from '@/core/services/shared';
import { EmpresaConfiguracao } from "@/core/models/geral";
import { OrdemServico, OrdemServicoFoto, OrdemServicoServico } from "@/core/models/interna";
import { OrdemServicoService } from "@/core/services/interna";
import { ContratoService, PortoService, SituacaoOrdemServicoService, TransportadoraService } from "@/core/services/residuo";
import { Contrato, ContratoServico } from "@/core/models/residuo";
import { EmpresaService, MotoristaService, VeiculoService } from "@/core/services/geral";

@Component
export default class CadastroOrdemServico extends PageBase {
  @Prop() private item!: OrdemServico;
  @Prop() private value!: string;

  save: boolean = false;

  service = new OrdemServicoService();
  arquivoService = new ArquivoService();
  contratoService = new ContratoService();

  valid = true;
  validDialogServico = true;

  dialog = false;
  dialogServico = false;
  dialogFoto = false;

  editaServico = false;
  editaFoto = false;

  isContratoLoading = false;
  isEquipamentoLoading = false;  

  onSearchContrato: any = null;

  ordemServicoServico: OrdemServicoServico = new OrdemServicoServico();
  ordemServicoFoto: OrdemServicoFoto = new OrdemServicoFoto();

  servicos: ContratoServico[] = [];
  embarcacoes: any[] = [];
  bases: any[] = [];
  contratos: Contrato[] = [];
  portos: any[] = [];
  transportadoras: any[] = [];
  veiculos: any[] = [];
  situacoes: any[] = [];
  motoristas: any[] = [];

  tabActive: any = {};
  baseOrigem: boolean = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  arquivo: any  = {
    mr01: null,
    mr01Chip: true,
    cdf01: null,
    cdf01Chip: true,
    cartaResponsabilidade: null,
    cartaResponsabilidadeChip: true,
    ticketPesagem: null,
    ticketPesagemChip: true,
    fcdr: null,
    fcdrChip: true,
    mr02: null,
    mr02Chip: true,
    cdf02: null,
    cdf02Chip: true
  }

  $refs!: {
    formOrdemServico: HTMLFormElement,
    formDialogServico: HTMLFormElement,
    formDialogLocacao: HTMLFormElement
  }

  observer! : jsonpatch.Observer<OrdemServico>;

  headerServico: any[] = [
    { text: '',value:'actions', sortable: false },
    { text: 'Serviço', value: 'servico.codigoNome' },
    { text: 'Quantidade', value: 'quantidade' },
  ];

  headerFoto: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Foto', value: 'dados' },
    { text: 'Nome', value: 'nome' },
    { text: 'Descrição', value: 'descricao' }
  ];

  configuracaoEmpresa: EmpresaConfiguracao = new EmpresaConfiguracao()

  itemExcluido(item: any){
    return item.excluido ? 'itemExcluido' : '';
  }
  
  beforeUpdate() {
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('item')
  Item() {

    if (this.$refs.formOrdemServico) {
      this.$refs.formOrdemServico.resetValidation();
    }

    if(this.$refs.formDialogServico){
      this.$refs.formDialogServico.resetValidation();
    }

    if (this.item.id > 0){
      this.SelectedContrato();
      this.baseOrigem = this.item.baseId != undefined;
      
      if(this.item.motorista){
        this.motoristas.unshift(this.item.motorista);
      }

      if(this.item.veiculo){
        this.veiculos.unshift(this.item.veiculo);
      }
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("baseOrigem")
  Origem() {
    if (this.baseOrigem) {
      this.item.portoId = undefined;
      this.item.embarcacaoId = undefined;
    }
    else {
      this.item.baseId = undefined;
    }
  }

  @Watch('onSearchContrato')
  SearchContrato (val: string) {

    if (this.item.contratoId) return;
    if (this.isContratoLoading) return;
    if (!val) return;

    this.isContratoLoading = true
    this.contratoService.AutoComplete(val).then(
      res => {
        this.contratos = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isContratoLoading = false));
  }

  @Watch('item.transportadoraId')
  GetVeiculoMotoristaByTransportadora(val: any){
    if(val > 0){
      
      const veiculoService = new VeiculoService();
      veiculoService.VeiculoByTransportadora(val)
      .then(
        res => {
          this.veiculos = [];
          this.veiculos = res.data;
          if(this.veiculos.length > 0){
            this.veiculos.unshift({
              id: undefined,
              placa: ''
            });
          }          
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );      
      
      const motoristaService = new MotoristaService();
      motoristaService.MotoristaByTransportadora(val)
      .then(
        res => {
          this.motoristas = [];
          this.motoristas = res.data;
          if(this.motoristas.length > 0){
            this.motoristas.unshift({
              id: undefined,
              cpfNome: ''
            });
          }         
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );
      
    }
  }

  mounted() {

    const portoService = new PortoService();
    portoService.ListarTudo()
    .then(
      res => {
        this.portos = res.data.items;
        this.portos.unshift({
          id: undefined,
          nome: ''
        });
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const situacaoOrdemServicoService = new SituacaoOrdemServicoService();
    situacaoOrdemServicoService.ListarTudo()
    .then(
      res=>{
        this.situacoes = res.data.items;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const transportadoraService = new TransportadoraService();
    transportadoraService.ListarTudo()
    .then(
      res=>{
        this.transportadoras = res.data.items;
        this.transportadoras.unshift({
            id: undefined,
            nome: ''
          });
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    );

    const empresaService = new EmpresaService()
    empresaService.GetConfiguracao(this.app.empresaId).then(
      res =>{
        this.configuracaoEmpresa = new EmpresaConfiguracao(res.data);
      },
      err=> this.$swal('Aviso', err.message, 'error')      
    )
  }

  SelectedContrato(){
  
    this.contratoService.ObterPorId(this.item.contratoId, 'Cliente.Embarcacoes, Cliente.Bases, Residuos.UnidadeMedida, Residuos.Residuo, Servicos.Servico').then(
      res => {
        const contrato = res.data;

        this.servicos = contrato.servicos;

        this.embarcacoes = [...contrato.cliente.embarcacoes];
        this.embarcacoes.unshift({
          id: undefined,
          nome: ''
        });

        this.bases = [...contrato.cliente.bases];
        this.bases.unshift({
          id: undefined,
          nome: ''
        });
        
      }
    );

    //const contrato = this.item.id > 0 ? this.item.contrato : this.contratos.find(x => x.id == this.item.contratoId);
  }

  AbrirDialogServico(ordemServicoServico?: OrdemServicoServico){
    
    if (ordemServicoServico){
      this.ordemServicoServico = ordemServicoServico;
      this.editaServico = true;
    }
    else {
      this.ordemServicoServico = new OrdemServicoServico();
      this.editaServico = false;
    }

    this.dialogServico = true;
  } 

  AbrirDialogFoto(ordemServicoFoto?: OrdemServicoFoto){
    if(ordemServicoFoto){
      this.ordemServicoFoto = ordemServicoFoto;
      this.editaFoto = true;
    }
    else{
      this.ordemServicoFoto = new OrdemServicoFoto();
      this.ordemServicoFoto.ordemServicoId = this.item.id;
      this.editaFoto = false;
    }
    this.dialogFoto = true;
  }

  SalvarServico(){

    if (this.$refs.formDialogServico.validate()) {

      const contratoServico = this.servicos.find(x=> x.servicoId == this.ordemServicoServico.servicoId);
      this.ordemServicoServico.servico = contratoServico!.servico;
      this.ordemServicoServico.valor = contratoServico!.preco;
      
      if(this.editaServico == false) {
        this.item.servicos.push(this.ordemServicoServico);
      }
      this.dialogServico = false;
    }
  }

  SalvarFoto(){    

    if(this.editaFoto == false){
      this.item.fotos.push(this.ordemServicoFoto);
    }

    this.dialog = false;
    this.dialog = true;

    this.dialogFoto = false;
  }
  
  ExcluirServico(ordemServicoServico: OrdemServicoServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemServicoServico.excluido = true;
        const index = this.item.servicos.indexOf(ordemServicoServico);
        delete this.item.servicos[index];

        // const index = this.item.servicos.indexOf(ordemServicoServico);
        // this.item.servicos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  ExcluirFoto(ordermServicoFoto: OrdemServicoFoto){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordermServicoFoto.excluido = true;
        const index = this.item.fotos.indexOf(ordermServicoFoto);
        delete this.item.fotos[index];

        // const index = this.item.fotos.indexOf(ordermServicoFoto);
        // this.item.fotos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", "Resíduo removido com sucesso!", "success");
          }
      })
  }

  Salvar() {
    if (this.$refs.formOrdemServico.validate()) {

      this.save = true;

      // let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          this.$swal("Aviso", err.response.data, err.status >= 500 ? "error" : "warning");
        }
      ).finally(() =>{
        this.save = false;
      })
    }
  }

  Close() {
    this.dialog = false;
  }

  AtualizarValorServico(item: OrdemServicoServico){
    this.service.AtualizarValorServico(item).then(
      res=>{
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
      },
      err=>{
        this.$swal("Aviso", err.response.data, err.status >= 500 ? "error" : "warning");
      }
    )
  }
}
