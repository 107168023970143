import { Service } from '../shared';
import {http, httpHeader} from '../../ApiConfig'

export class LocatarioService extends Service{

  constructor(){
    super('locatario');
  }

  public VincularEquipamento(id: number, equipamentoId: number, vincular: boolean){
    return http.post(`${this.GetNomeControle()}/${id}/VincularEquipamento/${equipamentoId}`, undefined, {
      params: {        
        vincular
      },
      headers: httpHeader.headers
    });
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

}