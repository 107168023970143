





































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { EquipamentoService, GrupoEquipamentoService } from "@/core/services/residuo";
import { Equipamento, GrupoEquipamento } from "@/core/models/residuo";
import { Locatario } from "@/core/models/geral";
import { LocatarioService } from "@/core/services/geral";

@Component
export default class CadastroEquipamento extends Vue {
  @Prop() private item!: Equipamento;
  @Prop() private value!: string;

  itemOriginal!: Equipamento;

  service = new EquipamentoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  grupos: GrupoEquipamento[] = [];
  $refs!: {
    formEquipamento: HTMLFormElement
  }

  locatarios: Locatario[] = [];
  locatarioService: LocatarioService = new LocatarioService();
  onSearchLocatario: any = null;
  isLocatarioLoading: boolean = false;

  @Watch('item')
  Item() {
    if (this.$refs.formEquipamento) {
      this.$refs.formEquipamento.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      if(this.item.locatarioId){
        this.locatarios.push(this.item.locatario);
      }
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('onSearchLocatario')
  searchCliente (val: string) {
    if(this.item.locatarioId) return;
    if (this.isLocatarioLoading) return;
    if (!val) return;

    this.isLocatarioLoading = true
    this.locatarioService.AutoComplete(val).then(
      res => {
        this.locatarios = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isLocatarioLoading = false;
    })
  }

  mounted() {
    const grupoEquipamentoService = new GrupoEquipamentoService();
    grupoEquipamentoService.ListarTudo()
      .then(
        res=>{
          this.grupos = res.data.items;
        },
        err =>{
          this.$swal('Aviso', err.message, 'error')
        }
      )
  }

  Salvar() {
    if (this.$refs.formEquipamento.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
