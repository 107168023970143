import { OrdemServico } from '.';
import { Cliente } from './../geral/Cliente';
import { MedicaoOrdemServico } from './MedicaoOrdemServico';
import { MedicaoOrdemServicoLocacao } from './MedicaoOrdemServicoLocacao';
export class Medicao implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    clienteId: number = 0;
    cliente!: Cliente;
    competenciaMes?: number;
    competenciaAno?: number;
    impostoPercentual: number = 0;
    valorLocacao: number = 0;
    descontoLocacao: number = 0;
    acrescimoLocacao: number = 0;
    totalLocacao: number = 0;
    imposto: number = 0;
    data: string = new Date().toYYYYMMDD();
    ordemServicos: MedicaoOrdemServico[] = [];
    locacoes: MedicaoOrdemServicoLocacao[] = [];
    observacao: string = "";
    total: number = 0;

    constructor(model?: Medicao){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.competenciaMes = model.competenciaMes;
        this.competenciaAno = model.competenciaAno;
        this.impostoPercentual = model.impostoPercentual;
        this.valorLocacao = model.valorLocacao;
        this.descontoLocacao = model.descontoLocacao;
        this.acrescimoLocacao = model.acrescimoLocacao;
        this.totalLocacao = model.totalLocacao;
        this.imposto = model.imposto;
        this.data = model.data.toDateYYYYMMDD();
        this.ordemServicos = model.ordemServicos;
        this.locacoes = model.locacoes;
        this.observacao = model.observacao;
        this.total = model.total;
    }

}