







































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { MedicaoService, OrdemServicoService } from "@/core/services/residuo";
import { Medicao, MedicaoOrdemServico, MedicaoOrdemServicoLocacao, OrdemServico } from "@/core/models/residuo";
import { ClienteService } from '@/core/services/geral';
import { OrdemServicoLocacaoModel } from '@/core/models/model/OrdemServicoLocacaoModel';
import { ModelCalculoLocacao } from '@/core/models/model/ModelCalculoLocacao'
import { EnumSituacaoOrdemServico } from "@/core/models/residuo/Enumerados";

@Component
export default class CadastroMedicao extends Vue {
  @Prop() private item!: Medicao;
  @Prop() private value!: string;

  itemOriginal!: Medicao;

  service = new MedicaoService();
  cliente = new ClienteService();
  ordemServicoService = new OrdemServicoService();

  valid = true;
  dialog = false;
  dialogSelecionarOS = false;
  tabActive: any = {};

  selectOrdemServicos: OrdemServico[] = [];

  clientes: any[] = [];
  isClienteLoading = false;
  onSearchCliente: any = null;
  headerOS: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "OS #", value: "ordemServico.numero" },
    { text: "Contrato", value: "ordemServico.contrato.numeroDescricao" },
    { text: "Data", value: "ordemServico.data" },
    { text: "Porto", value: "ordemServico.porto.nome" },
    { text: "Embarcação", value: "ordemServico.embarcacao.nome" },
    { text: "Base", value: "ordemServico.base.nome" },  
    { text: "Resíduo", value: "ordemServico.totalResiduo" },
    { text: "Serviço", value: "ordemServico.totalServico" },
    { text: "Acrescimo", value: "ordemServico.acrescimo" },
    { text: "Desconto", value: "ordemServico.decrescimo" },
    { text: "Total", value: "ordemServico.totalGeral" }
  ];

  locacaoItem: OrdemServicoLocacaoModel = new OrdemServicoLocacaoModel();
  headerLocacao: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "OS #", value: "ordemServicoLocacao.ordemServico.numero" },
    { text: "Origem", value: "ordemServicoLocacao.parent.equipamento.nome" },
    { text: "Equipamento", value: "ordemServicoLocacao.equipamento.nome" },
    { text: "Movimento", value: "ordemServicoLocacao.tipo.nome" },
    { text: "Inicio", value: "ordemServicoLocacao.dataInicio" },
    { text: "Término", value: "ordemServicoLocacao.dataFim" },
    { text: "Inicio (Cobrança)", value: "ordemServicoLocacao.dataHoraInicioCobranca" },
    { text: "Término (Cobrança)", value: "ordemServicoLocacao.dataHoraTerminoCobranca" },
    { text: "Dias", value: "ordemServicoLocacao.dias" },
    { text: "Valor", value: "ordemServicoLocacao.valorCalculado" },
  ];

  meses: any[] = [
    { valor: 1, texto: "Janeiro" },
    { valor: 2, texto: "Fevereiro" },
    { valor: 3, texto: "Março" },
    { valor: 4, texto: "Abril" },
    { valor: 5, texto: "Maio" },
    { valor: 6, texto: "Junho" },
    { valor: 7, texto: "Julho" },
    { valor: 8, texto: "Agosto" },
    { valor: 9, texto: "Setembro" },
    { valor: 10, texto: "Outubro" },
    { valor: 11, texto: "Novembro" },
    { valor: 12, texto: "Dezembro" }
  ]

  locacaoSemaforo: boolean = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formMedicao: HTMLFormElement
  }

  imposto: number = 0;
  impostoInicial: number = 0;
  total: number = 0;
  totalInicial: number = 0;

  totalLocacoes: number = 0;

  itemStates(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else if(item.atualizado && !item.excluido)
      return 'itemAlterado'
    else
      return ''
  }

  @Watch('item')
  Item() {
    if (this.$refs.formMedicao) {
      this.$refs.formMedicao.resetValidation();
    }

    this.impostoInicial = this.item.imposto;
    this.totalInicial = this.item.total;
  }

  @Watch('item.valorLocacao')
  @Watch('item.descontoLocacao')
  @Watch('item.acrescimoLocacao')
  WatchValorLocacao(){
    this.totalLocacoes = this.item.valorLocacao + this.item.acrescimoLocacao - this.item.descontoLocacao;    
  }

  @Watch('totalLocacoes')
  @Watch("item.impostoPercentual")
  @Watch("item.total")
  Imposto() {
    this.total = 0;
    this.imposto = 0;
    this.item.ordemServicos.forEach(x => {
      this.total += x.ordemServico.total;
    });
    this.total += this.totalLocacoes;
    this.imposto = this.total * (this.item.impostoPercentual / 100);
    this.total = this.total + this.imposto;
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('onSearchCliente')
  SearchCliente (val: string) {

    if (this.isClienteLoading) return;
    if (!val) return;

    this.isClienteLoading = true
    this.cliente.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isClienteLoading = false));
  }

  @Watch('item.locacoes')
  @Watch('item.competenciaMes')
  @Watch('item.competenciaAno')
  WatchForCalc(){
    setTimeout(() => {
      if(this.item.locacoes.length > 0 && this.item.competenciaAno?.toString().length == 4 && this.item.competenciaMes && !this.locacaoSemaforo){
      
        var locacaoIds: number[] = [];
        
        this.item.locacoes.forEach(x => {
          locacaoIds.push(x.ordemServicoLocacaoId);
        })
        this.locacaoSemaforo = true;

        var model = new ModelCalculoLocacao();
        model.competenciaMes = this.item.competenciaMes
        model.competenciaAno = this.item.competenciaAno
        model.locacaoIds = locacaoIds;

        this.service.CalcularLocacao(model).then(
          res=>{
            this.locacaoItem = res.data;
            this.item.valorLocacao = this.locacaoItem.total;
          },
          err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => {
          this.locacaoSemaforo = false;
          this.dialog = false;
          this.dialog = true;
        })

      }
      else{
        return;
      }
    }, 200);
  }

  @Watch("locacaoItem")
  WatchLocacaoItem(){
    this.locacaoItem.itens.forEach(x => {
      var item = this.item.locacoes.find(y => y.ordemServicoLocacaoId == x.equipamento.id)!;
      item.ordemServicoLocacao['dataHoraInicioCobranca'] = x.equipamento.dataHoraInicioCobranca;
      item.ordemServicoLocacao['dataHoraTerminoCobranca'] = x.equipamento.dataHoraTerminoCobranca;
      item.ordemServicoLocacao['dias'] = x.equipamento.totalDias;
      item.ordemServicoLocacao['valorCalculado'] = x.subTotal;
    })
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  AtualizarOSValores(item: MedicaoOrdemServico){
    this.ordemServicoService.AtualizarValores(item).then(
      res =>{
        item.atualizado = true;
        this.dialog = false;
        this.dialog = true;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
  }

  SelectedOrdemServico(){
    this.selectOrdemServicos.forEach(x => {
      
      this.ordemServicoService.ObterPorId(x.id, "Contrato, Porto, Embarcacao, Base, Residuos.Residuo, Servicos.Servico, Locacoes.Equipamento, Locacoes.Parent.Equipamento, Locacoes.Tipo").then(
        res => {
          var ordemServico = res.data;

          //OS
          var medicaoOS = new MedicaoOrdemServico();
          medicaoOS.medicaoId = this.item.id;
          medicaoOS.ordemServicoId = ordemServico.id;
          medicaoOS.ordemServico = ordemServico;

          if(new Date(ordemServico.data).getMonth() + 1 == this.item.competenciaMes && (ordemServico.situacaoId != EnumSituacaoOrdemServico.Faturado || ordemServico.situacaoId != EnumSituacaoOrdemServico.FaturadoParcialmente))
          {
            if(!this.item.ordemServicos.find(x => x.medicaoId == medicaoOS.medicaoId && x.ordemServicoId == medicaoOS.ordemServicoId))
              this.item.ordemServicos.push(medicaoOS);
          }          

          ordemServico.locacoes.forEach(y => {
            //LOCACAO
            var medicaoLocacao = new MedicaoOrdemServicoLocacao();
            medicaoLocacao.medicaoId = this.item.id;
            medicaoLocacao.ordemServicoLocacaoId = y.id;
            medicaoLocacao.ordemServicoLocacao = y;
            medicaoLocacao.ordemServicoLocacao.ordemServico = x;

            if(new Date(y.dataInicio).getMonth() + 1 == this.item.competenciaMes){
              if(!this.item.locacoes.find(x => x.medicaoId == medicaoLocacao.medicaoId && x.ordemServicoLocacaoId == medicaoLocacao.ordemServicoLocacaoId))
                this.item.locacoes.push(medicaoLocacao);
            }
            else if(!y.dataFim){
              if(!this.item.locacoes.find(x => x.medicaoId == medicaoLocacao.medicaoId && x.ordemServicoLocacaoId == medicaoLocacao.ordemServicoLocacaoId))
                this.item.locacoes.push(medicaoLocacao);
            }            
              
          });
          
        }
      )

    });
  }

  ExcluirOrdemServico(medicaoOrdemServico: MedicaoOrdemServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {          
        medicaoOrdemServico.excluido = true;
        this.dialog = false;
        this.dialog = true;        
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result) => {
      if(result.value) {
        this.$swal("Aviso", "Ordem de Serviço removido com sucesso!", "success");
      }
    })
  }

  ExcluirOrdemServicoLocacao(medicaoOrdemServicoLocacao: MedicaoOrdemServicoLocacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {          
        medicaoOrdemServicoLocacao.excluido = true;
        this.dialog = false;
        this.dialog = true;        
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result) => {
      if(result.value) {
        this.$swal("Aviso", "Ordem de Serviço removido com sucesso!", "success");
      }
    })
  }
  
  Salvar() {
    if (this.$refs.formMedicao.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);
    
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          var id = 0;
          this.item.id > 0 ? id = this.item.id : res.data.id > 0 ? id = res.data.id : '';

          if (id > 0){
            if(this.item.ordemServicos.length > 0){

              this.item.ordemServicos.forEach(item => {
                item.ordemServico.acrescimo = item.ordemServico.acrescimo.toString().length == 0  ? 0 : item.ordemServico.acrescimo
                item.ordemServico.decrescimo = item.ordemServico.decrescimo.toString().length == 0 ? 0 : item.ordemServico.decrescimo
                
                this.service.VincularOrdemServico(id, item.ordemServicoId, !item.excluido, item.ordemServico.acrescimo, item.ordemServico.decrescimo).then(
                  res => {
                    this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                  }
                );

              });
            }
            else{
              this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
              this.$emit("salvou");
              this.Close();
            }

            if(this.item.locacoes.length > 0){
              this.item.locacoes.forEach(item => {
                
                this.service.VincularOrdemServicoLocacao(id, item.ordemServicoLocacaoId, !item.excluido).then(
                  res => {
                    this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                  }
                );

              })
            }
            else{
              this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
              this.$emit("salvou");
              this.Close();
            }
          }
          else {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          }
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
