import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class TecnologiaService extends Service{

  constructor(){
    super('tecnologia');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
  
}