import { UnidadeMedida } from '../geral';
import { Contrato, Residuo } from './';

export class ContratoResiduo implements Shared.IEntity{
    
    id: number = 0;
    contratoId: number = 0;
    cantrato!: Contrato;
    residuoId: number = 0;
    residuo!: Residuo;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    preco: number = 0;
    excluido: boolean = false;


    constructor(model?: ContratoResiduo){

        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.residuoId = model.residuoId;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.preco = model.preco;
    }

}