var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',[( _vm.app.clienteId == null && _vm.app.transportadoraId == null)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{attrs:{"color":"widget"}},[_c('v-card-title',[_c('span',{staticClass:"title font-weight-light"},[_vm._v("Documentos vencidos")]),_c('v-spacer'),_c('v-icon',{attrs:{"large":"","right":""}},[_vm._v(" mdi-file-document-multiple ")])],1),_c('v-data-table',{attrs:{"loading":_vm.loadingDocumentosVencer,"headers":_vm.documentosVencidosHeader,"items":_vm.documentosVencidos,"options":_vm.documentosVencidosOpt,"server-items-length":_vm.documentosVencidosQtd,"hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.documentosVencidosOpt=$event}},scopedSlots:_vm._u([{key:"item.identificacao",fn:function(ref){
var item = ref.item;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Arquivista'|| _vm.app.role == 'ResiduoArquivista')?_c('span',{staticClass:"text-left",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.AbrirDialogDocumento(item)}}},[_vm._v(" "+_vm._s(item.identificacao)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.identificacao)+" ")])]}},{key:"item.dataValidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataValidade? item.dataValidade.toDateDDMMYYYY() : '')+" ")]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{attrs:{"color":"widget"}},[_c('v-card-title',[_c('span',{staticClass:"title font-weight-light"},[_vm._v("Documentos à vencer")]),_c('v-spacer'),_c('v-icon',{attrs:{"large":"","right":""}},[_vm._v(" mdi-file-document-multiple-outline ")])],1),_c('v-data-table',{attrs:{"loading":_vm.loadingDocumentosVencer,"headers":_vm.documentosVencerHeader,"items":_vm.documentosVencer,"options":_vm.documentosVencerOpt,"server-items-length":_vm.documentosVencerQtd,"hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.documentosVencerOpt=$event}},scopedSlots:_vm._u([{key:"item.identificacao",fn:function(ref){
var item = ref.item;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Arquivista'|| _vm.app.role == 'ResiduoArquivista')?_c('span',{staticClass:"text-left",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.AbrirDialogDocumento(item)}}},[_vm._v(" "+_vm._s(item.identificacao)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.identificacao)+" ")])]}},{key:"item.dataValidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataValidade? item.dataValidade.toDateDDMMYYYY() : '')+" ")]}}],null,true)})],1)],1)],1)]:_vm._e(),_c('cadastro-documento-geral',{attrs:{"item":_vm.item,"service":_vm.documentoService},on:{"fechou":function($event){_vm.dialogCadastroDocumento = false},"salvou":function($event){return _vm.CarregarEmpresa()}},model:{value:(_vm.dialogCadastroDocumento),callback:function ($$v) {_vm.dialogCadastroDocumento=$$v},expression:"dialogCadastroDocumento"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }