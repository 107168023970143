import { Empresa } from './Empresa';

export class EmpresaConfiguracao implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    situacaoAutomaticaOSResiduo: boolean = false;
    vencimentoDocumentoDias: number = 0;
    vencimentoDocumentoQuantidade: number = 0;
    observacaoMedicao: string = "";
    osResiduoDocumentosProcesso234: boolean = false;
    odCartaRastreabilidade: boolean = false;
    odTecnologiaObrigatoria: boolean = false;
    restringirFinanceiroAdministrador: boolean = false;
    pcpCartaRastreabilidadeMr2: boolean = false;
    restringirVisualizacaoDocumentosGrupo: boolean = false;
    listarDocumentosAnexados: boolean = false;
    restringirGrupoDocumentoAdministrador: boolean = false;

    constructor(model?: EmpresaConfiguracao){
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.situacaoAutomaticaOSResiduo = model.situacaoAutomaticaOSResiduo;
        this.vencimentoDocumentoDias = model.vencimentoDocumentoDias;
        this.vencimentoDocumentoQuantidade = model.vencimentoDocumentoQuantidade;
        this.observacaoMedicao = model.observacaoMedicao;
        this.osResiduoDocumentosProcesso234 = model.osResiduoDocumentosProcesso234;
        this.odCartaRastreabilidade = model.odCartaRastreabilidade;
        this.odTecnologiaObrigatoria = model.odTecnologiaObrigatoria;
        this.restringirFinanceiroAdministrador = model.restringirFinanceiroAdministrador;
        this.pcpCartaRastreabilidadeMr2 = model.pcpCartaRastreabilidadeMr2;
        this.restringirVisualizacaoDocumentosGrupo = model.restringirVisualizacaoDocumentosGrupo;
        this.listarDocumentosAnexados = model.listarDocumentosAnexados;
        this.restringirGrupoDocumentoAdministrador = model.restringirGrupoDocumentoAdministrador;
    }

}