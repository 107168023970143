import { http } from '@/core/ApiConfig';
import { Service } from '../shared';

export class RegiaoService extends Service{

  constructor(){
    super('regiao');
  }

  public VincularPorto(portoId: number, id?: number) {
    return http.put(`${this.GetNomeControle()}/VincularPorto`, undefined, {
      params:{
        portoId,
        id
      }
    });
  }
  
}