import { UnidadeMedida } from '../geral';
import { Tecnologia, Receptor } from './';

export class ReceptorTecnologia {

    receptorId: number = 0;
    receptor: Receptor = new Receptor();
    tecnologiaId: number = 0;
    tecnologia: Tecnologia = new Tecnologia();
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    valor: number = 0;

    constructor(model?: ReceptorTecnologia) {

        if (!model)
            return;

        this.receptorId = model.receptorId;
        this.receptor = model.receptor;
        this.tecnologiaId = model.tecnologiaId;
        this.tecnologia = model.tecnologia;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.valor = model.valor;
    }
}