enum EnumSituacaoOrdemServico {
    Aberta = 1,
    Atendida = 2,
    Concluido = 3,
    Faturado = 4,
    Cancelado = 5,
    ConcluidoManualmente = 6,
    FaturadoParcialmente = 7
}

enum EnumSituacaoOrdemServicoResiduo {
    EmAndamento = 1,
    Cancelado = 2,
    Concluido = 3
}

enum EnumUsuarioPerfil {
    Administrador = 1,
    ControleReisuo = 2,
    Cliente = 3,
    Motorista = 4
}

enum TipoOperacaoLocacao {
    Novo = 0,
    Editar = 1,
    Transbordo = 2,
    TrocaInterna = 3,
    TrocaExterna = 4,
    Retirar = 5
}

enum EnumTipoOrdemServicoLocacao{
    Ida = 1,
    Volta = 2,
    Troca = 3,
    Transbordo = 4
}

export { 
    EnumSituacaoOrdemServico,
    EnumSituacaoOrdemServicoResiduo,
    EnumUsuarioPerfil,
    TipoOperacaoLocacao,
    EnumTipoOrdemServicoLocacao
}