import { CartaRastreabilidadeItem, SituacaoCartaRastreabilidade } from '.';
import { Cliente, Empresa, Usuario } from '../geral';

export class CartaRastreabilidade implements Shared.IEntity{
    
    id: number = 0;
    numero: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    clienteId: number = 0;
    cliente!: Cliente;
    dataHoraCriacao: string = new Date().toYYYYMMDD();
    usuarioId: number = 0;
    usuario!: Usuario;
    responsavelId?: number;
    responsavel!: Usuario;
    situacaoId: number = 0;
    situacao!: SituacaoCartaRastreabilidade;

    itens: CartaRastreabilidadeItem[] = [];

    constructor(model?: CartaRastreabilidade){

        if(!model)
            return;

        this.id = model.id;
        this.numero = model.numero;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.dataHoraCriacao = model.dataHoraCriacao ? model.dataHoraCriacao.toDateYYYYMMDD() : model.dataHoraCriacao.toDateYYYYMMDD();
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.responsavelId = model.responsavelId;
        this.responsavel = model.responsavel;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;

        this.itens = model.itens;
    }
}