import { ContratoReceptorResiduo } from '.';
import { Receptor } from './Receptor';

export class ContratoReceptor implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    numero: number = 0;
    receptorId: number = 0;
    receptor!: Receptor;
    validade: string = '';
    faturamentoMinimo: boolean = false;
    coletaBasePropria: boolean = false;
    observacoes: string = '';    
    ativo: boolean = true;
    residuos: ContratoReceptorResiduo[] = [];

    constructor(model?: ContratoReceptor){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.receptorId = model.receptorId;
        this.receptor = model.receptor;
        this.validade = model.validade;
        this.faturamentoMinimo = model.faturamentoMinimo;
        this.coletaBasePropria = model.coletaBasePropria;
        this.validade = model.validade ? model.validade.toDateYYYYMMDD() : '';
        this.observacoes = model.observacoes;
        this.ativo = model.ativo;
        this.residuos = model.residuos;
    }

}