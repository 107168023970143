import { PessoaJuridica } from './../shared/Pessoa';
import { Equipamento } from '../residuo';
import { Contato } from '../shared/';
import { Empresa, LocatarioGrupoEquipamento } from '.';

export class Locatario extends PessoaJuridica{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    contato: Contato = new Contato();

    equipamentos: Equipamento[] = [];
    grupoEquipamentos: LocatarioGrupoEquipamento[] = [];

    constructor(model?: Locatario){

        super(model);
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.contato = model.contato;

        this.equipamentos = model.equipamentos;
        this.grupoEquipamentos = model.grupoEquipamentos;
    }
}