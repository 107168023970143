import { Usuario } from '.';
import { Transportadora } from '../residuo';
import { PessoaFisica } from './../shared/Pessoa';

export class Motorista extends PessoaFisica{
    
    empresaId: number = 0;
    cnh: string = '';
    mopp: string = '';
    aso: string = '';
    dataValidadeCnh: string = new Date().toYYYYMMDD();
    dataValidadeMopp: string = new Date().toYYYYMMDD();
    dataValidadeAso: string = new Date().toYYYYMMDD();
    transportadoraId: number = 0;
    transportadora!: Transportadora;
    usuarioId?: number;
    usuario!: Usuario;
    cnhFoto: any = null;
    moppFoto: any = null;
    asoFoto: any = null;
    supervisor: boolean = false;

    constructor(model? : Motorista){

        super(model);

        if(!model)
            return;

        this.empresaId = model.empresaId;
        this.cnh = model.cnh;
        this.mopp = model.mopp;
        this.aso = model.aso;
        this.dataValidadeCnh = model.dataValidadeCnh? model.dataValidadeCnh.toDateYYYYMMDD() : '';
        this.dataValidadeMopp = model.dataValidadeMopp? model.dataValidadeMopp.toDateYYYYMMDD() : '';
        this.dataValidadeAso = model.dataValidadeAso? model.dataValidadeAso.toDateYYYYMMDD() : '';
        this.transportadoraId = model.transportadoraId;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.cnhFoto = model.cnhFoto;
        this.moppFoto = model.moppFoto;
        this.asoFoto = model.asoFoto;
        this.supervisor = model.supervisor;
    }
    

}