import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class DocumentoEmpresaService extends Service{

  constructor(){
    super('documentoEmpresa');
  }  

  Download(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/download`, {responseType: 'blob'});
  }

  ListarAcabandoValidade(dias: number){
    return http.get(`${this.GetNomeControle()}/acabandoValidade`, {
      params: {
          dias: dias,
      },        
      headers: httpHeader
  });
  }
  
}