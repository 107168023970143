
















































































import { Equipamento, OrdemServicoLocacao, TipoOrdemServicoLocacao } from "@/core/models/residuo";
import { EnumTipoOrdemServicoLocacao, TipoOperacaoLocacao } from "@/core/models/residuo/Enumerados";
import { ContratoService, EquipamentoService, OrdemServicoService, TipoOrdemServicoLocacaoService } from "@/core/services/residuo";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";


@Component
export default class CadastroOrdemServicoFoto extends Vue {
    @Prop() private item!: OrdemServicoLocacao;
    @Prop() private value!: string;
    @Prop() private contratoId!: number;
    @Prop() private embarcacaoId!: number;
    @Prop() private tipoLocacao!: number;

    service: OrdemServicoService = new OrdemServicoService();
    contratoService: ContratoService = new ContratoService();
    validDialog = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];    
    $refs!: {
        formDialog: HTMLFormElement
    }

    ordemServicoLocacoes: OrdemServicoLocacao[] = [];

    equipamentoService: EquipamentoService = new EquipamentoService();
    equipamentos: Equipamento[] = [];
    equipamentoAux: Equipamento[] = [];

    tipos: TipoOrdemServicoLocacao[] = [];

    transbordo: boolean = false;

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    mounted() {
        new TipoOrdemServicoLocacaoService().ListarTudo().then(
            res => {
                this.tipos = res.data.items;
            }
        )
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.EquipamentoContrato();
        }
        else{
            this.$emit("fechou");
        }
    }
    
    @Watch('item')
    Item(){

        if(this.$refs.formDialog){
            this.$refs.formDialog.resetValidation();
        }

        this.equipamentoService.ListarTudo(undefined, "Grupo").then(
            res => this.equipamentoAux = res.data.items
        )

        this.EquipamentoContrato();
    }

    @Watch('contratoId')
    EquipamentoContrato(){  
        if(!(this.contratoId > 0))
            return;
        
        this.contratoService.ObterPorId(this.contratoId, "Cliente, Equipamentos").then(
            res =>{
                res.data.equipamentos.forEach(x => {
                    this.equipamentoService.PorGrupo(x.grupoEquipamentoId).then(
                        res =>{
                            res.data.forEach(y => {
                                this.equipamentos.push(y)
                            });
                        },
                        err => this.$swal('Aviso', err.response.data, 'error')
                    )
                })
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        
        new OrdemServicoService().ListarLocacoesTroca(this.contratoId, this.embarcacaoId).then(
            res => {
                this.ordemServicoLocacoes = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'warning')
        )
    }

    Salvar(){
        if(this.$refs.formDialog.validate()){

            
            if(this.tipoLocacao == TipoOperacaoLocacao.Retirar){
                this.item.equipamentoId = this.ordemServicoLocacoes.find(x => x.id == this.item.parentId)?.equipamentoId!;
            }

            this.item.equipamento = this.equipamentoAux.find(x=> x.id == this.item.equipamentoId)!;

            if(this.item.parentId)
                this.item.parent = this.ordemServicoLocacoes.find(x => x.id == this.item.parentId)!;            
                    
            if(this.transbordo){
                this.item.dataFim = this.item.dataInicio;
                this.item.tipoId = EnumTipoOrdemServicoLocacao.Transbordo
            }

            this.item.tipo = this.tipos.find(x => x.id == this.item.tipoId)!;

            this.$emit("salvou");
            this.Close();
        }
    }

    Close() {
        this.Clear();
        this.dialog = false;
    }

    Clear(){
        this.equipamentos = [];
        // this.equipamentoAux = [];
        this.transbordo = false;
    }
}
