import { CartaRastreabilidade } from './CartaRastreabilidade';
import { OrdemServicoResiduo } from './OrdemServicoResiduo';

export class CartaRastreabilidadeItem implements Shared.IEntity{
    
    id: number = 0;
    cartaRastreabilidadeId: number = 0;
    cartaRastreabilidade!: CartaRastreabilidade;
    ordemServicoResiduoId: number = 0;
    ordemServicoResiduo!: OrdemServicoResiduo;

    constructor(model?: CartaRastreabilidadeItem){

        if(!model)
            return;

        this.id = model.id;
        this.cartaRastreabilidadeId = model.cartaRastreabilidadeId;
        this.cartaRastreabilidade = model.cartaRastreabilidade;
        this.ordemServicoResiduoId = model.ordemServicoResiduoId;
        this.ordemServicoResiduo = model.ordemServicoResiduo;
    }
}