<template>    
    <canvas :id="key"/>    
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    props:{
        data: {
            required: true
        },
        headers: {
            required: true
        }
    },
    created(){
        this.key = Math.floor(Math.random() * 9999999999).toString();
        Chart.register(...registerables);
        Chart.register(ChartDataLabels);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        key: '012344897',
        chart: {},        
        //METODO PARA COLOCAR MARGEM NO NOME DA LABEL E O CHART
        legendMargin: {
            id: 'legendMargin',
            beforeInit(chart, legend, options){
                const fitValue = chart.legend.fit;
                chart.legend.fit = function fit() {
                    fitValue.bind(chart.legend)();
                    return this.height += 20;
                }
            }
        },
        colors: [
            'rgba(75, 192, 192, 0.3)',
            'rgba(255, 99, 132, 0.3)',
            'rgba(135, 206, 235, 0.3)',
            'rgba(205, 133, 63, 0.3)',
            'rgba(128, 128, 128, 0.3)',
            'rgba(0, 139, 139, 0.3)',
            'rgba(154, 205, 50, 0.3)',
            'rgba(54, 162, 235, 0.3)',
            'rgba(244, 164, 96, 0.3)',
            'rgba(0, 128, 0, 0.3)',
            'rgba(255, 206, 86, 0.3)',
            'rgba(70, 130, 180, 0.3)',
            'rgba(218, 165, 32, 0.3)',
            'rgba(153, 102, 255, 0.3)'
        ]
    }),
    methods:{
        //METODO PARA SIMULAR A QUEBRA DE TEXTO DO DATALABEL
        BreakText(text, maxWidth){
            if(text == null)
                return;
            const words = text.split(' ');
            let currentLine = '';
            const lines = [];
            for (let i = 0; i < words.length; i++) {
                const word = words[i];
                const tempLine = currentLine ? currentLine + ' ' + word : word;
                if (tempLine.length <= maxWidth) {
                    currentLine = tempLine;
                } else {
                    lines.push(currentLine);
                    currentLine = word;
                }
            }
            lines.push(currentLine);
            return lines;
        },
        Generate(){            
            let vm = this;
            let listagem = [];
            if(listagem.length == 0)
                listagem = this.headers;
            const canvas = document.getElementById(this.key);
            const config = {
                type: 'bar',                
                options: {
                    indexAxis: 'y',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context){
                                    return vm.headers[context[0].dataIndex];
                                },
                                label: function(context){
                                    return "Valor: " + context.raw.toDecimal(2);
                                }
                            }
                        },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                height: 10                                
                            }
                        },                        
                        datalabels: {
                            display: false,
                            font: {
                                size: 10 // Tamanho da fonte dos labels
                            },
                            align: 'end',
                            anchor: 'end',
                            textAlign: 'center',
                            clamp: true                            
                        },                        
                    },
                    layout: {
                        padding: {
                            top: 10
                        }
                    },
                    scales: {
                        y: {
                            stacked: false,
                            ticks: {
                                font: {
                                    size: 10
                                },                                
                                callback: function(value, index, ticks) {
                                    const maxWidth = 30;
                                    const lines = vm.BreakText(listagem[index], maxWidth);
                                    return lines;
                                }
                            },
                            grid: {
                                drawBorder: false                                
                            },                            
                        },
                        x: {
                            offset: true,
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            },
                            grid: {                              
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            },
                        },
                    }
                },
                plugins: [this.legendMargin]
            }
            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.datasets = [];
                this.chart.data.labels = this.headers;
                this.data.forEach((item, index) => {
                    this.chart.data.datasets.unshift({
                        label: item.nome,
                        data: item.valores,
                        backgroundColor: this.colors[Math.floor(Math.random() * this.colors.length + 1)],
                        borderRadius: 4,
                        borderWidth: 1
                    });
                })
                this.chart.update();
            }
        }
    }
}
</script>