import { DocumentoVeiculo } from './DocumentoVeiculo';
import { Transportadora } from './../residuo/Transportadora';
import { TipoVeiculo } from '.';

export class Veiculo implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    transportadoraId?: number;
    transportadora!: Transportadora;
    placa: string = '';
    descricao: string = '';
    tipoId: number = 0;
    tipo!: TipoVeiculo;
    documentos: DocumentoVeiculo[] = [];
    

    constructor(model?: Veiculo){

        if(!model)
            return;
        
        this.id = model.id;
        this.empresaId = model.empresaId;
        this.transportadoraId = model.transportadoraId;
        this.placa = model.placa;
        this.descricao = model.descricao;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.documentos = model.documentos;
    }

}