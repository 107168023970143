



































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { ContratoReceptorService, MedicaoReceptorService, OrdemDestinacaoService } from "@/core/services/residuo";
import { MedicaoReceptor, OrdemDestinacao } from "@/core/models/residuo";

@Component
export default class CadastroMedicao extends Vue {
  @Prop() private item!: MedicaoReceptor;
  @Prop() private value!: string;

  itemOriginal!: MedicaoReceptor;

  service = new MedicaoReceptorService();
  ordemDestinacaoService = new OrdemDestinacaoService();

  valid = true;
  dialog: boolean = false;

  contratoReceptorService = new ContratoReceptorService();
  contratoReceptores: any[] = [];
  isContratoReceptorLoading = false;
  onSearchContratoReceptor: any = null;

  dialogSelecionarOD: boolean = false;
  selectedODs: OrdemDestinacao[] = [];

  receptorIdFiltro: number = 0;

  headerOS: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "OD #", value: "numero" },
    { text: "Contrato", value: "contratoReceptor.numeroReceptor" },
    { text: "Data", value: "data" },
    { text: "Quantidade", value: "quantidadeTotal" },
    { text: "Total", value: "valorResiduos" },
    { text: "Total (Receptor)", value: "valorResiduosReceptor" }
  ];

  meses: any[] = [
    {
      valor: 1,
      texto: "Janeiro"
    },
    {
      valor: 2,
      texto: "Fevereiro"
    },
    {
      valor: 3,
      texto: "Março"
    },
    {
      valor: 4,
      texto: "Abril"
    },
    {
      valor: 5,
      texto: "Maio"
    },
    {
      valor: 6,
      texto: "Junho"
    },
    {
      valor: 7,
      texto: "Julho"
    },
    {
      valor: 8,
      texto: "Agosto"
    },
    {
      valor: 9,
      texto: "Setembro"
    },
    {
      valor: 10,
      texto: "Outubro"
    },
    {
      valor: 11,
      texto: "Novembro"
    },
    {
      valor: 12,
      texto: "Dezembro"
    }
  ]

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formMedicao: HTMLFormElement
  }

  itemStates(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else if(item.atualizado && !item.excluido)
      return 'itemAlterado'
    else
      return ''
  }

  @Watch('item')
  Item() {
    if (this.$refs.formMedicao) {
      this.$refs.formMedicao.resetValidation();
    }

    this.receptorIdFiltro = this.item.contratoReceptor.receptorId;
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('onSearchContratoReceptor')
  SearchContratoReceptor (val: string) {

    if (this.item.contratoReceptorId) return;
    if (this.isContratoReceptorLoading) return;
    if (!val) return;

    this.isContratoReceptorLoading = true
    this.contratoReceptorService.AutoComplete(val).then(
      res => {
        this.contratoReceptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => (this.isContratoReceptorLoading = false));
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }
  
  Salvar() {
    if (this.$refs.formMedicao.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);
    
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          if(this.item.ordensDestinacao.length > 0){
            this.item.ordensDestinacao.forEach(x => {
              this.service.VincularOrdemDestinacao(x.id, x.medicaoId).then(
                res => {
                  this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                  this.$emit("salvou");
                  this.Close();
                }
              )
            });
          }
          else{
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          }
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  AtualizarODValores(item: OrdemDestinacao){
    this.ordemDestinacaoService.AtualizarValoresReceptor(item.id).then(
      res =>{
        item.atualizado = true;
        this.dialog = false;
        this.dialog = true;
      },
      err => {
        if (err.response.status == 400) {
          this.$swal("Aviso", err.response.data.title, "warning");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
  }

  SelectedOrdemDestinacao(){
    this.selectedODs.forEach(x => {
      if(!this.item.ordensDestinacao.find(y => y == x)){
        x.medicaoId = this.item.id > 0 ? this.item.id : 0;
        this.item.ordensDestinacao.push(x);
      }      
    })
  }

  ExcluirOrdemDestinacao(ordemDestinacao: OrdemDestinacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        ordemDestinacao.medicaoId = undefined;
        ordemDestinacao.excluido = true;

        this.dialog = false;
        this.dialog = true;

        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          this.$swal("Aviso", "Ordem de Serviço removido com sucesso!", "success");
        }
      })
  }

  Close() {
    this.dialog = false;
  }
}
