import { Contrato } from '.';
import { Servico } from './Servico';

export class ContratoServico implements Shared.IEntity{
    
    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    servicoId: number = 0;
    servico!: Servico;
    preco: number = 0;
    transporte: boolean = false;
    excluido: boolean = false;

    constructor(model?: ContratoServico){

        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.servicoId = model.servicoId;
        this.preco = model.preco;
        this.transporte = model.transporte;
    }
}