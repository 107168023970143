



































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PortoReceptor, Receptor } from "@/core/models/residuo";
import { ReceptorService } from "@/core/services/residuo";


@Component
export default class CadastroPortoReceptor extends Vue {
    @Prop() private item!:PortoReceptor;
    @Prop() private value!:string;

    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    receptores: Receptor[] = [];
    isReceptorLoading = false;
    onSearchReceptor: any = null;
    receptorService = new ReceptorService();

    $refs!: {
        formPortoReceptor: HTMLFormElement
    }

    @Watch('item')
    Item() {
        if (this.$refs.formPortoReceptor) {
            this.$refs.formPortoReceptor.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
        if(this.item.id > 0){
            this.receptores.push(this.item.receptor);
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
    }

    @Watch('onSearchReceptor')
    SearchReceptor (val: string) {

        if (this.item.receptorId) return;
        if (this.isReceptorLoading) return;
        if (!val) return;

        this.isReceptorLoading = true
        this.receptorService.AutoComplete(val).then(
        res => {
            this.receptores = res.data;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => (this.isReceptorLoading = false));
    }

    Salvar(){
        if (this.$refs.formPortoReceptor.validate()) {
            this.item.receptor = this.receptores.find( x => x.id == this.item.receptorId)!
            this.$emit("salvou");
            this.dialog = false;
        }
    }

    Close() {
        this.dialog = false;
    }
}
