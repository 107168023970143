export class UnidadeMedida implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    nome: string = '';
    sigla: string = '';

    constructor(model?: UnidadeMedida){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.sigla = model.sigla;
    }
}