export class Acondicionamento implements Shared.IEntity {
    id: number = 0;
    empresaId: number = 0;
    codigo: string = '';
    nome: string = '';

    constructor(model?: Acondicionamento) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.nome = model.nome;
    }
}
