













































































































































































import { PageBase } from '@/core/models/shared';
import { EmpresaService, UsuarioService } from '@/core/services/geral';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
  @Prop() private icone!: string;
  @Prop() private titulo!: string;
  @Prop() private subTitulo!: string;

  drawer = false;
  active = false;  
  nomeLogo: string = '1.png';

  restringirFinanceiroAdministrador: boolean = false;

  created() {
    new EmpresaService().GetConfiguracao(this.app.empresaId).then(
      res => {
        this.restringirFinanceiroAdministrador = res.data.restringirFinanceiroAdministrador;
      }
    )
  }

  mounted() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
  }

  GetAppVersion(){
    const pack = require('../../../package.json');
    return pack.version;
  }

  AlterarSenha(){
      
      this.$swal({
        title: 'Digite sua senha atual',
        input: 'password',
        confirmButtonText: 'Próximo &rarr;',
      })
      .then((senhaAntiga: any) => {

          this.$swal({
            title: 'Digite sua senha nova',
            input: 'password',
            confirmButtonText: 'Próximo &rarr;',
          })
          .then((senhaNova: any) => {

              this.$swal({
                title: 'Repita a senha nova',
                input: 'password',
                confirmButtonText: 'Alterar',
                showLoaderOnConfirm: true,
                // @ts-ignore: Unreachable code error
                allowOutsideClick: () => !this.$swal.isLoading()
              })
              .then((senhaNova2: any) => {

                if (!senhaAntiga.value || !senhaNova.value){
                  this.$swal("Aviso", 'Nâo foi possível alterar a senha!', "info");
                  return;
                }

                if (senhaNova.value != senhaNova2.value){
                  this.$swal("Aviso", 'As senhas não conferem!', "warning");
                  return;
                }

                const usuarioService = new UsuarioService();
                usuarioService.AlterarSenha(this.app.usuarioId, senhaAntiga.value, senhaNova.value).then(
                  res => {
                    this.$swal("Aviso", res.data, res.status == 200 ? "success" : "warning");
                  },
                  err => {
                    this.$swal('Aviso', err.message, 'error')
                  }
                );
              });
          })

      })
  }

  RelatorioArquivosProblema(){
    let routeLink = this.$router.resolve({name:'relatorioArquivosProblema'});
    window.open(routeLink.href, '_blank');
  }

  RelatorioOsSemOd(){
    let routeLink = this.$router.resolve({name:'relatorioOsSemOd'});
    window.open(routeLink.href, '_blank');
  }

  Logout() {
    localStorage.clear();
    this.$vuetify.theme.dark = false;
    this.$router.push({name: 'login'});
  }

  MudarTema(){
    const usuarioService = new UsuarioService();
    usuarioService.MudarTema(this.app.usuarioId).then(
      res => super.MudarTema(res.data),
      err => this.$swal('Aviso', err.message, 'error')
    );
  };

}
