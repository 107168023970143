import { GrupoServico } from './';

export class Servico implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    codigo: string = '';
    nome: string = '';
    grupoId: number = 0;
    grupo!: GrupoServico;
    preco: number = 0;
    ativo: boolean = true;

    constructor (model?: Servico){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
        this.preco = model.preco;
        this.ativo = model.ativo;
    }
}