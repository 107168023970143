

























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Pergunta } from "@/core/models/monitoramento";
import { PerguntaService } from "@/core/services/monitoramento";

@Component
export default class CadastroPergunta extends Vue {
  @Prop() private item!: Pergunta;
  @Prop() private value!: string;

  itemOriginal!: Pergunta;

  service = new PerguntaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  $refs!: {
    formPergunta: HTMLFormElement
  }

  @Watch('item')
  Item() {
    if (this.$refs.formPergunta) {
      this.$refs.formPergunta.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }

  mounted() {

  }

  Salvar() {
    if (this.$refs.formPergunta.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
