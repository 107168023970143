





















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { EmpresaService, GrupoDocumentoService, TipoDocumentoService } from "@/core/services/geral";
import { ArquivoService } from '@/core/services/shared';
import { DocumentoGrupo, GrupoDocumento, TipoDocumento } from "@/core/models/geral";
import { Arquivo } from '@/core/models/shared';
import { EmpresaDocumento } from "@/core/models/geral/EmpresaDocumento";

@Component
export default class CadastroDocumentoEmpresa extends Vue {
  @Prop() private item!: EmpresaDocumento;
  @Prop() private value!: string;

  itemOriginal!: EmpresaDocumento;
  service = new EmpresaService();
  arquivoService = new ArquivoService();
  salvando: boolean = false;
  arquivo: any = {
    documento: null,
    documentoChip: true
  }
  valid = true;
  validGrupo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formDocumentoEmpresa: HTMLFormElement
    formGrupo: HTMLFormElement
  }  
  grupoHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Grupo', value: 'grupo.descricao' }
  ];
  documentoEmpresaGrupo = new DocumentoGrupo();
  grupos: GrupoDocumento[] = [];
  grupoDocumentoService = new GrupoDocumentoService();

  tipos: TipoDocumento[] = [];

  @Watch('item')
  Item() {

    if (this.$refs.formDocumentoEmpresa) {
      this.$refs.formDocumentoEmpresa.resetValidation();
    }
    if (this.$refs.formGrupo) {
      this.$refs.formGrupo.resetValidation();
    }

  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item)
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  } 

  mounted(){
    this.grupoDocumentoService.ListarTudo().then(
      res =>{
        this.grupos = res.data.items;
      }
    )
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      }
    )
  }

  AdicionarEmpresaGrupo(){
    if(this.item.documento.todos){
      this.$swal("Aviso", "Não é possível adicionar um grupo em um documento marcado como 'TODOS'!", "warning");
    }
    else if(this.$refs.formGrupo.validate()){
      if(this.item.documento.grupos.find(x => x.grupoId == this.documentoEmpresaGrupo.grupoId)){
        this.$swal("Aviso", "Esse grupo já se encontra adicionado!", "warning");
      }
      else{
        this.documentoEmpresaGrupo.grupo = this.grupos.find(x => x.id == this.documentoEmpresaGrupo.grupoId)!;
        this.item.documento.grupos.push(this.documentoEmpresaGrupo);
        if (this.$refs.formGrupo) {
          this.$refs.formGrupo.resetValidation();
        }
      }
      this.documentoEmpresaGrupo = new DocumentoGrupo();
    }
  }

  ExcluirDocumentoGrupo(grupo: DocumentoGrupo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.documento.grupos.indexOf(grupo);
        this.item.documento.grupos.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  async Salvar() {
    if (this.$refs.formDocumentoEmpresa.validate()) {    

      var arquivoPesado: boolean = false;

      if(this.arquivo.documento){
        let dados = await this.arquivoService.Ler(this.arquivo.documento);

        this.item.documento.arquivo = new Arquivo();
        this.item.documento.arquivo.nome = this.arquivo.documento.name;
        this.item.documento.arquivo.tamanho = this.arquivo.documento.size / 1024;
        this.item.documento.arquivo.dados = dados.replace(/^[^,]*,/, "");

        if(this.arquivo.documento.type.length > 0){
          this.item.documento.arquivo.tipo = this.arquivo.documento.type;
        }
        else{
          this.item.documento.arquivo.tipo = 'application/x-rar-compressed';
        }

        if(this.item.documento.arquivo.tamanho > 20000){
          arquivoPesado = true;
        }
        else{
          arquivoPesado = false;
        }
      }
      else if(!this.arquivo.documento && !this.arquivo.documentoChip){
        this.item.documento.arquivo = undefined;
      }

      if(arquivoPesado){
        this.$swal("Aviso", 'Não é possíve fazer upload de um arquivo maior que 20Mb', "error");
      }
      else{
        this.salvando = true;
        let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

        (this.item.id > 0 ? this.service.SalvarDocumento(pacthModel, this.item.id) : this.service.SalvarDocumento(this.item))
        .then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data, "warning");
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }
            else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        ).finally(() => {
          this.salvando = false;
        });
      }
    }
  }

  Close() {
    this.arquivo = {
      documento: null,
      documentoChip: true
    }

    this.dialog = false;
  }
}
