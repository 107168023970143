































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { OrdemServicoService, ResiduoService, TecnologiaService } from "@/core/services/residuo/";
import { OrdemServicoResiduo } from "@/core/models/residuo/";

@Component
export default class SelecionarOrdemServicoResiduo extends Vue {
  @Prop() private selected!: OrdemServicoResiduo[];
  @Prop() private value!: boolean;
  @Prop() private singleSelect!: boolean;
  @Prop() private clienteId!: number;
  @Prop({default: false}) private hasOd!: boolean;

  service = new OrdemServicoService();
  residuoService = new ResiduoService();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  sessionApp: any;

  localSelected: OrdemServicoResiduo[] = [];
  loading: boolean = false;
  isResiduoLoading = false;
  lista: any[] = [];
  tecnologias: any[] = [];
  residuos: any[] = [];
  headers: any[] = [
    { text: "", sortable: false },
    { text: "OS #", value: "ordemServico.numero" },
    { text: "Cliente", value: "ordemServico.contrato.cliente.nome" },
    { text: "Protocolo", value: "ordemServico.protocolo"},
    { text: "Resíduo", value: "residuo.nome" },
    { text: "MR01", value: "processo1.mr01" },
    { text: "MMR / FCDR", value: "processo1.fcdr" },
    { text: "MR02", value: "processo2.mr02" },
    { text: "MR03", value: "processo3.mr03" },
    { text: "Coleta", value: "dataColeta" },
    { text: "Unidade", value: "unidade.sigla" },
    { text: "Quantidade", value: "quantidade" }
  ];
  sheet: boolean = false;  
  filtro: any = {
    numeroOS: null,
    numeroOD: null,
    tecnologiaId: null,
    residuoId: null,
    protocolo: '',
    dataColetaInicial: '',
    dataColetaFinal: '',
    mr01: '',
    mr02: '',
    mr03: '',
    clienteId: null,
    hasOd: false
  }

  totalItems: number = 0;
  page: number = 1;
  take: number = 15;

  @Watch("value")
  Value() {
    if (this.value){
      this.localSelected.length = 0;
      if(this.clienteId){
        this.filtro.clienteId = this.clienteId;
      }
      this.Atualizar();
    }
  }

  mounted() {
    this.sessionApp = JSON.parse(localStorage.sessionApp);

    const tecnologiaService = new TecnologiaService();
    tecnologiaService.ListarTudo().then(
      res=> {
        this.tecnologias = res.data.items;        
        this.tecnologias.unshift({
          id: null,
          nomePcp: '---TODOS---'
        });
      }
    );

    this.residuoService.ListarTudo(undefined, "Tecnologia, Grupo").then(
      res => {
        this.residuos = res.data.items;
        this.residuos.unshift({
          id: null,
          codigoNomeTecnologiaGrupo: '---TODOS---'
        })
      }
    )

    if(this.hasOd){
      this.filtro.hasOd = this.hasOd;
      this.headers.splice(2, 0, { text: "OD #", value: "ordemDestinacao.numero" });
    }
  }

  @Watch("page")
  @Watch("take")
  WatchTable(){
    this.Atualizar(false);
  }

  Atualizar(filtrar: boolean = false) {
    
    if(filtrar){
      this.page = 1;      
    }
    
    this.sheet = false;
    this.loading = true;    
    this.service.GetResiduos(this.page, this.take, this.filtro).then(
      res => {
        this.lista = [];
        this.lista = res.data.items;
        this.totalItems = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(
      () => (this.loading = false)
    );
  }

  Selecionar() {
    this.$emit('update:selected', this.localSelected);
    if (this.localSelected.length > 0){
      this.$emit('selecionou');
    }
    this.Close();
  }

  Close() {
    this.localSelected = [];
    this.lista = [];
    this.$emit("fechou");
  }
}
