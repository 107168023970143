import { Arquivo } from '../shared';

export class OrdemServicoResiduoArquivo implements Shared.IEntity {

    id: number = 0;
    ordemServicoResiduoId: number = 0;
    mr01?: Arquivo;
    cdf01?: Arquivo;
    cartaResponsabilidade?: Arquivo;
    ticketPesagem?: Arquivo;
    fcdr?: Arquivo;
    relatorioObservacao?: Arquivo;
    mr02?: Arquivo;
    cdf02?: Arquivo;
    relatorioRecebimento2?: Arquivo;
    mr03?: Arquivo;
    cdf03?: Arquivo;
    relatorioRecebimento3?: Arquivo;
    mr04?: Arquivo;
    cdf04?: Arquivo;
    relatorioRecebimento4?: Arquivo;

    constructor(model?: OrdemServicoResiduoArquivo) {

        if (!model)
            return;

        this.id = model.id;
        this.ordemServicoResiduoId = model.ordemServicoResiduoId;
        this.mr01 = model.mr01;
        this.cdf01 = model.cdf01;
        this.cartaResponsabilidade = model.cartaResponsabilidade;
        this.ticketPesagem = model.ticketPesagem;
        this.fcdr = model.fcdr;
        this.relatorioObservacao = model.relatorioObservacao;
        this.mr02 = model.mr02;
        this.cdf02 = model.cdf02;
        this.relatorioRecebimento2 = model.relatorioRecebimento2;
        this.mr03 = model.mr03;
        this.cdf03 = model.cdf03;
        this.relatorioRecebimento3 = model.relatorioRecebimento3;
        this.mr04 = model.mr04;
        this.cdf04 = model.cdf04;
        this.relatorioRecebimento4 = model.relatorioRecebimento4;
    }
}