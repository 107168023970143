























































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { PageBase } from "@/core/models/shared";
import { Locatario, LocatarioGrupoEquipamento } from "@/core/models/geral";
import { EnderecoService, LocatarioService } from "@/core/services/geral";
import { Equipamento, GrupoEquipamento } from "@/core/models/residuo";
import { EquipamentoService, GrupoEquipamentoService } from "@/core/services/residuo";

@Component
export default class CadastroLocatario extends PageBase {
  @Prop() private item!: Locatario;
  @Prop() private value!: string;

  itemExcluido(item: any){
    return item.vincular == false ? 'itemExcluido' : '';
  }

  itemOriginal!: Locatario;
  service = new LocatarioService();

  valid = true;
  validEquipamento = true;
  validArquivo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tipos: any[] = [];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  $refs!: {
    formLocatario: HTMLFormElement,
    formEquipamento: HTMLFormElement,
    formEquipamentoGrupo: HTMLFormElement
  }

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;
  headersPorto: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Empresa', value: 'nome' }
  ];

  headersEquipamento: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Codigo', value: 'codigo' },
    { text: 'Nome', value: 'nome' }
  ];

  enderecoService = new EnderecoService();
  tabActive: any = {};
  estados: any[] = [];
  municipios: any[] = [];

  equipamentoService: EquipamentoService = new EquipamentoService();
  equipamentos: Equipamento[] = [];
  equipamento: Equipamento = new Equipamento();
  equipamentoValor: number = 0;
  onSearchEquipamento: any = null;
  isEquipamentoLoading: boolean = false;

  headersEquipamentoGrupo: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Nome', value: 'grupoEquipamento.nome' },
    { text: 'Valor', value: 'valor' }
  ];
  validEquipamentoGrupo: boolean = false;
  equipamentoGrupo: LocatarioGrupoEquipamento = new LocatarioGrupoEquipamento();
  grupoEquipamento: GrupoEquipamento = new GrupoEquipamento();
  grupoEquipamentos: GrupoEquipamento[] = [];
  grupoEquipamentoService: GrupoEquipamentoService = new GrupoEquipamentoService();

  @Watch('item')
  Item() {
    if (this.$refs.formLocatario) {
      this.$refs.formLocatario.resetValidation();
    }

    if(this.item.id > 0){
      this.item.equipamentos.forEach(equipamento => {
        equipamento.vincular = true;
      });
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }

  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged() {
    this.CarregarMunicipio();
  }

  @Watch("onSearchEquipamento")
  SearchEquipamento(val: string) {
    if (this.equipamento.id) return;
    if (this.isEquipamentoLoading) return;
    if (!val) return;
    this.isEquipamentoLoading = true
    this.equipamentoService.AutoComplete(val, false).then(
      res => { 
        this.equipamentos = res.data;
      },
      err => this.$swal("Aviso!", err)
    ).finally(() => (this.isEquipamentoLoading = false));
  }

  SalvarEquipamento(){
    if (this.$refs.formEquipamento.validate()) {
      if(!this.item.equipamentos.find(x => x.id == this.equipamento.id)){
        this.equipamento = this.equipamentos.find(x => x.id == this.equipamento.id)!;
        this.equipamento.vincular = true;
        this.item.equipamentos.push(this.equipamento);
        this.equipamento = new Equipamento();
  
        if (this.$refs.formEquipamento) {
          this.$refs.formEquipamento.resetValidation();
        }
      }
      else{
        this.$swal('Aviso', "O item informado já se encontra incluso.", 'warning');
      }
    }
  }

  ExcluirEquipamento (item: Equipamento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let index = this.item.equipamentos.indexOf(item);
        this.item.equipamentos[index].vincular = false;
        this.dialog = false;
        this.dialog = true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  CarregarMunicipio(){
    this.enderecoService.ObterMunicipios(this.item.endereco.estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    );
    this.grupoEquipamentoService.ListarTudo().then(
      res => this.grupoEquipamentos = res.data.items,
      err => this.$swal('Aviso', err.message, 'error')
    )
  }

  SalvarEquipamentoGrupo(){
    if (this.$refs.formEquipamentoGrupo.validate()) {
      console.log(this.item)
      if(!this.item.grupoEquipamentos.find(x => x.grupoEquipamentoId == this.equipamentoGrupo.grupoEquipamentoId)){        
        this.equipamentoGrupo.grupoEquipamento = this.grupoEquipamentos.find(x => x.id == this.equipamentoGrupo.grupoEquipamentoId)!;
        this.equipamentoGrupo.locatarioId = this.item.id;
        this.item.grupoEquipamentos.push(this.equipamentoGrupo);
        this.equipamentoGrupo = new LocatarioGrupoEquipamento();
        if (this.$refs.formEquipamentoGrupo) {
          this.$refs.formEquipamentoGrupo.resetValidation();
        }
      }
      else{
        this.$swal('Aviso', "O item informado já se encontra incluso.", 'warning');
      }
    }
  }

  ExcluirEquipamentoGrupo (item: LocatarioGrupoEquipamento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let index = this.item.grupoEquipamentos.indexOf(item);
        this.item.grupoEquipamentos.splice(index, 1);
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  Salvar() {
    if (this.$refs.formLocatario.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          if(this.item.equipamentos.length > 0){
            this.item.equipamentos.forEach((equipamento, index) => {
              this.service.VincularEquipamento(this.item.id, equipamento.id, equipamento.vincular).then(
                res => {
                  if(index == this.item.equipamentos.length - 1){
                    this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();    
                  }
                },
                err => {
                  if (err.response.status == 400) {
                    this.$swal("Aviso", err.response.data.title, "warning");
                  } else {
                    this.$swal("Aviso", err.response.data, "error");
                  }
                }
              )              
            });            
          }
          else {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          }
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );

    }
  }

  Close() {
    this.equipamentoGrupo = new LocatarioGrupoEquipamento();
    this.dialog = false;
    this.tabActive = {};
  }
}
