import { Transportadora } from './../residuo/Transportadora';
import { Cliente } from './Cliente';
import { Contato } from '../shared/';
import { PerfilUsuario, UsuarioOrdemServicoRestricao } from './';

export class Usuario implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    login: string = '';
    senha: string = '';
    complementar: Contato = new Contato();
    clienteId?: number;
    cliente!: Cliente;
    transportadoraId?: number;
    transportadora!: Transportadora;
    perfilId: number = 1;
    perfil!: PerfilUsuario;
    ativo: boolean = true;
    foto: any = null;
    assinatura: any = null;
    temaEscuro: boolean = false;

    ordemServicoRestricoes: UsuarioOrdemServicoRestricao[] = [];

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.login = model.login;
        this.senha = model.senha;
        this.complementar = model.complementar;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.transportadoraId = model.transportadoraId;
        this.transportadora = model.transportadora;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.foto = model.foto;
        this.assinatura = model.assinatura;
        this.ativo = model.ativo;
        this.temaEscuro = model.temaEscuro;

        this.ordemServicoRestricoes = model.ordemServicoRestricoes;
    }
}