










































































import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch } from "vue-property-decorator";
import { Documento } from '@/core/models/geral';
import { DocumentoService } from '@/core/services/geral/DocumentoService';

@Component
export default class Home extends PageBase {
  
  loadingDocumentosVencer: boolean = false;
  documentosVencer: Documento[] = [];
  documentosVencerQtd: number = 0;
  documentosVencerOpt: any = {
    itemsPerPage: 15
  };
  documentosVencerHeader: any[] = [
    { text: 'Nome', value:'identificacao'},
    { text: 'Data', value:'dataValidade'}
  ];

  loadingDocumentosVencidos: boolean = false;
  documentosVencidos: Documento[] = [];
  documentosVencidosQtd: number = 0;
  documentosVencidosOpt: any = {
    itemsPerPage: 15
  };
  documentosVencidosHeader: any[] = [
    { text: 'Nome', value:'identificacao'},
    { text: 'Data', value:'dataValidade'}
  ]

  item = new Documento();
  documentoService: DocumentoService = new DocumentoService();
  dialogCadastroDocumento: boolean = false;

  @Watch("documentosVencerOpt")
  CarregarDocumentosVencer(){
    this.documentoService.AcabandoValidade(this.documentosVencerOpt.itemsPerPage, this.documentosVencerOpt.page).then(
      res => {
        this.documentosVencer = res.data.items;
        this.documentosVencerQtd = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )    
  }

  @Watch("documentosVencidosOpt")
  CarregarDocumentosVencidos(){
    this.loadingDocumentosVencidos = true;
    this.documentoService.AcabouValidade(this.documentosVencidosOpt.itemsPerPage, this.documentosVencidosOpt.page).then(
      res => {
        this.documentosVencidos = res.data.items;
        this.documentosVencidosQtd = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loadingDocumentosVencidos = false;
    })
  }

  AbrirDialogDocumento(item: Documento){
    this.documentoService.ObterPorId(item.id,"Tipo,Grupos.Grupo,Grupos.Grupo.Usuarios").then(
      res => {
        this.item = new Documento(res.data);
        this.dialogCadastroDocumento = true;
      },
      err => {
        if (!err.response){
          this.$swal("Aviso", "Não foi possivel acessar a API", "error");
        }else {
          this.$swal("Aviso",err.response.data,"error");
        }
      }
    )
  }

}
