





















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Agendamento } from "@/core/models/monitoramento";
import { AgendamentoService } from "@/core/services/monitoramento";
import { OrdemServico, Porto, Receptor } from "@/core/models/residuo";
import { Veiculo } from "@/core/models/geral";
import { OrdemDestinacaoService, OrdemServicoService, PortoService, ReceptorService } from "@/core/services/residuo";
import { MotoristaService, VeiculoService } from "@/core/services/geral";
import { PageBase } from "@/core/models/shared";

import { OrdemServico as OrdemServicoInterna } from "@/core/models/interna";
import { OrdemServicoService as OrdemServicoInternaService} from "@/core/services/interna";


@Component
export default class CadastroAgendamento extends PageBase {
  @Prop() private item!: Agendamento;
  @Prop() private value!: string;

  itemOriginal!: Agendamento;

  service = new AgendamentoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  $refs!: {
    formAgendamento: HTMLFormElement
  }

  filtro: any = {
    transportadoraId: this.app.transportadoraId,
  }

  baseOrigem: boolean = false;
  veiculos: Veiculo[] = [];
  veiculoService = new VeiculoService();
  motoristas: any[] = [];
  motoristaService = new MotoristaService();
  portos: Porto[] = [];
  portoService = new PortoService();
  bases: any[] = [];  
  receptores: Receptor[] = []
  receptorService = new ReceptorService();

  onSearchOrdemServico: any = null;
  ordemServicos: OrdemServico[] = [];
  ordemServicoService = new OrdemServicoService();
  isOrdemServicoLoading: boolean = false;

  onSearchOrdemServicoInterna: any = null;
  ordemServicoInternas: OrdemServicoInterna[] = [];
  ordemServicoInternaService = new OrdemServicoInternaService();
  isOrdemServicoInternaLoading: boolean = false;

  onSearchOrdemDestinacao: any = null;
  ordemDestinacoes: OrdemServico[] = [];
  ordemDestinacaoService = new OrdemDestinacaoService();
  isOrdemDestinacaoLoading: boolean = false;

  selectTipo: number = 1;
  tipos: any[] = [
    { id: 1, nome: 'OS'},
    { id: 2, nome: 'OS (Interna)'},
    { id: 3, nome: 'OD'},
  ]

  @Watch('item')
  Item() {
    if (this.$refs.formAgendamento) {
      this.$refs.formAgendamento.resetValidation();
    }
    if(this.item.id > 0){
      this.item.ordemServicoId ? this.selectTipo = 1 : '';
      this.item.ordemServicoInternaId ? this.selectTipo = 2 : '';
      this.item.ordemDestinacaoId ? this.selectTipo = 3 : '';
    }
  }

  @Watch('selectTipo')
  SelectTipoWatch(val: number){
    if(val != 3 && !(this.item.id > 0)){
      this.item.receptorId = undefined;
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("baseOrigem")
  Origem() {
    if (this.baseOrigem) {
      this.item.portoId = undefined;
    }
    else {
      this.item.baseId = undefined;
    }
  }

  @Watch('onSearchOrdemServico')
  SearchOS (val: string) {

    if (this.item.ordemServicoId) return;
    if (this.isOrdemServicoLoading) return;
    if (!val) return;

    this.isOrdemServicoLoading = true;
    this.ordemServicoService.AutoComplete(val).then(
      res => {
        this.ordemServicos = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isOrdemServicoLoading = false;
    })
  }

  @Watch('onSearchOrdemServicoInterna')
  SearchOSInterna (val: string) {

    if (this.item.ordemServicoInternaId) return;
    if (this.isOrdemServicoInternaLoading) return;
    if (!val) return;

    this.isOrdemServicoInternaLoading = true;
    this.ordemServicoInternaService.AutoComplete(val).then(
      res => {
        this.ordemServicoInternas = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isOrdemServicoInternaLoading = false;
    })
  }

  @Watch('onSearchOrdemDestinacao')
  SearchOD (val: string) {

    if (this.item.ordemDestinacaoId) return;
    if (this.isOrdemDestinacaoLoading) return;
    if (!val) return;

    this.isOrdemDestinacaoLoading = true
    this.ordemDestinacaoService.AutoComplete(val)
        .then(
            res => {
              this.ordemDestinacoes = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isOrdemDestinacaoLoading = false));
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {

    this.veiculoService.ListarComFiltro(0,-1,[],[],null,[],this.filtro).then(
      res=> this.veiculos = res.data.items,
      err=> this.$swal('Aviso', err.message, 'error')
    )

    this.motoristaService.ListarComFiltro(0,-1,[],[],null,[],this.filtro).then(
      res=>{
        this.motoristas = res.data.items
        this.motoristas.unshift(
          {
            id: null,
            nome: ''
          }
        )
      },
      err=> this.$swal('Aviso', err.message, 'error')
    )

    this.receptorService.ListarTudo().then(
      res=> this.receptores = res.data.items,
      err=> this.$swal('Aviso', err.message, 'error')
    )

    this.portoService.ListarTudo().then(
      res=> this.portos = res.data.items,
      err=> this.$swal('Aviso', err.message, 'error')
    )
  }

  Salvar() {
    if (this.$refs.formAgendamento.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      if(!this.item.ordemServicoId && !this.item.ordemServicoInternaId && !this.item.ordemDestinacaoId){
        this.$swal("Aviso", 'Nenhuma OS, OS (Interna) ou OD foi selecionada', "warning");
      }
        
      else{

        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
        .then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            this.$swal("Aviso", err.response.data, err.response.status == 400 ? 'warning' : 'error');
          }
        );

      }
    }
  }

  Close() {
    this.selectTipo = 1;
    this.dialog = false;
  }
}
