import { Receptor } from ".";
import { Documento } from "../geral";

export class ReceptorDocumento implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    receptorId: number = 0;
    receptor!: Receptor;

    constructor(model?: ReceptorDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.receptorId = model.receptorId;
        this.receptor = model.receptor;
    }
}