import { ContratoReceptor } from './ContratoReceptor';
import { UnidadeMedida } from '../geral';
import { Residuo } from './';

export class ContratoReceptorResiduo implements Shared.IEntity{
    
    id: number = 0;
    contratoId: number = 0;
    cantrato!: ContratoReceptor;
    residuoId: number = 0;
    residuo!: Residuo;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    preco: number = 0;
    excluido: boolean = false;


    constructor(model?: ContratoReceptorResiduo){

        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.residuoId = model.residuoId;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.preco = model.preco;
    }

}