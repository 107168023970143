import { Documento, Motorista } from ".";

export class MotoristaDocumento implements Shared.IEntity{
    
    id: number = 0;
    documentoId: number = 0;
    documento: Documento = new Documento();
    motoristaId: number = 0;
    motorista!: Motorista;

    constructor(model?: MotoristaDocumento){
        
        if(!model)
            return;

        this.id = model.id;
        this.documentoId = model.documentoId;
        this.documento = model.documento;
        this.motoristaId = model.motoristaId;
        this.motorista = model.motorista;
    }
}