export class NotificacaoDocumento{
    
    notificar: boolean = false;
    antes: boolean = true;
    assunto: string = "";
    dias: string = "";
    destinatarios: string = "";

    constructor(model?: NotificacaoDocumento){
        
        if(!model)
            return;

        this.notificar = model.notificar;
        this.antes = model.antes;
        this.assunto = model.assunto;
        this.dias = model.dias;
        this.destinatarios = model.destinatarios;
    }

}