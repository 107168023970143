export class GrupoResiduo implements Shared.IEntity{
    
    id: number = 0;
    empresaId: number = 0;
    nome: string = '';
    ativo: boolean = true;

    constructor(model?: GrupoResiduo){
        
        if(!model)
            return;
        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;
    }
}