import {http} from '../../ApiConfig';
import { Service } from '../shared/';

export class EmpresaService extends Service{

  constructor(){
    super('empresa');
  }

  public ObterLogo(){
    return http.get(`${this.GetNomeControle()}/logo`);
  }

  public GetConfiguracao(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Configuracao`);
  }

  private GetFiltro(parametros: string, filter: any){
    if (filter) {
      let paramTemp = '';

      if (filter.identificacao){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(documento/identificacao, '${filter.identificacao}')`;
      }

      if (filter.descricao){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(documento/descricao, '${filter.descricao}')`;
      }

      if (filter.tipoId && filter.tipoId.length > 0){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `documento/tipoId in (${filter.tipoId})`;
      }

      if (filter.protocoloValidade){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(documento/protocoloValidade, '${filter.protocoloValidade}')`;
      }

      if (filter.validadeInicial){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `documento/dataValidade ge ${filter.validadeInicial}`;
      }
      
      if (filter.validadeFinal){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `documento/dataValidade le ${filter.validadeFinal}`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }

  //DOCUMENTOS
  public ListarDocumentos(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filtro: string, include?: string) : Promise<any>{

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filtro);

    return http.get(`${this.GetNomeControle()}/Documento${parametros}`, this.GetHeader(include));
  }

  public ObterDocumentoPorId(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public SalvarDocumento(model: any) : Promise<any>
  public SalvarDocumento(model: any, documentoId: number) : Promise<any>
  public SalvarDocumento(model: any, documentoId?: number) : Promise<any>{
    if(documentoId){
      if(documentoId > 0){
        return http.patch(`${this.GetNomeControle()}/Documento/${documentoId}`, model);
      }
    }

    return http.post(`${this.GetNomeControle()}/Documento`, model);
  }

  public DeletarDocumento(documentoId: number) : Promise<any>{
    return http.delete(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public DownloadDocumento(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}/Download`, {responseType: 'blob'});
  }
}