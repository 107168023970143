





































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { GrupoDocumento, GrupoDocumentoUsuario, Usuario } from "@/core/models/geral";
import { GrupoDocumentoService, UsuarioService } from "@/core/services/geral";
import { Legend } from "chart.js";


@Component
export default class CadastroDocumentoEmpresa extends Vue {
  @Prop() private item!: GrupoDocumento;
  @Prop() private value!: string;
  itemOriginal!: GrupoDocumento;
  service = new GrupoDocumentoService();
  grupoDocumentoUsuario: GrupoDocumentoUsuario = new GrupoDocumentoUsuario();
  usuarios: Usuario[] = [];
  usuarioService = new UsuarioService();
  valid = true;
  validUsuario = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement,
    formUsuario: HTMLFormElement
  }
  usuarioHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Usuario', value: 'usuario.complementar.nome' },
  ];

  @Watch('item')
  Item() {

    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }

    if (this.$refs.formUsuario) {
      this.$refs.formUsuario.resetValidation();
    }

  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item)
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.WatchEditor();
    }
    else{
      this.$emit("fechou");
    }
  }

  @Watch("item.editor")
  WatchEditor(){
    this.usuarios = [];
    this.usuarioService.ListarTudo().then(
        res => {
        var retorno = res.data.items;
        if(this.item.editor){
          this.usuarios = retorno.filter(x => x.perfilId == 1 || x.perfilId == 6 || x.perfilId == 7);
        }
        else{
          this.usuarios = retorno;
        }
      }
    )
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  } 

  AdicionarGrupoUsuario(){
    if(this.$refs.formUsuario.validate()){
      if(this.item.usuarios.find(x => x.usuarioId == this.grupoDocumentoUsuario.usuarioId)){
        this.$swal("Aviso", "Esse usuário já se encontra adicionado!", "warning");
      }
      else{
        this.grupoDocumentoUsuario.usuario = this.usuarios.find(x => x.id == this.grupoDocumentoUsuario.usuarioId)!;
        this.item.usuarios.push(this.grupoDocumentoUsuario);
        if (this.$refs.formUsuario) {
          this.$refs.formUsuario.resetValidation();
        }
      }
      this.grupoDocumentoUsuario = new GrupoDocumentoUsuario();
    }
  }

  async Salvar() {
    if (this.$refs.form.validate()) {    

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
  }

  ExcluirUsuario(usuario: GrupoDocumentoUsuario){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.usuarios.indexOf(usuario);
        this.item.usuarios.splice(index,1);

        return true;
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  Close() {
    this.dialog = false;
  }
}
