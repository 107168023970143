import { Endereco } from '../shared';

export class Base implements Shared.IEntity{
    
    id: number = 0;
    clienteId: number = 0;
    nome: string = '';
    pcpCodigo: string = "";
    ativo: boolean = true;
    excluido: boolean = false;
    endereco: Endereco = new Endereco();
    mapaUrl: string = '';

    constructor(model?: Base){

        if(!model)
            return;

        this.id = model.id;
        this.clienteId = model.clienteId;
        this.nome = model.nome;
        this.pcpCodigo = model.pcpCodigo;
        this.ativo = model.ativo;
        this.excluido = model.excluido;
        this.endereco = model.endereco;
        this.mapaUrl = model.mapaUrl;
    }
}