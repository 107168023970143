















































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';

import { ClienteService, EnderecoService, TipoDocumentoService } from "@/core/services/geral";
import { Cliente } from "@/core/models/geral";
import { TipoEmbarcacaoService } from "@/core/services/residuo";
import { Base, Embarcacao } from "@/core/models/residuo";
import { Arquivo, PageBase } from "@/core/models/shared";
import { DocumentoEmbarcacao } from "@/core/models/residuo/DocumentoEmbarcacao";
import { ArquivoService } from "@/core/services/shared";

@Component
export default class CadastroCliente extends PageBase {
  @Prop() private item!: Cliente;
  @Prop() private value!: string;

  $refs!: {
    formCliente: HTMLFormElement
    formDialogEmbarcacao: HTMLFormElement
    formDialogBase: HTMLFormElement
  }

  service = new ClienteService();
  enderecoService = new EnderecoService();
  tipoEmbarcacaoService = new TipoEmbarcacaoService();
  tipoEmbarcacaoDocumentoService = new TipoDocumentoService();
  embarcacao = new Embarcacao();
  arquivoDocumento: any = null;
  embarcacoes: Embarcacao[] = [];
  embarcacaoDocumento = new DocumentoEmbarcacao();
  base = new Base();
  valid = true;
  validDialogEmbarcacao = true;
  validDialogBase = true;
  dialog = false;
  dialogEmbarcacao = false;
  dialogBase = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskCep = process.env.VUE_APP_MSK_CEP;
  tabActive: any = {};
  estados: any[] = [];
  municipios: any[] = [];
  tipoEmbarcacoes: any[] = [];
  tipoEmbarcacaoDocumentos: any[] = [];  

  headerEmbarcacao: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Código PCP', value: 'pcpCodigo' },
    { text: 'Nome', value: 'nome' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Ativo', value: 'ativo' }   
  ];
  headerBase: any[] = [
    { text: '', value:'actions',sortable: false },
    { text: 'Código PCP', value: 'pcpCodigo' },
    { text: 'Nome', value: 'nome' },
    { text: 'Ativo', value: 'ativo' },
  ];
  headerEmbarcacaoDocumento: any[] = [
    { text: '', value:'actions',sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Data Validade', value: 'dataValidade' }     
  ];

  observer! : jsonpatch.Observer<Cliente>;

  itemExcluido(item: any){
    if(item.excluido)
      return 'itemExcluido'
    else
      return ''
  }

  @Watch('item')
  Item() {
    if (this.$refs.formCliente) {
      this.$refs.formCliente.resetValidation();
    }
    if(this.$refs.formDialogEmbarcacao){
      this.$refs.formDialogEmbarcacao.resetValidation();
    }
    if(this.$refs.formDialogBase){
      this.$refs.formDialogBase.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else{
      jsonpatch.unobserve(this.item, this.observer);
    }

  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  }

  @Watch("base.endereco.estadoId")
  onBaseEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  }

  mounted() {

    this.enderecoService.ObterEstados()
      .then(
        res => {
          this.estados = res.data.items;
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );   
    this.tipoEmbarcacaoService.ListarTudo()
      .then(
        res => {
          this.tipoEmbarcacoes = res.data.items;
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      );  
    this.tipoEmbarcacaoDocumentoService.ListarTudo()
      .then(
        res =>{
          this.tipoEmbarcacaoDocumentos = res.data.items;
        },
        err => this.$swal('Aviso', err.message, 'error')
      );
    
  }

  AbrirDialogEmbarcacao(embarcacao?: Embarcacao){
    this.embarcacao = embarcacao ? embarcacao : new Embarcacao();
    this.dialogEmbarcacao = true;
  }

  SalvarEmbarcacao(){

    if (this.$refs.formDialogEmbarcacao.validate()) {
      
      if (this.embarcacao.id == 0){
        const tipoEmbarcacao  = this.tipoEmbarcacoes.find(x => x.id == this.embarcacao.tipoId);
        this.embarcacao.tipo = tipoEmbarcacao;
        this.item.embarcacoes.push(this.embarcacao);
      }

      this.dialogEmbarcacao = false;
    }
  }  

  ExcluirEmbarcacao(embarcacao: Embarcacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        embarcacao.excluido = true;
        const index = this.item.embarcacoes.indexOf(embarcacao);
        // this.item.embarcacoes.splice(index, 1);
        delete this.item.embarcacoes[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          //this.$swal("Aviso", "Embarcação removido com sucesso!", "success");
        }
      })
  }

  AbrirDialogBase(base?: Base){
    this.base = base ? base : new Base();
    this.dialogBase = true;
  }

  SalvarBase(){
    if (this.$refs.formDialogBase.validate()) {
      if (this.base.id == 0){
        this.item.bases.push(this.base);
      }
      this.dialogBase = false;
    }
  }

  ExcluirBase(base: Base){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        base.excluido = true;
        const index = this.item.bases.indexOf(base);
        // this.item.bases.splice(index, 1);
        delete this.item.bases[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          //this.$swal("Aviso", "Base removido com sucesso!", "success");
        }
      })
  }  

  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId)
      .then(
        res => {
          this.municipios = res.data.items;
        },
        err => this.$swal('Aviso', err.message, 'error')      
      );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  ObterEnderecoBase(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
        this.base.endereco.logradouro = endereco.logradouro;
        this.base.endereco.bairro = endereco.bairro;
        this.base.endereco.estadoId = endereco.estadoId;
        this.base.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  async AdicionarEmbarcacaoDocumento(){

    if (this.$refs.formDialogEmbarcacao.validate()) {

      this.embarcacaoDocumento.embarcacaoId = this.embarcacao.id;
      const tipo = this.tipoEmbarcacaoDocumentos.find(x => x.id == this.embarcacaoDocumento.tipoId);
      this.embarcacaoDocumento.tipo = tipo;

      const arquivoService = new ArquivoService();
      let dados = await arquivoService.Ler(this.arquivoDocumento);

      this.embarcacaoDocumento.arquivo = new Arquivo();
      this.embarcacaoDocumento.arquivo.nome = this.arquivoDocumento.name;
      this.embarcacaoDocumento.arquivo.tamanho = this.arquivoDocumento.size / 1024;
      this.embarcacaoDocumento.arquivo.tipo = this.arquivoDocumento.type;
      this.embarcacaoDocumento.arquivo.dados = dados.replace(/^[^,]*,/, "");
      
      this.embarcacao.documentos.push(this.embarcacaoDocumento);
      this.embarcacaoDocumento = new DocumentoEmbarcacao();
      this.arquivoDocumento = null;
    }
  }

  BaixarEmbarcacaoDocumento(item: DocumentoEmbarcacao){

    if(item.id == 0)
      this.$swal('Aviso', 'O arquivo ainda não foi salvo !', 'warning');
    else
      this.service.BaixarEmbarcacaoDocumento(this.item.id, item.embarcacaoId, item.id).then(
        async (res) =>{
          const arquivoService = new ArquivoService();
          await arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.nome);
        },
        (err) => this.$swal("Aviso", err.message, "error")
      );
  }

  ExcluirEmbarcacaoDocumento(documento: DocumentoEmbarcacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {

        let index = this.embarcacao.documentos.indexOf(documento);
        documento.excluido = true;
        delete this.embarcacao.documentos[index];
        // this.embarcacao.documentos.splice(index, 1);
        this.dialogEmbarcacao = false;
        this.dialogEmbarcacao = true;        
        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          // this.$swal("Aviso", "Documento removido com sucesso!", "success");
        }
      })
  }

  Salvar() {
    if (this.$refs.formCliente.validate()) {

      // let patchModel = jiff.diff(this.itemOriginal, this.item, false);

      let patchModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } 
          else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );

    }
  }

  Close() {
    this.dialog = false;
  }
}
