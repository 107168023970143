

























































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';

import { Receptor, ReceptorDocumento, ReceptorTecnologia, Tecnologia } from '@/core/models/residuo/';
import { ReceptorService, TecnologiaService } from '@/core/services/residuo';
import { EnderecoService, UnidadeMedidaService } from '@/core/services/geral';
import { PageBase } from '@/core/models/shared';
import { ArquivoService } from "@/core/services/shared";
import { UnidadeMedida } from "@/core/models/geral";

@Component
export default class CadastroDocumentoReceptor extends PageBase {
  @Prop() private item!: Receptor;
  @Prop() private value!: string;

  itemOriginal!: Receptor;

  service = new ReceptorService();
  enderecoService = new EnderecoService();

  valid = true;
  validEquipamento = true;
  validTecnologia = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formReceptor: HTMLFormElement
    formTecnologia: HTMLFormElement
    formEquipamento: HTMLFormElement
  }

  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  mskCelular = process.env.VUE_APP_MSK_CELULAR;
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;
  tabActive: any = {};
  estados: any[] = [];
  municipios: any[] = [];

  documento = new ReceptorDocumento();
  documentos: ReceptorDocumento[]=[];
  
  totalDocumentos:number = 0;
  optionsDocumentos: any = {
    itemsPerPage: -1
  }
  loadingDocumentos: boolean = false;
  loadingDocumentoDialog: boolean = false;
  dialogDocumento: boolean = false;
  loadingDownload: boolean = false;

  tecnologiaService: TecnologiaService = new TecnologiaService();
  tecnologias: Tecnologia[] = [];
  isTecnologiaLoading: boolean = false;

  unidadeMedidas: UnidadeMedida[] = [];
  unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();
  
  receptorTecnologia: ReceptorTecnologia = new ReceptorTecnologia();
  headersTecnologias: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Tecnologia', value: 'tecnologia.nome' },
    { text: 'Un.', value: 'unidadeMedida.nome' },
    { text: 'Valor', value: 'valor' }
  ];

  headersDocumentos: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Descrição', value: 'documento.descricao' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Validade', value: 'documento.dataValidade', type:'date' },
  ];

  @Watch('item')
  Item() {

    if (this.$refs.formReceptor) {
      this.$refs.formReceptor.resetValidation();
    }

    if(this.item.id > 0){
      this.CarregarDocumentos();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
    
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.endereco.estadoId")
  onEstadoIdChanged() {
    this.CarregarMunicipio();
  }

  beforeUpdate(){
    if (!this.dialog){
        this.$emit('fechou');
    }
  }
  
  mounted() {
    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    );
    this.tecnologiaService.ListarTudo().then(
      res => {
        this.tecnologias = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    )
    this.unidadeMedidaService.ListarTudo().then(
      res => {
        this.unidadeMedidas = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    )
  }

  CarregarMunicipio(){
    this.enderecoService.ObterMunicipios(this.item.endereco.estadoId).then(
      res => {
        this.municipios = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')
    );
  }

  ObterEndereco(cep: string){
    this.enderecoService.ObterPorCep(cep).then(
      res => {
        const endereco = res.data;
        if (endereco) {
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err => {
        this.$swal('Aviso', err.message, 'error');
      }
    );
  }

  Salvar() {
    if (this.$refs.formReceptor.validate()) {

      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      this.item.empresaId = this.app.empresaId;

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
          if (err.response.status == 403){
            this.$swal("Aviso","Você não tem permissão para essa funcionalidade!", "warning");
          }
        }
      );

    }
  }

  AbrirCadastroDocumento(documento?: ReceptorDocumento){
    if(documento){
      this.loadingDocumentoDialog = true;
      this.service.ObterDocumentoPorId(documento.id).then(
        res => {
          this.documento = res.data;
          this.documento.documento.dataValidade = this.documento.documento.dataValidade? this.documento.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogDocumento = true;
        },err => {
          this.$swal('Aviso',err.message,'error')
        }
      ).finally(() => {
        this.loadingDocumentoDialog = false;
      })
    }
    else{
      this.documento = new ReceptorDocumento();
      this.documento.receptorId = this.item.id;
      this.documento.documento.empresaId = this.app.empresaId;
      this.dialogDocumento = true;
    }
  }

  @Watch('optionsDocumentos', { deep: true })
  CarregarDocumentos(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsDocumentos;
    this.loadingDocumentos = true;
    this.service.ListarDocumentos(this.item.id, page, itemsPerPage, sortBy, sortDesc, search, this.headersDocumentos, undefined, "Tecnologia").then(
      res =>{
        this.documentos = res.data.items;
        this.totalDocumentos = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loadingDocumentos = false;
    })
  }

  SalvarTecnologia(){
    if (this.$refs.formTecnologia.validate()) {
      if(!this.item.tecnologias.find(x => x.tecnologiaId == this.receptorTecnologia.tecnologiaId && x.unidadeMedidaId == this.receptorTecnologia.unidadeMedidaId)){
        this.receptorTecnologia.receptorId = this.item.id;
        this.receptorTecnologia.tecnologia = this.tecnologias.find(x => x.id == this.receptorTecnologia.tecnologiaId)!;
        this.receptorTecnologia.unidadeMedida = this.unidadeMedidas.find(x => x.id == this.receptorTecnologia.unidadeMedidaId)!;
        this.item.tecnologias.push(this.receptorTecnologia);
        this.receptorTecnologia = new ReceptorTecnologia();  
        if (this.$refs.formTecnologia) {
          this.$refs.formTecnologia.resetValidation();
        }
      }
      else{
        this.$swal("Aviso", "O item informado já se encontra incluso.", "warning");
      }
    }
  }

  ExcluirTecnologia (item: ReceptorTecnologia){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let index = this.item.tecnologias.indexOf(item);
        this.item.tecnologias.splice(index, 1);
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  ExcluirDocumento(documento: ReceptorDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(documento.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
    .then((result) => {
      if(result.value) {
        this.$swal("Aviso", result.value, "success");
        this.CarregarDocumentos();
      }
    })
  }

  AbrirLink(link){
    if(!!link)
      window.open(link,"_blank");
  }

  DownloadDocumento(documento: ReceptorDocumento){
    this.loadingDownload = true;
    this.service.DownloadDocumento(documento.id).then(
      async (res) => {
        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.documento.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }

  Close() {
    this.documentos = [];
    this.dialog = false;
  }
}
