import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      dark: false,
      default: 'light',
      themes: { 
        light: {
          primary: '#78909C',
          secondary: '#B0BEC5',
          toolbar: '#ECEFF1',
          widget: colors.blueGrey.lighten5,
          itemOSClass: '#eb8f8f',
          changed: colors.deepOrange.lighten4
        },
        dark: {
          primary: '#78909C',
          secondary: '#424242',
          toolbar: '#4f4f4f',
          widget: colors.blueGrey.darken3,
          itemOSClass: '#430a0a',
          changed: colors.deepOrange.darken3
        }
       },
    }
});
