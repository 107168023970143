import { http } from '@/core/ApiConfig';
import { Service } from '../shared/';

export class PortoService extends Service{

  constructor(){
    super('porto');
  }
  
  //DOCUMENTOS
  public ListarDocumentos(id: number, page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filtro?: string, include?: string) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/${id}/Documento${this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns, filtro)}`, this.GetHeader(include));
  }

  public ObterDocumentoPorId(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public SalvarDocumento(model: any) : Promise<any>
  public SalvarDocumento(model: any, documentoId: number) : Promise<any>
  public SalvarDocumento(model: any, documentoId?: number) : Promise<any>{
    if(documentoId){
      if(documentoId > 0){
        return http.patch(`${this.GetNomeControle()}/Documento/${documentoId}`, model);
      }
    }

    return http.post(`${this.GetNomeControle()}/Documento`, model);
  }

  public DeletarDocumento(documentoId: number) : Promise<any>{
    return http.delete(`${this.GetNomeControle()}/Documento/${documentoId}`);
  }

  public DownloadDocumento(documentoId: number) : Promise<any>{
    return http.get(`${this.GetNomeControle()}/Documento/${documentoId}/Download`, {responseType: 'blob'});
  }
}