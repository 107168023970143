import { http } from '@/core/ApiConfig';
import { Service } from '../shared';

export class CartaRastreabilidadeService extends Service {

    constructor() {
        super('cartarastreabilidade');
    }

    Aprovar(id: number){
        return http.get(`${this.GetNomeControle()}/Aprovar/${id}`);
    }

    Cancelar(id: number){
        return http.get(`${this.GetNomeControle()}/Cancelar/${id}`);
    }

    Detalhar(id: number){
        return http.get(`${this.GetNomeControle()}/Detalhar/${id}`);
    }
}