









import CadastroTransportadora from "@/components/residuo/transportadora/Cadastro.vue";
import { Trajeto } from "@/core/models/monitoramento";
import { Component, Prop, Watch, Vue } from "vue-property-decorator"

@Component
export default class Mapa extends Vue {
  @Prop() private trajeto!: Trajeto[];
  
  geo: any[] = [];
  center: any[] = [];

  mounted() {
    this.CarregarDados();    
  }

  @Watch('trajeto')
  AtualizaTrajeto(){
    this.CarregarDados();
  }

  CarregarDados(){
    this.geo.length = 0;
    
    if(this.trajeto.length > 1){
      this.trajeto.forEach(x => {
        this.geo.push([x.longitude, x.latitude])
      })

      this.center = [(this.trajeto[this.trajeto.length - 1].longitude + this.trajeto[0].longitude) / 2, (this.trajeto[this.trajeto.length - 1].latitude + this.trajeto[0].latitude) / 2]

      setTimeout(() => this.CarregarMapaComItens(), 100);
    }

    else{
      setTimeout(() => this.CarregaMapaEmBranco(), 100);
      
    }
  }

  CarregaMapaEmBranco(){

    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

    mapboxgl.accessToken = 'pk.eyJ1Ijoic2NpYW1tYXJlbGxhYnJlbm5vIiwiYSI6ImNrbTBtcWxidTBpZWkybm1yYXpndTg4ZHQifQ.P1PbWu6ZPZVNL6Vj8DPK1A';

    var map = new mapboxgl.Map({
      container: 'mapaComponent',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-43.21253059477089, -22.941262462581616],
      zoom: 12
    });

  }

  CarregarMapaComItens() {

    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

    mapboxgl.accessToken = 'pk.eyJ1Ijoic2NpYW1tYXJlbGxhYnJlbm5vIiwiYSI6ImNrbTBtcWxidTBpZWkybm1yYXpndTg4ZHQifQ.P1PbWu6ZPZVNL6Vj8DPK1A';

    var map = new mapboxgl.Map({
      container: 'mapaComponent',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 12
    });

    var mapCenter = null;

    map.on('load', () => {      

      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "top-right");

      const markerStart = new mapboxgl.Marker({color: "#00FF00"})
        .setLngLat([this.trajeto[0].longitude, this.trajeto[0].latitude]).addTo(map);

      const trajetoSituacoes = this.trajeto.filter(x => x.situacaoOperacaoId && x.situacaoOperacaoId > 0);
      trajetoSituacoes.forEach(posicao => {
        const markerSituacao = new mapboxgl.Marker({color: "#4169E1"})
          .setLngLat([posicao.longitude, posicao.latitude]).addTo(map);
      });

      const markerEnd = new mapboxgl.Marker({color: "#FF0000"})
        .setLngLat([this.trajeto[this.trajeto.length - 1].longitude, this.trajeto[this.trajeto.length - 1].latitude]).addTo(map);

      var data = {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': this.geo
        }
      }

      map.addSource('route', {
        'type': 'geojson',
        'data': null
      })
      
      map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'route',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#888',
        'line-width': 8
      }
      });

      map.on('draw.update', reloadSource() );

      function reloadSource(){
        map.getSource('route').setData(null);
        map.getSource('route').setData(data);
      }  

    });

    var bbox = [this.geo[0], this.geo[this.geo.length - 1] ];

    setTimeout(() => 
      map.fitBounds(bbox, {
        padding: {top: 250, bottom:250, left: 250, right: 250}
      })
    , 1000);

    // map.addSource('marker', {
    //   'type': 'geojson',
    //   'data': {
    //     'type': 'FeatureCollection',
    //     'features': [
    //       this.trajeto.forEach(x =>{
    //         type: 'Feature'
    //         properties: {
    //           description: x.dataHora
    //         }
    //         geometry:{
    //           type: 'Point'
    //           coordinates: [x.longitude, x.latitude]
    //         }
    //       })
    //     ]
    //   }
    // })

    // map.addSource('route', {
    //   'type': 'geojson',
    //   'data': {
    //     'type': 'Feature',
    //     'properties': {},
    //     'geometry': {
    //       'type': 'LineString',
    //       'coordinates': this.geo
    //     }
    //   }
    // })

    // this.trajeto.forEach(x =>{
    //   const marker = new mapboxgl.Marker()
    //   .setLngLat([x.longitude,x.latitude])
    //   .addTo(map);
    // })

    // map.on('mouseenter', 'route', function() {
    //   map.getCanvas().style.cursor = 'pointer';
    // });

    // map.on('mouseleave', 'route', function() {
    //   map.getCanvas().style.cursor = '';
    // });

  }
}
