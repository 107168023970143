import { Service } from '../shared/';
import {http, httpHeader} from '../../ApiConfig'

export class EquipamentoService extends Service{

  constructor(){
    super('equipamento');
  }

  public PorGrupo(grupoId: number) {
    return http.get(`${this.GetNomeControle()}/PorGrupo`, {
      params: {
        grupoId: grupoId
      },
      headers: httpHeader.headers
    });
  } 

  public AutoComplete(q: string, somenteDisponivel: boolean) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q,
        somenteDisponivel: somenteDisponivel
      },
      headers: httpHeader.headers
    });
  } 
  
}