import { Motorista } from './../geral/Motorista';
import { Empresa } from "../geral";

export class Aviso implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    dataHora!: string;
    texto: string = '';
    motoristaId?: number;
    motorista!: Motorista;
    ativo: boolean = true;
    
    constructor(model?: Aviso){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.dataHora = model.dataHora;
        this.texto = model.texto;
        this.motoristaId = model.motoristaId;
        this.ativo = model.ativo;
    }
}