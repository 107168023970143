





































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';

import { EmpresaService, GrupoDocumentoService, MotoristaService, TipoDocumentoService, VeiculoService } from "@/core/services/geral";
import { ArquivoService } from '@/core/services/shared';
import { Documento, DocumentoGrupo, GrupoDocumento, Motorista, MotoristaDocumento, TipoDocumento, Veiculo, VeiculoDocumento } from "@/core/models/geral";
import { Arquivo } from '@/core/models/shared';
import { EmpresaDocumento } from "@/core/models/geral/EmpresaDocumento";
import { Porto, PortoDocumento, Receptor, ReceptorDocumento, Transportadora, TransportadoraDocumento } from "@/core/models/residuo";
import { PortoService, ReceptorService, TransportadoraService } from "@/core/services/residuo";
import { DocumentoService } from "@/core/services/geral/DocumentoService";

@Component
export default class CadastroDocumentoEmpresa extends Vue {
  @Prop() private value!: string;
  @Prop({default:false}) private cadastro!: boolean;
  @Prop() private item!: EmpresaDocumento | MotoristaDocumento | VeiculoDocumento | ReceptorDocumento | TransportadoraDocumento | PortoDocumento;
  @Prop() private service!: EmpresaService | MotoristaService | VeiculoService | ReceptorService | TransportadoraService | PortoService;

  observer! : jsonpatch.Observer<EmpresaDocumento | MotoristaDocumento | VeiculoDocumento | ReceptorDocumento | PortoDocumento>;

  arquivoService = new ArquivoService();
  salvando: boolean = false;
  arquivo: any = {
    documento: null,
    documentoChip: true
  }
  valid = true;
  validGrupo = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formDocumentoEmpresa: HTMLFormElement
    formGrupo: HTMLFormElement
  }  
  grupoHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'grupo.descricao' }
  ];
  documentoEmpresaGrupo = new DocumentoGrupo();
  grupos: GrupoDocumento[] = [];
  grupoDocumentoService = new GrupoDocumentoService();

  receptores: Receptor[] = [];
  receptorService: ReceptorService = new ReceptorService();

  motoristas: Motorista[] = [];
  motoristaService: MotoristaService = new MotoristaService();

  veiculos: Veiculo[] = [];
  veiculoService: VeiculoService = new VeiculoService();

  transportadoras: Transportadora[] = [];
  transportadoraService: TransportadoraService = new TransportadoraService();

  portos: Porto[] = [];
  portoService: PortoService = new PortoService();

  tipos: TipoDocumento[] = [];
  tabActive: any = null;

  destinatarios: string[] = [];
  dias: string[] = [];

  documentosService: DocumentoService = new DocumentoService();
  dialogDocumentoAnexado: boolean = false;
  loadingAnexados: boolean = false;
  documentoAnexado: Documento = new Documento();
  documentosAnexados: Documento[] = [];
  documentosAnexadosHeaders: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'identificacao' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Protocolo Ext. Validade', value: 'protocoloValidade' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Arquivo', value: 'arquivo.nome' },
    { text: 'Validade', value: 'dataValidade', type:'date' }
  ];

  @Watch('item')
  Item() {
    if (this.$refs.formDocumentoEmpresa) {
      this.$refs.formDocumentoEmpresa.resetValidation();
    }
    if (this.$refs.formGrupo) {
      this.$refs.formGrupo.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    if(this.dialog){
      setTimeout(() => {
        this.observer = jsonpatch.observe(this.item);
      }, 100);      
    }
    else{
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.documento.notificacao.destinatarios")
  WatchEmail(val: any){
    if(val.length == 0){
      this.destinatarios = [];
    }
    else{
      this.destinatarios = this.item.documento.notificacao.destinatarios.split(",");
    }
  }

  @Watch("item.documento.notificacao.dias")
  WatchDias(val: any){
    if(val.length == 0){
      this.dias = [];
    }
    else{
      this.dias = this.item.documento.notificacao.dias.split(",");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  } 

  mounted(){
    this.grupoDocumentoService.ListarTudo().then(
      res =>{
        this.grupos = res.data.items;
      }
    )
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      }
    )
    this.receptorService.ListarTudo().then(
      res => {
        this.receptores = res.data.items;
      }
    )
    this.motoristaService.ListarTudo().then(
      res => {
        this.motoristas = res.data.items;
      }
    )
    this.veiculoService.ListarTudo().then(
      res => {
        this.veiculos = res.data.items;
      }
    )
    this.transportadoraService.ListarTudo().then(
      res => {
        this.transportadoras = res.data.items;
      }
    )
    this.portoService.ListarTudo().then(
      res => {
        this.portos = res.data.items;
      }
    )
  }

  AdicionarEmpresaGrupo(){
    if(this.item.documento.todos && this.grupos.find(x => x.id == this.documentoEmpresaGrupo.grupoId)!.editor == false){
      this.$swal("Aviso", "Não é possível adicionar um grupo de visualização em um documento marcado como 'TODOS'!", "warning");
    }
    else if(this.$refs.formGrupo.validate()){
      if(this.item.documento.grupos.find(x => x.grupoId == this.documentoEmpresaGrupo.grupoId)){
        this.$swal("Aviso", "Esse grupo já se encontra adicionado!", "warning");
      }
      else{
        this.documentoEmpresaGrupo.grupo = this.grupos.find(x => x.id == this.documentoEmpresaGrupo.grupoId)!;
        this.item.documento.grupos.push(this.documentoEmpresaGrupo);
        if (this.$refs.formGrupo) {
          this.$refs.formGrupo.resetValidation();
        }
      }
      this.documentoEmpresaGrupo = new DocumentoGrupo();
    }
  }

  ExcluirDocumentoGrupo(grupo: DocumentoGrupo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const index = this.item.documento.grupos.indexOf(grupo);
        this.item.documento.grupos.splice(index,1);

        return true;
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  AbrirLink(){
    if(!!this.item.documento.link)
      window.open(this.item.documento.link,"_blank");
  }

  //ANEXADOS
  CarregarAnexados(){
    this.loadingAnexados = true;
    this.documentosService.ListarAnexados(this.item.documento.id).then(
      res => {
        this.documentosAnexados = res.data;
      },
      err => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingAnexados = false;
    })
  }

  DialogDocumentoAnexado(item?: Documento){
    if(item){
      this.documentosService.ObterPorId(item.id, "Tipo, Grupos.Grupo, Grupos.Grupo.Usuarios").then(
        res=>{
          this.documentoAnexado = new Documento(res.data);
          this.dialogDocumentoAnexado = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } 
          else {
            if(err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }else{
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        }
      )
    }
    else{
      this.documentoAnexado = new Documento();
      this.documentoAnexado.empresaId = this.item.documento.empresaId;
      this.documentoAnexado.parentId = this.item.documento.id;
      this.dialogDocumentoAnexado = true;
    }
  }

  ExcluirDocumentoAnexado(item: Documento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.documentosService.Excluir(item.id).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          },
          err => {
            if (err.response.status == 400){
              this.$swal("Aviso", err.response.data, "warning");                
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
      ).finally(() => {
        this.CarregarAnexados();
      })}
    })
  }

  DownloadDocumentoAnexado(documento: Documento){
    this.loadingAnexados = true;
    this.documentosService.Download(documento.id).then(
      async (res) => {
        if (res.status == 204){
          return;
        }
        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], documento.arquivo!.nome);
      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingAnexados = false;
    });
  }

  async Salvar() {
    if (this.$refs.formDocumentoEmpresa.validate()) {    

      var arquivoPesado: boolean = false;

      if(this.arquivo.documento){
        let dados = await this.arquivoService.Ler(this.arquivo.documento);

        this.item.documento.arquivo = new Arquivo();
        this.item.documento.arquivo.nome = this.arquivo.documento.name;
        this.item.documento.arquivo.tamanho = this.arquivo.documento.size / 1024;
        this.item.documento.arquivo.dados = dados.replace(/^[^,]*,/, "");

        if(this.arquivo.documento.type.length > 0){
          this.item.documento.arquivo.tipo = this.arquivo.documento.type;
        }
        else{
          this.item.documento.arquivo.tipo = 'application/x-rar-compressed';
        }

        if(this.item.documento.arquivo.tamanho > 20000){
          arquivoPesado = true;
        }
        else{
          arquivoPesado = false;
        }
      }
      else if(!this.arquivo.documento && !this.arquivo.documentoChip){
        this.item.documento.arquivo = undefined;
      }

      if(arquivoPesado){
        this.$swal("Aviso", 'Não é possíve fazer upload de um arquivo maior que 20Mb', "error");
      }
      else{
        this.salvando = true;

        let pacthModel = jsonpatch.generate(this.observer);

        (this.item.id > 0 ? this.service.SalvarDocumento(pacthModel, this.item.id) : this.service.SalvarDocumento(this.item)).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data, "warning");
            }
            else if (err.response.status == 403){
              this.$swal("Aviso", err.response.data, "warning");
            }
            else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
        ).finally(() => {
          this.salvando = false;
        });
      }
    }
  }

  Close() {
    this.tabActive = 0;
    this.arquivo = {
      documento: null,
      documentoChip: true
    }
    this.dialog = false;
  }
}
