import { PessoaJuridica } from './../shared/Pessoa';
import { Embarcacao, Base } from '../residuo';
import { Contato } from '../shared/';
import { Empresa } from '.';

export class Cliente extends PessoaJuridica{
    
    id: number = 0;
    empresaId: number = 0;
    empresa!: Empresa;
    periodoMedicao: string = '';
    embarcacoes: Embarcacao[]=[];
    bases: Base[] = [];
    contato: Contato = new Contato();

    constructor(model?: Cliente){

        super(model);
        
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.periodoMedicao = model.periodoMedicao;
        this.embarcacoes = model.embarcacoes;
        this.bases = model.bases;
        this.contato = model.contato;
    }
}