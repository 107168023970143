import { OrdemServico, OrdemServicoServico } from '@/core/models/interna';
import {http, httpHeader} from '../../ApiConfig'
import { Service } from '../shared';

export class OrdemServicoService extends Service{

  constructor(){
    super('ordemServicoInterna');
  }

  private GetFiltro(parametros: string, filter: any){

    if (filter) {
      let paramTemp = '';

      if (filter.numero){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }
      
      if (filter.clienteId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `contrato/clienteId eq ${filter.clienteId}`;
      }

      if (filter.transportadoraId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `transportadoraId eq ${filter.transportadoraId}`;
      }

      if (filter.motoristaId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `motoristaId eq ${filter.motoristaId}`;
      }

      if (filter.veiculoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `veiculoId eq ${filter.veiculoId}`;
      }

      if (filter.situacaoId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `situacaoId in (${filter.situacaoId})`;
      }

      if (filter.protocolo){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `protocolo eq '${filter.protocolo}'`;
      }

      if (filter.dataInicial){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data ge ${filter.dataInicial}`;
      }
      
      if (filter.dataFinal){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `data le ${filter.dataFinal}`;
      }

      //FILTROS POR MR
      if (filter.mr01){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo1/mr01 eq '${filter.mr01}')`;
      }

      if (filter.mr02){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo2/mr02 eq '${filter.mr02}')`;
      }

      if (filter.mr03){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo3/mr03 eq '${filter.mr03}')`;
      }

      if (filter.mr04){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo4/mr04 eq '${filter.mr04}')`;
      }

      //FILTROS POR CDF
      if (filter.cdf01){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo1/cdf01 eq '${filter.cdf01}')`;
      }

      if (filter.cdf02){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo2/cdf02 eq '${filter.cdf02}')`;
      }

      if (filter.cdf03){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo3/cdf03 eq '${filter.cdf03}')`;
      }

      if (filter.cdf04){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `residuos/any(o: o/processo4/cdf04 eq '${filter.cdf04}')`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  ListarAgrupadoPorClienteComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any){

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);
    
    return http.get(`${this.GetNomeControle()}/agrupadoPorCliente${parametros}`);  
  }

  ConcluirManualmente(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`, null, {
      headers: httpHeader.headers
    });
  }

  Cancelar(id: number, justificativa: string){
    return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, null, {
      params: {
        justificativa: justificativa,
      },
      headers: httpHeader.headers
    });
  } 

  AtualizarValorServico(item: OrdemServicoServico){
    return http.post(`${this.GetNomeControle()}/AtualizarValorServico`, null, {
      params: {
        ordemServicoServicoId: item.id,
        ordemServicoId: item.ordemServicoId
      },
      headers: httpHeader.headers
    });
  }

  AtualizarValores(item: OrdemServico){
    return http.post(`${this.GetNomeControle()}/AtualizarValores`, null, {
      params: {
        ordemServicoId: item.id
      },
      headers: httpHeader.headers
    });
  }

  AtualizarValoresReceptor(id: number){
    return http.post(`${this.GetNomeControle()}/AtualizarValoresReceptor`, null, {
      params: {
        ordemServicoId: id
      },
      headers: httpHeader.headers
    });
  }

  public RelatorioComItens(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any){
    
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);
    
    return http.get(`${this.GetNomeControle()}/RelatorioComItens${parametros}`);
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
    
}