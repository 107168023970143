import { Service } from '../shared/';
import {http, httpHeader} from '../../ApiConfig'

export class DocumentoService extends Service{

  constructor(){
    super('documento');
  }

  ListarAnexados(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/Anexados`);
  }

  Download(id: number){
    return http.get(`${this.GetNomeControle()}/${id}/download`, {responseType: 'blob'});
  }

  AcabandoValidade(pageSize: number, page: number){
    return http.get(`${this.GetNomeControle()}/acabandoValidade`, {
      params:{
        skip: (page - 1) * pageSize,
        top: pageSize
      }
    });
  }

  AcabouValidade(pageSize: number, page: number){
    return http.get(`${this.GetNomeControle()}/acabouValidade`, {
      params:{
        skip: (page - 1) * pageSize,
        top: pageSize
      }
    });
  }

  private GetFiltro(parametros: string, filter: any){
    if (filter) {
      let paramTemp = '';

      if(filter.parentId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `parentId eq ${filter.parentId}`;
      }

      if (filter.identificacao){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(identificacao, '${filter.identificacao}')`;
      }

      if (filter.descricao){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(descricao, '${filter.descricao}')`;
      }

      if (filter.tipoId && filter.tipoId.length > 0){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += ` tipoId in (${filter.tipoId})`;
      }

      if (filter.protocoloValidade){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `Contains(protocoloValidade, '${filter.protocoloValidade}')`;
      }

      if (filter.validadeInicial){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataValidade ge ${filter.validadeInicial}`;
      }
      
      if (filter.validadeFinal){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataValidade le ${filter.validadeFinal}`;
      }

      if(filter.usuarioId){
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `grupos/any(o: o/grupo/usuarios/any(p: p/usuarioId eq ${filter.usuarioId}))`;
      }

      if (paramTemp) {

        if(parametros){
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){
    
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

}