import { Empresa } from '../geral';
import { Contato } from './../shared/Contato';
import { PessoaJuridica } from './../shared/Pessoa';
import { TransportadoraServico } from './TransportadoraServico';

export class Transportadora extends PessoaJuridica {

    empresaId: number = 0;
    empresa!: Empresa;
    contato: Contato = new Contato();
    pcpCodigo: string = '';

    servicos: TransportadoraServico[] = [];

    constructor(model?: Transportadora) {

        super(model);

        if (!model)
            return;

        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.contato = model.contato;
        this.pcpCodigo = model.pcpCodigo;

        this.servicos = model.servicos;
    }

}