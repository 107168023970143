import { Contrato, Equipamento, OrdemServico } from '@/core/models/residuo';
import { TipoOrdemServicoLocacao } from './TipoOrdemServicoLocacao';

export class OrdemServicoLocacao implements Shared.IEntity{

    id: number = 0;
    ordemServicoId: number = 0;
    ordemServico!: OrdemServico;
    equipamentoId: number = 0;
    equipamento!: Equipamento;
    equipamentoTrocaId?: number;
    equipamentoTroca!: Equipamento;
    parentId?: number;
    parent!: OrdemServicoLocacao;
    valor: number = 0;
    valorFixo: number = 0;
    limiar: number = 0;
    dataInicio: string = '';
    dataFim?: string;
    dataHoraInicioCobranca: string = '';
    dataHoraTerminoCobranca: string = '';
    tipoId: number = 0;
    tipo!: TipoOrdemServicoLocacao;
    excluido: boolean = false;

    constructor(model?: OrdemServicoLocacao){

        if(!model)
            return;

        this.id = model.id;
        this.ordemServicoId = model.ordemServicoId;
        this.ordemServico = model.ordemServico;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.equipamentoTrocaId = model.equipamentoTrocaId;
        this.equipamentoTroca = model.equipamentoTroca;
        this.parentId = model.parentId;
        this.valor = model.valor;
        this.valorFixo = model.valorFixo;
        this.limiar = model.limiar;
        this.dataInicio = model.dataInicio.toDateYYYYMMDD();
        this.dataFim = model.dataFim ? model.dataFim.toDateYYYYMMDD() : '';
        this.tipoId = model.tipoId;
    }
}