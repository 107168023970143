import { DocumentoEmbarcacao } from './DocumentoEmbarcacao';
import { Cliente } from '../geral';
import { TipoEmbarcacao } from './TipoEmbarcacao';

export class Embarcacao implements Shared.IEntity{
    
    id: number = 0;
    clienteId: number = 0;
    cliente!: Cliente;
    pcpCodigo: string = "";
    nome: string = '';
    sigla: string = '';
    tipoId: number = 0;
    tipo!: TipoEmbarcacao;
    documentos: DocumentoEmbarcacao[] = [];
    ativo: boolean = true;
    excluido: boolean = false;

    constructor(model?: Embarcacao){
        
        if(!model)
            return;

        this.id = model.id;
        this.clienteId = model.clienteId;
        this.pcpCodigo = model.pcpCodigo;
        this.nome = model.nome;
        this.sigla = model.sigla;
        this.tipoId = model.tipoId;
        this.documentos = model.documentos;
        this.ativo = model.ativo;
    }
}